import Vue from 'vue';
import './libs/element';
import App from './App.vue';
import router from './router';
import store from './store';
import config from './config';
import api from './api';
import i18n from './locale';
import Templates from './libs/template'
import Tools from '@stroll/tools'
import './styl/app.styl';
// import './assets/css/style.styl';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
config.productionTip = process.env.NODE_ENV === 'development';
import 'promise-polyfill/src/polyfill';
import dateFormat from './libs/dateFormat';//  当前时间转换
import * as echarts from 'echarts';
import tool from "./utils/tools";
Vue.prototype.$echarts = echarts;
// import sensors from './utils/sensors'
// Vue.prototype.$sensors = sensors
import question from '@wd/question/plugin/index';
import tempRender from '@wd/news-temp';
import '@wd/question/lib/question.css';
import SDK from '@wd/lowcode-pc'
import './libs/saas.js'
import wdPcForm from '@wd/form-render-pc';
import '@wd/form-render-pc/lib/form-render-pc.css';
Vue.use(wdPcForm)

Vue.use(question)
Vue.use(tempRender)
Vue.use(tool);

import Toast from './modules/live/components/common/Toast/toast.js'
import Dialog from './modules/live/components/common/Dialog/dialog.js'
Vue.use(Dialog)
Vue.use(Toast)
BJY.logger.enable()

/**
 * @description 全局注册应用配置
 */
Vue.use(Templates)
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
Vue.prototype.$config = config;
Vue.prototype.$tools = Tools
Vue.prototype.$api = api;
Vue.prototype.$dateFormat = dateFormat;
Vue.prototype.$t = function(str, fallbackStr) {
  // use `$te` and `$t
  return i18n.t && i18n.te
    ? i18n.te(str)
      ? i18n.t(str)
      : fallbackStr?.text || str
    : fallbackStr?.text || str;
};


//新加配置代码
Vue.prototype.$store = store;
// let domain = window.location.host
const TcPlayer = window.TcPlayer
Vue.prototype.TcPlayer = TcPlayer
//close

// let domain = window.location.host
const BrowserLogger = require('alife-logger');
// BrowserLogger.singleton(conf) conf传入config配置。
// eslint-disable-next-line no-unused-vars
let environment =
    process.env.VUE_APP_NODE_ENV=='testing'? 'daily':
        process.env.VUE_APP_NODE_ENV=='fat'?'daily':
            process.env.VUE_APP_NODE_ENV=='uat'?'pre':
                process.env.VUE_APP_NODE_ENV=='production'?'prod':'local'
BrowserLogger.singleton({
    pid: 'g2t7afypx3@a71a3d04b8450ea',
    // 设定日志上传地址：
    // 部署新加坡地域可设为`https://arms-retcode-sg.aliyuncs.com/r.png?`。
    // 部署美西地域可设为`http://arms-us-west-1.console.aliyun.com/r.png?`。
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    enableLinkTrace:true,
    environment:environment,
    // enableApiCors: true,
    // 其他config配置。
    disableHook: true
});

//将自定义页面加入到路由中 路由必须是历史模式
Promise.all([SDK.setLayoutToRouter(router,store)]).then(() => {
    const sensorsConf = store.state.dynamic.frontendConfig.find((o) => {
      return o.key === 'is_track_single_page'
    })
    const is_track_single_page = sensorsConf?.value || true
    localStorage.setItem('is_track_single_page', is_track_single_page)

    if (is_track_single_page == 'true') {
      import('./utils/sensors')
        .then((sensors) => {
          Vue.prototype.$sensors = sensors.default
          new Vue({
            store,
            router,
            i18n,
            render: h => h(App),
          }).$mount('#wdc-app')
        });
    } else {
      const sensors = {
        login: () => {},
        track: () => {},
      }
      Vue.prototype.$sensors = sensors
      new Vue({
        store,
        router,
        i18n,
        render: h => h(App),
      }).$mount('#wdc-app')
    }
   }).catch(res=>{
    console.log(res)
    new Vue({
        router,
        store,
        i18n,
        render: h => h(App),
    }).$mount('#wdc-app');
});

