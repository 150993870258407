import _ from 'lodash'
import ElementLocale from 'element-ui/lib/locale'
import api from '../api'
import i18n from '../locale'
// 自动根据浏览器系统语言设置语言
// const navLang = navigator.language

let language = ''

export default async function getLanguageData() {
    let env  = 'dev';
    // 如果是国际化
    console.log('is_i18n',localStorage.getItem('is_i18n') );
    if(localStorage.getItem('is_i18n') == 'true'){
      // 如果是第一次访问，从后台设置的语言取语言
      console.log('如果是第一次访问，从后台设置的语言取语言',localStorage.getItem('current_language'));
      console.log('如果是第一次访问，语言是否为空',localStorage.getItem("current_language") == 'null' || localStorage.getItem('current_language') == null);
      if(localStorage.getItem("current_language") == 'null' || localStorage.getItem('current_language') == null){
        await api.locale.getConfiguration().then(res=>{
          if(res.success){
            console.log('res',res.data.internationalizationSetting);
            const data = res.data.internationalizationSetting.languages;
            const timeZonesName = res.data.internationalizationSetting.timeZonesName
            const index = data.lastIndexOf('-');
            const value = data.substring(0,index);
            localStorage.setItem('current_language',value)
            localStorage.setItem('timezone',timeZonesName)
            i18n.locale = value
            checkPackageVersion(value,env)
          }
        });
      }else{
        language = localStorage.getItem("current_language"); // 查不到为 null
        checkPackageVersion(language,env)
      }
    }
    else{
      // 如果不是国际化
      language = 'zh-CN'
      localStorage.setItem('current_language',language)
      checkPackageVersion(language,env)
    }
}
async function checkPackageVersion(language,env) {
  // 获取语言包版本
  const version_data = await api.locale.checkPackageVersion({
    appname: 'WDNPC_WEB', // 此字段在配置管理中心预设
    language,
    env: env
  });
  const remote_version = _.get(version_data[0], 'version', null)
  // 获取现有language语言的当前版本号
  const current_version = localStorage.getItem(`language_pc_${language}_version`);
  console.log('版本是否更新',current_version == 'null' || current_version == null || remote_version > current_version);
  if (current_version == 'null' || current_version == null || remote_version > current_version) { // 接口获取
    var lang_data = await api.locale.getCourseGrantList({
      appname: 'WDNPC_WEB',
      language,
      env: env
    });
    if(language == 'zh-CN'){
      lang_data = Object.assign(require('./language').default.zhLogin,lang_data);
    }else if(language == 'en-US'){
      lang_data = Object.assign(require('./language').default.enLogin,lang_data);
    }else if(language == 'fr-FR'){
      lang_data = Object.assign(require('./language').default.frLogin,lang_data);
    }
    const lang = _.get(lang_data, language, {})
    const elementUILang = getElementUILang(language)
    localStorage.setItem(`language_pc_${language}_data`, JSON.stringify(Object.assign(lang, elementUILang)));
    localStorage.setItem(`language_pc_${language}_version`, remote_version);
    console.log('i18n',i18n)
    localStorage.getItem('setLocaleMessage',i18n.setLocaleMessage())
    i18n.setLocaleMessage(language, Object.assign(lang, elementUILang));
    // i18n.setLocaleMessage(language, lang);
    ElementLocale.i18n((key, value) => i18n.t(key, value))
  } else {  // 缓存获取
    console.log('language',language);
    var lang = {}
    if(language == 'zh-CN'){
      lang = Object.assign(require('./language').default.zhLogin,JSON.parse(localStorage.getItem(`language_pc_${language}_data`)));
    }else if(language == 'en-US'){
      lang = Object.assign(require('./language').default.enLogin,JSON.parse(localStorage.getItem(`language_pc_${language}_data`)));
    }else if(language == 'fr-FR'){
      lang = Object.assign(require('./language').default.frLogin,JSON.parse(localStorage.getItem(`language_pc_${language}_data`)));
    }
    console.log('lang',lang);
    const elementUILang = getElementUILang(language)
    i18n.setLocaleMessage(language, Object.assign(lang, elementUILang));
    ElementLocale.i18n((key, value) => i18n.t(key, value))
  }
}
function getElementUILang(lang) {
  const { elementUIConverter } = require('./languageConverter')
  const langKey = elementUIConverter[lang]
  const elementUILang = require(`element-ui/lib/locale/lang/${langKey}`)
  return elementUILang.default
}