let base = require('js-base64').Base64;

const hostname = window.location.hostname
const port = window.location.port;
// const isIP=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
// let arr =  hostname.split('.')
// let  isDTYProEnv = !hostname.split('.').splice(3).join('.'); //线上的定制化主题
let API_HOST_CMS;

const pageUrl = port? 'wdn-pc.testing3.svc.k8s.bjo.wdcloud.cc:8080' : hostname;


let envArr = ['testing1', 'testing2', 'testing3', 'fat', 'uat'];
let env = window.location.port ? 'testing3' : ''; // 本地启动，使用testing3环境
envArr.forEach(d => {
  if (location.hostname.includes(d)) {env = d}
})
let API = env ? `${location.protocol}//hrss-api.${env}.wdeduc.com` : `${location.protocol}//hrss-api.wdeduc.com`;
// let confUrl = port? base.encode('wdn-pc.testing3.svc.k8s.bjo.wdcloud.cc:8080') :  base.encode(hostname);
let confUrl = port? base.encode('wdn-pc.testing3.svc.k8s.bjo.wdcloud.cc:8080') :  base.encode(hostname);
const yingjiURL = window.location.origin;

// API = 'https://hrss-api.uat.wdeduc.com'//临时访问UAT环境的接口
export default {
    API,
    confUrl,
    pageUrl,
    yingjiURL,
    API_HOST_CMS,
};
