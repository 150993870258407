<template>
    <div class="taskItem">
        <el-collapse v-model="activeNames">
            <el-collapse-item :name="index" v-for="(el,index) in itemList" :key="el.id" :disabled="(el.childItemList && el.childItemList.length)?false:true">
                <template slot="title" v-if="el.type === 1 && !el.isTraining" >
                    <div @click="tipClick(el)" class="title-box">
                        <i :class="activeNames.includes(index)?'el-icon-caret-bottom':'el-icon-caret-right'" v-show="el.childItemList && el.childItemList.length"></i>
                        <span class="sourse-title" :style="!el.childItemList?'margin-left:16px;':''">{{el.stageName}}</span>
                        <svg-icon icon-class="lock" class-name="lock-icon" v-if="el.isLocked"></svg-icon>
                    </div>
                </template>
                <template slot="title" v-else>
                    <div class="sourse" @click="toDetail(index,el)">
                        <span :class="isSelect===index?'selected sourse-name':'sourse-name'" :title="el.type === 1 ? el.stageName : el.contentName">
                            <span class="textType" v-if="el.type == 1 && el.isTraining">
                                {{el.type == 1 && el.isTraining? '线下实训': el.type == 2?'课程':el.type === 3?'考试':el.type === 4 ? '直播':el.type === 5 ? '实训':el.type === 6?'表单':'练习'}}
                            </span>
                            <span class="textType" v-else>
                                {{ el.type == 2?'课程':el.type === 3?'考试':el.type === 4 ? '直播':el.type === 5 ? '实训':el.type === 6?'表单':'练习'}}
                            </span>
                            <span class="name names">{{el.type == 1 ? el.stageName : el.contentName}}
                                <span class="red" v-if="el.type == 1 && el.isTraining && el.trainingResult === 0">
                                   （ 成绩不合格 ）
                                </span>
                                <span class="green" v-else-if="el.type == 1 && el.isTraining && el.trainingResult === 1">
                                   （ 成绩合格 ）
                                </span>
                                <span class="green" v-else-if="el.type == 1 && el.isTraining && el.trainingResult === 2">
                                   （ 已预约 ）
                                </span>
                                <span class="blue" v-else-if="el.type == 1 && el.isTraining">
                                    （ 未预约 ）
                                </span>
                            </span>
                            <span v-if="el.dateExpireStatus == 2 || courseExpireStatus == 2" class="guoqi">已过期不可查看</span>
                        </span>
                        <div class="isLocked" v-show="el.isLocked">
                            <svg-icon icon-class="lock"></svg-icon>
                        </div>
                        <div class="isLocked btns"  v-show="el.type == 1 && el.isTraining && el.trainingResult != 1">
                            <div class="order-button reserve" v-if="el.isReserved && el.trainingResult != 0" >查看预约</div>
                            <div class="order-button " v-else :class="!el.isTrainingCanReserve && !el.isReserved?'gray':''" >预约</div>
                        </div>
                        <span v-show="el.type === 2 && !el.isLocked" class="learnRate">{{el.studyProcess || 0}}%</span>
                        <span v-if="el.type === 6" :class="['submitStatus',el.submitStatus?'mrsubmitStatus':'']">{{el.submitStatus?'已填写':'未填写'}}</span>
                        <span @click.stop="jumpReplay(el)" v-show="el.type === 4 && el.isLiveReplay && el.status === 2 && !el.isLocked" class="learnRate learngkhf">观看回放</span>
                        <span class="time" :class="el.isLocked?'marginRight':''" v-show="el.type === 3 && el.isLimitTime">考试时间：{{el.contentStartTime && el.contentStartTime.replace(/-/g,'.')}} - {{el.contentEndTime && el.contentEndTime.replace(/-/g,'.')}}</span>
                        <span class="time" :class="el.isLocked?'marginRight':''" v-show="el.type === 4 ">直播时间：{{el.contentStartTime && el.contentStartTime.replace(/-/g,'.')}} - {{el.contentEndTime && el.contentEndTime.replace(/-/g,'.')}}</span>
                        <span class="time" :class="el.isLocked?'marginRight':''" v-show="el.type === 6  && el.contentEndTime">结束时间：{{el.contentEndTime && el.contentEndTime.replace(/-/g,'.')}}</span>
                    </div>
                </template>
                <taskItem v-if="el.type==1" :itemList="el.childItemList" :taskDetail="taskDetail"></taskItem>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import {goLiveHome} from "../modules/live/common/livePlatform";

export default {
    name: 'taskItem',
    props: {
        itemList: {
            type: Array,
            default: () => []
        },
        courseExpireStatus:{
            type:Number
        },
        taskDetail:{
            type: Object,
            default:() => {}
        },
        showSourse:{
            type:Boolean
        }

    },
     data () {
        return {
            activeNames: [],
            isSelect:null,
            timeSlot:'',
            startTime:'',
            endTime:''
        }
     },
    mounted(){
    },
    methods:{
        // 观看直播回放
        jumpReplay(item){
            goLiveHome(this,item, item.contentId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
          // this.$api.live.getBjyPlayUrl({liveCourseId: item.contentId}).then(async (resB) => {
          //   if(resB.data.roomStatus==1){ // 腾讯
          //     if(item.isReplay){
          //       this.$api.live.saveViewUser(item.contentId).then((res) => {
          //         if(res.success) {
          //           this.$router.push({
          //             path: '/livePlayback',
          //             query: {
          //               roomId: item.contentId,
          //               sourceId: item.replayResourceId,
          //             }
          //           })
          //         }
          //       });
          //     }else{
          //       this.$message.warning('视频处理中，请次日查看~')
          //     }
          //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==4){ // 百家云 cc
          //   this.$api.live.saveViewUser(item.contentId).then((res) => {
          //     if(res.success) {
          //       this.$router.push({
          //         path: '/bjyLivePlayBack',
          //         query:{
          //           contentId: item.contentId,
          //         }
          //       })
          //     }
          //   })
          //   }
          // });


            // if(item.isReplay){
            //     this.$api.live.saveViewUser(item.contentId).then((res) => {
            //         if(res.success){
            //           this.$api.live.getBjyPlayUrl({liveCourseId: item.contentId}).then(async (resB) => {
            //             if(resB.data.roomStatus==1){ // 腾讯
            //               this.$router.push({
            //                 path: '/livePlayback',
            //                 query:{
            //                   roomId: item.contentId,
            //                   sourceId: item.replayResourceId,
            //                 }
            //               })
            //             }else if(resB.data.roomStatus==2){ // 百家云
            //               this.$router.push({
            //                 path: '/bjyLivePlayBack',
            //                 query:{
            //                   contentId: item.contentId,
            //                 }
            //               })
            //
            //             }
            //           });
            //         }
            //     })
            // } else {
            //     this.$message.warning({message:'视频处理中，请次日查看~',offset:70})
            // }
            //  && item.isReplay

        },
         // 点击章节上锁提示
         tipClick(el){
             if(el.isLocked){
                 if(localStorage.getItem('theme')=='junmin'){
                    this.$message({
                        message: this.multiPopTips(),
                        type: 'warning'
                    });
                 }else{
                    this.$message.info(this.multiPopTips())
                 }
                 //this.$message.info(`该章未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%，考试及格。`)
             }
         },
         // 获取实训详情
        getdrillDetail(id){
            this.$api.learning.getdrillDetail({id}).then(res => {
                if(res.data){
                    window.open(window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`)
                }
            })
        },
        toDetail(e,item){
            this.isSelect = e;
            // if(item.dateExpireStatus == 2 || this.courseExpireStatus == 2){
            //     this.$message.info('已过期不可查看')
            // }else {

            // }
            console.log(this.taskDetail,'tasssss')
            if(item.type === 1 && item.isTraining) {
                // 去实训列表
                if(!item.isTrainingCanReserve) {
                    if(item.trainingResult !== 1) { // 成绩不合格
                         this.$toast({
                            message: '正式考核通过后可预约',
                            position: 'middle'
                        })
                        return
                    }else {
                        return
                    }
                }
                this.$router.push({
                    path: '/learning/subscribeList',
                    query: {
                        taskId: item.taskId,
                        searchKey: '',
                    }
                })
                return
            }else if(item.type === 1 && item.isLocked){
                this.$message.warning({message:this.multiPopTips(),offset:70})
                //this.$message.info(`该章未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
            }else if(item.type === 2){
                if(item.isLocked){
                    //this.$message.info(`该课程未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    this.$router.push({
                        path:'/learning/courseDetails',
                        query:{
                            id:item.contentId,
                            taskId:item.taskId,
                            taskType:2,//1：训练营 2：任务；
                            backMoudle:this.$route.query.backMoudle,
                            backPath:this.$route.query.backPath

                        }
                    })
                }

            }else if(item.type === 3){
                if(item.isLocked){
                    //this.$message.info(`该考试未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    console.log('asdasdasd',this.taskDetail.cycleType);
                    // this.taskDetail.cycleType === 1 ? 
                        // this.taskDetail.startTime.replace(/-/g,'.')+'至'+this.taskDetail.endTime.replace(/-/g,'.'):
                        // this.taskDetail.cycleType === 2 ? this.taskDetail.endTime.split(' ')[0].replace(/-/g,'.'):
                        // '长期有效',
                        // 加startTime，endTime两个参数主要是为了国际化翻译切换语言的时候使用
                    if(this.taskDetail.cycleType === 1){
                        this.startTime = this.taskDetail.startTime
                        this.endTime = this.taskDetail.endTime
                    }else if(this.taskDetail.cycleType === 2){
                        this.startTime = ''
                        this.endTime = this.taskDetail.endTime
                    }else{
                        this.timeSlot = 0
                    }
                    this.$router.push({
                        path:'/examDetails',
                        query:{
                            examId:item.contentId,//考试ID
                            bussinessId:item.taskId,//任务ID
                            type:2,//训练营1，任务2
                            title:this.taskDetail.name,
                            pathName:this.$route.path,
                            backPath:this.$route.query.backPath,
                            timeSlot:this.timeSlot,
                            startTime:this.startTime,
                            endTime:this.endTime
                        }
                    })
                }

            }else if(item.type === 4){
                if(item.isLocked){
                    //this.$message.info(`该直播未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    goLiveHome(this, item, item.contentId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
                    // this.$api.live.getBjyPlayUrl({liveCourseId: item.contentId}).then(async (resB) => {
                  //   console.log('hanmeng==== resB', resB);
                  //   if(resB.data.roomStatus==1){ // 腾讯
                  //     this.$api.live.audienceEnterRoom({id: item.contentId}).then(async (res) => {
                  //       if (res.success) {
                  //         if (res.success) {
                  //           window.open(window.location.origin + `/studentSee?type=1&roomId=${item.contentId}`)
                  //         }
                  //       }
                  //     });
                  //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==5){ // 百家云
                  //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.contentId}`)
                  //   }else if(resB.data.roomStatus==3){ // 百家云
                  //       if(item.status == 2){
                  //           this.$message.warning('直播已结束');
                  //           return;
                  //       }
                  //     window.open(window.location.origin + `/zegoroomkit?&roomId=${item.contentId}`)
                  //   }else if(resB.data.roomStatus==4){ // 百家云自定义
                  //     if(item.status == 2){
                  //       this.$message.warning('直播已结束');
                  //       return;
                  //     }
                  //     this.$api.live.audienceEnterRoom({id: item.contentId}).then(async (res) => {
                  //       if (res.success) {
                  //         if (res.success) {
                  //           window.open(window.location.origin + `/bjyCustomStudent?&roomId=${resB.data.room_id}`)
                  //         }
                  //       }
                  //     });
                  //   } else if(resB.data.roomStatus==6){
                  //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.contentId}`)
                  //   }
                  // });
                }
            }else if(item.type === 5){
                if(item.isLocked){
                    //this.$message.info(`该实训未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                }else {
                    this.getdrillDetail(item.contentId)
                }
            }else if(item.type === 6){
                if(item.isLocked){
                   this.$message.warning({message:this.multiPopTips(),offset:70})
                 }else {
                     this.$router.push({
                        path:'/learning/formPage',
                        query:{
                            type: this.$route.query.backMoudle == 'trainingTask' ? 2:  this.$route.query.backMoudle == 'trainingCamp' ? 1 : 2,// type：1训练营 2培训任务
                            typeId: this.$route.query.id || this.$route.query.taskId,// typeId：训练营id或者培训任务id
                            wjId:item.contentId // wjId：问卷ID
                        }
                    })

                }
            }else if(item.type === 7){
                if(item.isLocked){
                    //this.$message.info(`该课程未开启，开启条件为前面的学习内容学习进度达到 ${this.taskDetail.lockCourseRate}%${this.taskDetail.lockExamCondition?'，考试及格。':'。'}`)
                    this.$message.warning({message:this.multiPopTips(),offset:70})
                    return false
                }else {
                    this.$router.push({
                        path:'/pattern',
                        query:{
                            businessId:item.taskId,
                            practiceId:item.contentId,
                            businessType:2,
                        }
                    })
                }
            }

         },
         multiPopTips() {
            if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && this.taskDetail.isFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%，考试及格，表单提交`;
            }else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%，表单提交`;
            }else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && this.taskDetail.isFormCommit){
                return '该章未开启，开启条件为表单提交';
            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===1 && !this.taskDetail.isFormCommit ){
                return '该章未开启，开启条件为考试及格';
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition===0 && !this.taskDetail.isFormCommit){
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%`;
            } else if(this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && !this.taskDetail.isFormCommit) {
                return `该章未开启，开启条件为前面的学习内容学习进度达到${this.taskDetail.lockCourseRate}%，考试及格`;
            } else if(!this.taskDetail.lockCourseRate && this.taskDetail.lockExamCondition && this.taskDetail.isFormCommit) {
                return '该章未开启，开启条件为考试及格，表单提交';
            }
         }
     }
}
</script>
<style lang="stylus" scoped>
// .courseItem,.taskItem

.sourse-title
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 16px
    color: #1B2257
    font-weight: 500

.lock-icon
    font-size:12px;
    color:#CCCCCC;
    margin-right 24px
.sourse
    padding: 0 16px
    box-sizing: border-box
    cursor pointer
    position relative
    flex:1;
    // display flex
    // justify-content space-between
    .isLocked
        float right
        margin-left: 16px;
        // position absolute
        // right 24px
        // top 0
        .icon
            font-size:12px;
            color:#CCCCCC;
    .textType
        // width: 36px;
        padding 0 4px
        height: 20px;
        border-radius: 2px;
        border: 1px solid rgba(55, 96, 255, 0.7);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3760FF;
        line-height: 20px;
        text-align: center;
        margin-right 8px
        display: inline-block
        vertical-align: middle
    .time
        // margin-right 65px
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        // width: 350px
        float left
        // margin-right: 30px;
    .marginRight
        // margin-right: 10px;
    .submitStatus
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FD220F;
        margin-left: 10px;
        float right
        // margin-right: 30px;
    .mrsubmitStatus
        color: #43BC60;
    .selected
        color: #1972FF;
    .guoqi
        color: #E82424
        margin-left 4px
    img
        margin-right 5px
        align-self: center;
        width 14px
        position relative
        top 2px
    span
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #1B2257;
        // display: inline-block
        vertical-align: middle
    .sourse-name
        display flex
        align-items: center
        // width 100%
        max-width: 640px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        float:left;
        .name
            width: 283px
            // margin-right 64px
            display: flex
            align-items: center
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
            vertical-align: middle
    .lianxiname
        width:calc(100% - 40px);
        max-width: none;
        .name
            width: calc(100% - 80px);
    .learnRate
        float right
        color: #43BC60;
    .learngkhf
        color: #3760ff;
        margin-left: 10px;
.el-icon-caret-right:before, .el-icon-caret-bottom:before
    font-size: 20px;
    color: #1972ff;
    padding-left 12px

.defindcheck
    font-weight: bold;
    padding-right: 10px;
    line-height: 53px;
    color: #D3D3D3
.ischeck
    color:#5D9CFF;
    font-weight: bold;
    padding-right: 10px;
    line-height: 53px;
/deep/.el-icon-arrow-right
    display: none
/deep/.el-collapse-item__header.is-active
    border-bottom 1px solid #D7DCEE
/deep/.el-collapse-item__header
    height 60px
    background: rgba(49, 111, 255, 0.07);
    .title-box
        display: flex;
        align-items: center;
        width 100%
        outline: 0;
/deep/.el-collapse-item__content
    padding: 16px
    .el-collapse-item__content
        padding-bottom 0
        ul li:last-child .sourse
            margin-bottom 0
    .courseItem,.taskItem
        border: none
/deep/.el-collapse-item__wrap
    border-bottom: 0
    .sourse-title
        font-size: 14px
/deep/.el-collapse
    border: none;
/deep/.el-collapse-item:last-child
    margin-bottom 0
/deep/.el-collapse-item
    border: 1px solid #D7DCEE
    margin-bottom 16px
    .el-collapse-item
        border: 0
        margin-bottom:0px;
        .el-collapse-item__wrap
            position relative
            bottom 1px
.names
    display flex
    height 50px
    align-items: center
    .green
        color #43BC60
    .blue
        color #1972FF
    .red
        color #FD220F
.btns
    display flex
    align-items: center
    height 50px
.order-button
    width: 88px;
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background-color: #1972FF
    box-sizing: border-box
    &.gray
        background-color: #DEDDDE;
        color: #FFFFFF;
    &.reserve
        border: 1px solid #1972FF;
        background-color #eee
        color #1972FF

</style>
