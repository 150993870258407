<template>
	<div id="wdc-app" :class="{ 'wdc-app': true, [theme]: theme }">
		<router-view v-if="excludeNavPage.includes($route.name)"></router-view>
		<div v-else>
			<globalNav v-if="globalNavShow" />
			<tempNav :tempData="$store.state.dynamic" v-else-if="$store.state.dynamic.pageHeaderSetting == 1" ref="tempnav" />
            <shuidianNav v-else-if="shuidianNavShow" />

            <dynamic v-else-if="$store.state.dynamic.pageHeaderSetting == 3" :subUserInfo="subUserInfo" :tokenS="tokenS" :jsonInfo=jsonInfo :typeTer=typeTer :name=sysHeaderTemplate  />

            <MENU v-else />
			<div
				ref="main"
				class="main"
				:id="globalNavShow?'globalMain':''"
				:style="
					$route.name === 'serviceProviderList' || $route.name === 'opcourseGoods'
						? `padding-top: 80px;background:#ffffff;`
						: $store.state.dynamic.pageHeaderSetting == 1 && $store.state.dynamic.pageHeader && !$store.state.dynamic.pageHeader.headerFixed
						? 'padding-top: 0px;'
						: $store.state.dynamic.pageHeaderSetting == 1 && $store.state.dynamic.pageHeader && $store.state.dynamic.pageHeader.headerFixed
						? `padding-top:${headerHeight}px;`
						: 'padding-top: 80px;'
				"
			>
				<router-view :key="key" />
			</div>
		</div>
	</div>
</template>

<script>
import MENU from './view/head/crowd/module/menu'
import shuidianNav from './view/head/crowd/module/shuidianNav.vue'
import globalNav from './modules/global/components/nav'

import tempNav from './view/head/crowd/module/tempNav.vue'
// import axios from './libs/heartAxios'
import dynamic from "@wd/cms-pc/index"

export default {
	data() {
		return {
			headerHeight: 80,
			timer: null,
			shuidianNavShow: false,
			globalNavShow:false,
			excludeNavPage: [
				'login',
				'register',
				'error',
				'video',
				'teacherLive',
				'studentSee',
				// "bjyCustomStudent",
				'register-jz',
				'forget',
				'training_detail',
				'testPaper',
				'livePlayback',
				'personnel',
				'screen',
				'service-agreement',
				'privacy',
				'shuidianPrivacy',
				'coursePlay',
				// 用户中心改造
				'login_new',
				'register_new',
				'forget_new',
				'userAgreement', //用户协议
				'privacyPolicy', //法律声明及隐私政策
				'DownloadClient',
				'registerProtocol',
				'UserPrivacy',
				'NetworkRegulation'
			],
            typeTer:'1', // 第三种自定义头部 0 展示在modular端 1 展示在pc端
            headerT:'dynamic-templentA', // 第三种自定义头部模版名称 默认templentA
            jsonInfo:{},
            subUserInfo:JSON.parse(localStorage.getItem('subUserInfo')),
            // sysHeaderTemplate:this.$store.state.dynamic.pageHeader.sysHeaderTemplate,
            sysHeaderTemplate:'dynamic-templentA',
            tokenS:localStorage.getItem('token'),
            isShowT:true
		}
	},
	computed: {
		theme() {
			return this.$store.state.theme
		},
		key() {
			return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
		},


	},



    watch: {
        '$store.state.dynamic.pageHeader':{
            handler(){
                this.jsonInfo = this.$store.state.dynamic.pageHeader
            },
            deep:true,
            immediate:true
        },


        // subUserInfo:{
        //     handler(){
        //         this.subUserInfo = 	JSON.parse(localStorage.getItem('subUserInfo'))
        //     },
        //     deep:true,
        //     immediate:true
        // },
        sysHeaderTemplate(val){
            this.sysHeaderTemplate = val
        },

        $route () {
            setTimeout(() => { //路由跳转、
                this.tokenS = localStorage.getItem('token')
                this.subUserInfo = JSON.parse(localStorage.getItem('subUserInfo'))
                console.log('路由切换+++++++99991', this.subUserInfo)
            }, 100)
        }

    },

	components: { MENU, tempNav, shuidianNav,globalNav ,dynamic},
	async mounted() {

        document.addEventListener('visibilitychange', () => {
			var isHidden = document.hidden
			if (!isHidden) {
				let localStorageId = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).accountId
				let vuexId = this.$store.state.userInfo.accountId
				if (localStorageId && vuexId && localStorageId !== vuexId) {
					//重新加载页面，保持账号同步
					// this.$router.replace('/')
					this.$router.go(0)
				}
			}
		})

		const loading = document.getElementById('loading')
		loading.parentNode.removeChild(loading)
		this.$nextTick(() => {
			setTimeout(() => {
				let headDom = document.getElementsByClassName('header-temp')[0]
				this.headerHeight = headDom ? headDom.clientHeight : 80
			}, 400)
		})
		// 设置埋点
		// var _this = this //注意，要有，因为有箭头函数所以this作用域不在是vue，而定时器。我们要调用的是vue中的函数
		// this.timer = setInterval(() => {
		// 	_this.setSensor()
		// }, 30000)
	},
	created() {
		this.shuidianNavShow = window.location.hostname.includes('sdsj')
		this.globalNavShow = window.location.hostname.includes('global') ||  window.location.hostname.includes('i18n')
	},
	methods: {
		// 设置埋点
		// setSensor() {
		// 	const token = localStorage.getItem('token')
		// 	if (token) {
		// 		let option = {
		// 			url: `/usercenter/v1/stat/heartBeat?url=${window.location.pathname}`,
		// 		}
		// 		axios(option)
		// 		// this.$api.usercenter.heartBeat(window.location.pathname).then(res => {
		// 		//   console.log('res',res);
		// 		// })
		// 	}
		// },
	},
}
</script>

<style lang="stylus">
#globalMain{
	padding-top:64px!important;
}
.backtop {
  overflow-y: scroll;
}

.flex {
  display: flex;
}

.lr-flex {
  justify-content: space-between;
}

.el-message {
  min-width: 150px;
}

.el-message.is-closable .el-message__content {
  padding-right: 36px;
}

/**一分栏  二分栏 选项卡  样式*/
// div[data-comp-name="12-12"],div[data-comp-name="1-2"],div[data-comp-name="Tabs"]{
//   width: 1200px;
//   margin: 0 auto;
// }
/* 选项卡tabs样式修改 */
 .common-page {
    .el-tabs {
      .el-tabs__nav-wrap::after{
        display: none;
      }
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            display: none;
          }
        }
      }
    }
 }
</style>
