const urlName = '/offlineTrain/v1'
export default API => ({
    /**
     * 开始抽题
     * @param
     */
     extractQuestion(params) {
        return API({
            url: `${urlName}/trainingClassExam/extractQuestion`,
            method: 'get',
            params
        })
    },
    /**
     * 获取正在考试页面信息
     * @param
     */
     findClassExamInfo(params) {
        return API({
            url: `${urlName}/trainingClassExam/findClassExamInfo`,
            method: 'get',
            params
        })
    },
    /**
     * 查看成绩结果
     * @param
     */
     findExamScore(params) {
        return API({
            url: `${urlName}/trainingClassExamUser/findExamScore`,
            method: 'get',
            params
        })
    },
    /**
     * 查看 实训题目
     * @param
     */
     findQuestion(params) {
        return API({
            url: `${urlName}/trainingClassExamUser/findQuestion`,
            method: 'get',
            params
        })
    },
})