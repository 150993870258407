const urlName = '/order/v1'
export default API => ({
    // 发票申请
    postData(data) {
        return API({
            url: `${urlName}/open/invoice/apply` ,
            method:'post',
            data,
        });
    },
    getDataDetails(id) {
        return API({
            url: `${urlName}/open/invoice/findById?id=${id}` ,
            method:'get',
        });
    }
})
