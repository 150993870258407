<template>
	<div class="facedialogBox">
		<div class="confirmDialog">
			<el-dialog title="身份验证" :visible.sync="confirmdialog.show" center :close-on-click-modal="false" :before-close="handleClose">
				<div class="startFace">请进行人脸识别验证</div>
				<div slot="footer" class="dialog-footer">
					<div class="publicBtn">
						<!-- <span class="publicWhite" id="cancelBtn" @click="cancelBtn"
              >取消</span
            > -->
						<span class="publicBlue" id="confirmBtn" @click="confirmBtn">确定</span>
					</div>
          <div class="face_image_export_checkbox" v-if="is_task_courseRule_face_image_export && !AuthSaveFace">
              <el-checkbox v-model="isExportImage">确定即表示，同意机构管理员查看、并可以导出确认本人人脸识别照片</el-checkbox>
          </div>
            
				</div>
			</el-dialog>
		</div>
		<div class="tipsPause">
			<el-dialog title="提示" :visible.sync="tipsPause" center :close-on-click-modal="false" :before-close="handleClose">
				<div class="startFace" v-if="type == 1">
					课程播放将被暂停，是否继续识别？
				</div>
				<div class="startFace" v-if="type == 2">
					考试将被暂停，是否继续识别？
				</div>
				<div slot="footer" class="dialog-footer">
					<div class="publicBtn">
						<span class="publicWhite" id="tipsNo" @click="tipsPauseNo">否</span>
						<span class="publicBlue" id="tipsYes" @click="tipsPauseYes">是</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<div class="tipsDialogTwo">
			<el-dialog title="提示" :visible.sync="tipsDialogTwo" center :close-on-click-modal="false" :before-close="handleClose">
				<div class="startFace">允许{{ website }}使用您的摄像头</div>
				<div slot="footer" class="dialog-footer">
					<div class="publicBtn">
						<span class="publicWhite" @click="tipsTwoNo">否</span>
						<span class="publicBlue" @click="tipsTwoYes">是</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<div class="rejectionPrompt">
			<el-dialog :visible.sync="rejection" center :close-on-click-modal="false" :before-close="handleClose">
				<svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
				<p class="titles">提示</p>
				<p class="text">
                    <span v-if="type == 1">本课程</span>
                    <span v-if="type == 2">本考试</span>
                    需要进行人脸验证。您已拒绝使用摄像头权限，请先授权或使用手机微信端
                    <span v-if="type == 1">学习</span>
                    <span v-if="type == 2">考试</span>。
				</p>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="rejectionPrompt">知道了</el-button>
				</span>
			</el-dialog>
		</div>
		<div class="faceidentifyDialog">
			<el-dialog title="人脸识别" :visible.sync="faceidentify" center :close-on-click-modal="false" :before-close="faceidentifyClose">
				<img class="people" src="../assets/images/face/face.png" />
				<div slot="footer" class="dialog-footer">
					<div class="careful">注意事项：</div>
					<div class="tips">
						按要求，需本人亲自完成人脸识别验证，请将脸部置于框内
					</div>
					<el-button type="primary" @click="goFaceidentify" class="faceidentifybtn">开始人脸识别</el-button>
				</div>
			</el-dialog>
		</div>
		<div class="photographDialog">
			<el-dialog title="人脸识别" :visible.sync="photograph" center :close-on-click-modal="false" :before-close="photographClose">
				<div id="camera"></div>
				<div id="contentHolder" class="contentHolder">
					<div id="photo">
						<img id="photoImg" />
					</div>
					<video id="faceVideo" width="248px" height="248px" autoplay class="faceVideo"></video>
					<canvas style="display: none" id="canvas" width="300" height="300"></canvas>
				</div>
				<div slot="footer" class="dialog-footer">
					<div class="introduce">人脸识别中…</div>
					<div class="twoBtn">
						<span class="clickPhotograph" id="btn_snap" v-show="clickPhotograph">点击拍照</span>
						<span class="repet" id="repet" v-show="!clickPhotograph">重拍</span>
						<span class="use" @click="finallyBtn(true)" id="use" v-show="!clickPhotograph">使用</span>
					</div>
				</div>
			</el-dialog>
			<!-- 从图库中选择图片 -->
			<el-dialog title="人脸识别" :visible.sync="photographAlbum" center :close-on-click-modal="false" :before-close="photographAlbumClose">
				<div id="camera"></div>
				<div id="contentHolder" class="contentHolder">
					<div id="photo">
						<img id="photoImg" />
					</div>
					<video id="faceVideo" width="248px" height="248px" autoplay class="faceVideo"></video>
					<canvas style="display: none" id="canvas" width="300" height="300"></canvas>
				</div>
				<div slot="footer" class="dialog-footer">
					<div class="introduce">人脸识别中…</div>
					<div class="twoBtn">
						<el-upload
							action="#"
							class="upload-demo"
							:limit="1"
							accept="image/*"
							:file-list="fileList"
							:on-change="handleChange"
							:show-file-list="false"
							:auto-upload ="false"
						>
							<el-button size="small" type="primary" :class="clickPhotographAlbum?'uploadPhotoAlbum':'uploadPhotoAlbum-reborn'">{{clickPhotographAlbum?'上传照片':'重新上传'}}</el-button>
						</el-upload>
						<span class="use" @click="finallyBtn(false)" id="use" v-show="!clickPhotographAlbum">使用</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<div class="successDialog">
			<el-dialog :visible.sync="successDialog" center :close-on-click-modal="false" :before-close="successClose">
				<svg-icon icon-class="success" class-name="right"></svg-icon>
				<div class="successtext">恭喜您！人脸识别成功</div>
				<div slot="footer" class="dialog-footer">
					<span class="publicLongBtn" @click="goSuccessAdd">知道了（{{ time }}）</span>
				</div>
			</el-dialog>
		</div>
		<div class="failDialog">
			<el-dialog :visible.sync="failDialog" center :close-on-click-modal="false" :before-close="failClose">
				<img class="error" src="../assets/images/face/error.png" />
				<div class="failtext" v-if="facecount !== 0">人脸认证失败，请重试</div>
				<div class="count" v-if="facecount !== 0">
					剩余：<span id="surplusCount">{{ facecount }}次</span>/共5次
				</div>
				<div class="failtext" v-if="facecount == 0">
					即将退出<span v-if="type == 1" class="failContent">课程</span><span v-if="type == 2" class="failContent">考试</span>
				</div>
				<div class="count" v-if="facecount == 0">5次人脸认证失败</div>
				<div slot="footer" class="dialog-footer">
					<span class="publicLongBtn" id="know" v-if="facecount !== 0" @click="tryAgain">重试</span>
					<span class="publicLongBtn" v-if="facecount == 0" id="know" @click="facefail">知道了（{{ failtime }}）</span>
				</div>
			</el-dialog>
		</div>
		<div class="faceAuthDialog">
			<el-dialog :visible.sync="faceAuthDialog" title="实名认证" center :close-on-click-modal="false" :before-close="faceAuthClose">
				<div class="faceAuthText">
					请用微信或手机浏览器扫描下方二维码
					<div>完成实名认证</div>
				</div>
				<div class="imgParentBox" id="qrcodeBox"></div>
				<div class="faceAuthText"><img class="green" src="../assets/images/face/green.png" /><span class="smallText">个人隐私信息安全保障中</span></div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
/* eslint-disable */
import QRCode from 'qrcodejs2'
import md5 from 'js-md5'
export default {
	name: 'facedialog',
	data() {
		return {
      isExportImage:false,//是否要导出人脸照片
      is_task_courseRule_face_image_export:false,//配置项配置默认不显示人脸识别导出
	  AuthSaveFace:1,
	  		fileList:[],
			wdSubBranch:false,//人社定制，是否是伟东渠道方
			wdBranch:false,//人社定制，是否是伟东
			photographAlbum:false,
			clickPhotographAlbum:true,
			website: '',
			face: false,
			QRCode: null,
			faceidentify: false,
			photograph: false,
			tipsPause: false,
			tipsDialogTwo: false,
			base64ImgData: false,
			successDialog: false,
			failDialog: false,
			facecount: 0,
			surpluscount: 5,
			clickPhotograph: true,
			time: 3,
			// address:'',
			facecount: '4',
			failtime: 3,
			rejection: false,
			faceidentifyPause: false,
			photographPause: false,
			faceAuthDialog: false,
			userInfo: {},
			domainUrl: null,
			maxtime: 0,
			videoContent: false,
			task_show_facedialog_rule:{ //人脸识别规则
				range:'course',
				isOnce:false,
				isBioassay:true //人脸活体检测true, 照片比对false
			}
		}
	},
	watch:{
		'confirmdialog':{
			handler(){
				if(this.confirmdialog.show){
					this.faceidentify = false;
				}
			},
			deep:true,
			immediate:true,
		}
	},
	props: {
		confirmdialog: {
			type: Object,
			default: () => {
				return {
          show:true
        }
			},
		},
		confirm: { type: String },
		address: { type: Object },
		failAddress: { type: Object },
		pauseAddress: { type: Object },
		invalidAddress: { type: Object },
		type: { type: Number }, //1 课程 考试(前1 中2 马上交卷按钮 3) 4更换手机号
		pauseFrame: { type: Boolean },
		commitExam: { type: String },
		userPaperId: { type: String | Number },
    businessId:{type:String | Number},
	taskId:{type:String|Number},
	resId:{type:String|Number},
	is_course_image_export:{type:Boolean},
	},
  created(){
	if(localStorage.getItem('theme')==='renshe'){
		this.currBranchInfo();
	}
	console.log(this.type,'this.type=====')
    // 课程 判断是否需要导出人脸识别照片
    if(this.is_course_image_export){
      this.getAuthSaveFace()
    }
    const configurationArr = localStorage.getItem('configurationArr') && JSON.parse(localStorage.getItem('configurationArr'));
		let is_task_courseRule_face_image_export = configurationArr && configurationArr.filter((item) => {
				if (item.key === 'is_task_courseRule_face_image_export') {
					return true
				}
			})[0];
		this.is_task_courseRule_face_image_export = is_task_courseRule_face_image_export && is_task_courseRule_face_image_export.value === 'true' ? true : false;
	 let task_show_facedialog_rule = configurationArr && configurationArr.filter((item) => {
        if (item.key === 'task_show_facedialog_rule') {
            return item
        }
    })[0];
	this.task_show_facedialog_rule = task_show_facedialog_rule && JSON.parse(task_show_facedialog_rule.value);
  },
	mounted() {
		let base = require('js-base64').Base64
		const hostname = window.location.hostname
		const port = !!window.location.port
		let url = port ? base.encode('hrss-pc.testing3.wdeduc.com') : base.encode(hostname)
		this.configuration(url)
		this.getUserInfo()
		this.website = `${window.location.protocol}//${window.location.host}`
		// 更换手机号
		if (this.type === 4) {
			this.faceidentify = true
		}
	},
	methods: {
    // 获取用户授权人脸比对照片保存记录
    getAuthSaveFace(){
      // 1-已授权，0-未授权
      const params = {
        taskId:this.taskId
      }
      this.$api.face.getAuthSaveFace({params}).then(res =>{
          if(res.success){
            this.AuthSaveFace = res.data;
          }
      })
    },
		// 关闭弹框
		faceAuthClose() {
			this.faceAuthDialog = false;
      this.confirmdialog.show = false;

		},
		// 关闭弹框
		handleClose() {
      this.$emit('failCloseFacedialog');
			this.$parent.verification = false;
		},
		//关闭人脸识别拍照
		photographClose() {
			this.photograph = false;
      		this.$emit('failCloseFacedialog');
			if (this.type === 4) {
				//修改手机号
				let video = document.getElementById('faceVideo')
				if(video?.srcObject){
					video.srcObject.getTracks()[0].stop()
				}
				this.$emit('closeFacedialog')
				return
			}
			if (this.pauseFrame) {
				this.$parent.verification = false
				if (this.videoContent) {
					let video = document.getElementById('faceVideo')
					video.srcObject.getTracks()[0].stop()
				}
				return
			}
			this.photographPause = true
			this.tipsPause = true
		},
		// 关闭人脸识别从图库中选择
		photographAlbumClose() {
			this.$parent.verification = false
			this.$emit('failCloseFacedialog');
			this.clickPhotographAlbum = true;
			this.photographAlbum = false;
			let img = document.getElementById('photoImg')
			img.src = ''
			img.style.display = 'none'
			this.fileList = [];
		},
		//关闭人脸识别
		faceidentifyClose() {
			this.faceidentify = false;
      this.$emit('failCloseFacedialog');
			if (this.type === 4) {
				//修改手机号
				this.$emit('closeFacedialog')
				return
			}
			if (this.pauseFrame) {
				this.$parent.verification = false
				return
			}
			// this.faceidentify=false
			this.faceidentifyPause = true
			this.tipsPause = true
			this.$parent.verification = false;
		},
		successClose() {
			if (this.pauseFrame) {
				return
			}
			this.successDialog = false
			// this.successPause=true
			this.tipsPause = true
			this.$parent.verification = false
		},
		failClose() {
			if (this.pauseFrame) {
				return
			}
			this.failDialog = false
			// this.failPause=true
			this.tipsPause = true
			this.$parent.verification = false
		},
		// cancelBtn() {
		//   this.confirmdialog = false;
		//   this.$parent.verification = false;
		// },
		//点击确定人脸识别
		confirmBtn() {
      if(!this.isExportImage && this.is_task_courseRule_face_image_export && !this.AuthSaveFace){
        this.$message.warning('请阅读并确认服务约定')
        return false
      }
      if(this.isExportImage && this.is_task_courseRule_face_image_export){
        const params ={
          taskId:this.taskId
        }
        this.$api.face.authSaveFace({params}).then(res =>{
          console.log(res,'==authSaveFace')
        })
      }
			this.confirmdialog.show = false
			this.$api.face
				.judgefaceauth({ data: {} })
				.then((res) => {
					var authToken = localStorage.getItem('token')
					var authTokenParam = authToken + 'true'
					var authMd5Param = md5(authTokenParam).toLowerCase()
					var authResData = res.data && res.data.toLowerCase()
					if (authMd5Param == authResData) {
						this.faceidentify = true
					} else {
						this.faceAuthDialog = true
						this.$nextTick(() => {
							this.qrcode()
							console.log('qrcode')
						})
					}
				})
				.catch((error) => {
					console.log(error)
				})
			console.log('99999')
			console.log('this.faceidentify', this.faceidentify)
		},
		//实名认证
		getUserInfo() {
			this.$api.face.getAccountUserInfo({}).then((res) => {
				this.userInfo = res.data
			})
		},
		//实名认证
		configuration(url) {
			this.$api.configure.configurationNew(url).then((res) => {
				if (res.data) {
                    // 获取h5域名
                    let h5_address = res.data.frontendConfig.find((item) => item.key === 'h5_address')
                    if(h5_address && h5_address.value){
                        this.domainUrl = h5_address.value
                    }else if(res.data.solutionInfo && res.data.solutionInfo.domain){
                        res.data.solutionInfo.domain.forEach((item) => {
							if (+item.frontend_template_id === 4) {
								this.domainUrl = item.domain
							}
						})
                    }
				}
			})
		},
		//实名认证
		async qrcode() {
			if (this.QRCode) return
			if(localStorage.getItem('theme')=='renshe'){
				this.domainUrl = localStorage.getItem('rensheH5Domain');
			}
			this.QRCode = new QRCode('qrcodeBox', {
				width: 150,
				height: 150, // 高度
				// text:  `https://${this.domainUrl || 'hrss-stu.testing2.wdeduc.com'}/login?phone=${this.userInfo.phone}`,   // 二维码内容
				text: `${window.location.protocol}//${this.domainUrl}/login?phone=${this.userInfo.phone}`, // 二维码内容
				display: 'none',
				// render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				// background: '#f0f',   // 背景色
				// foreground: '#ff0'    // 前景色
			})
		},
		//点击开始人脸识别按钮
		goFaceidentify() {
			this.faceidentify = false;
			 // 1 课程 考试(前1 中2 马上交卷按钮 3) 4更换手机号
			//  人社定制：伟东，伟东渠道方支持上传图片人脸对比
			if((this.type==1 || this.type==2 || this.type==3) && (this.task_show_facedialog_rule.isBioassay===false) && (this.wdSubBranch || this.wdBranch) && (this.type!=4)){
				this.photographAlbum = true;
			}else{
				this.tipsDialogTwo = true
			}

		},
		handleChange(file){
			if (file.raw.type.indexOf('image') === -1) {
				this.$message({
					type:'warning',
					message:'请上传图片',
					customClass:'messTip',
				})
			}
			this.fileList = [];
			const reader = new FileReader()
      		reader.readAsDataURL(file.raw)
			reader.onload = () => {
				let img = document.getElementById('photoImg')
				img.style.display = 'block'
				img.src = reader.result;
				this.clickPhotographAlbum = false;
			}
		},
		//点击暂停弹窗的是
		tipsPauseYes() {
			this.tipsPause = false
			if (this.faceidentifyPause == true) {
				this.faceidentify = true
				this.faceidentifyPause = false
			} else if (this.photographPause == true) {
				if((this.task_show_facedialog_rule.isBioassay===false) && (this.wdSubBranch || this.wdBranch) && (this.type!=4)){
					this.photographAlbum = true;
				}else{
					this.photograph = true
				}
				this.photographPause = false
			}
		},
		//点击暂停弹窗的否
		tipsPauseNo() {
			if (this.photograph) {
				let video = document.getElementById('faceVideo')
				video.srcObject.getTracks()[0].stop()
			}
			this.tipsPause = false
			if (JSON.stringify(this.pauseAddress) === '{}') {
				this.$parent.verification = false
				this.$parent.failSubmit = true
				return
			}
			this.$router.push(this.pauseAddress)
			// window.location.href=encodeURI(`${window.location.protocol}//${window.location.host}` + this.pauseAddress)
		},
		//点击是否允许使用摄像头的是
		tipsTwoYes() {
			this.tipsDialogTwo = false
			this.photograph = true
			this.$nextTick(() => {
				this.getImage()
			})
		},
		//点击是否允许使用摄像头的否
		tipsTwoNo() {
			this.tipsDialogTwo = false
			this.rejection = true
		},
		//点击已拒绝使用摄像头权限的知道了
		rejectionPrompt() {
      this.$emit('failCloseFacedialog');
			if (JSON.stringify(this.pauseAddress) === '{}') {
				this.rejection = false
				this.$parent.verification = false
				this.$parent.failSubmit = true
				return
			}
			this.rejection = false
			this.$parent.verification = false
			this.$router.push(this.pauseAddress)
			// window.location.href=encodeURI(`${window.location.protocol}//${window.location.host}` + this.pauseAddress)
		},
		//人脸识别拍照功能
		getImage() {
			let canvas = document.getElementById('canvas')
			let pzBtn = document.getElementById('btn_snap')
			let repet = document.getElementById('repet')
			let use = document.getElementById('use')
			let context = canvas.getContext('2d')
			let video = document.getElementById('faceVideo')
			// 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
			if (navigator.mediaDevices === undefined) {
				navigator.mediaDevices = {}
			}
			// 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
			// 使用getUserMedia，因为它会覆盖现有的属性。
			// 这里，如果缺少getUserMedia属性，就添加它。
			if (navigator.mediaDevices.getUserMedia === undefined) {
				navigator.mediaDevices.getUserMedia = function(constraints) {
					// 首先获取现存的getUserMedia(如果存在)
					let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia
					// 有些浏览器不支持，会返回错误信息
					// 保持接口一致
					if (!getUserMedia) {
						return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
					}
					//否则，使用Promise将调用包装到旧的navigator.getUserMedia
					return new Promise(function(resolve, reject) {
						getUserMedia.call(navigator, constraints, resolve, reject)
					})
				}
			}
			const constraints = { audio: false, video: { width: 720, height: 720 } }
			let that = this
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(function(stream) {
					var videoLable = document.getElementById('faceVideo')
					that.videoContent = true
					// 旧的浏览器可能没有srcObject
					if ('srcObject' in videoLable) {
						videoLable.srcObject = stream
					} else {
						//避免在新的浏览器中使用它，因为它正在被弃用。
						videoLable.src = window.URL.createObjectURL(stream)
					}
					videoLable.onloadedmetadata = function() {
						videoLable.play()
					}
				})
				.catch(function(err) {
					console.log(err.name + ': ' + err.message)
				})

			repet.addEventListener('click', function() {
				document.getElementById('photoImg').style.display = 'none'
				pzBtn.style.display = 'block'
				repet.style.display = 'none'
				use.style.display = 'none'
			})
			pzBtn.addEventListener('click', function() {
				// 点击，canvas画图
				context.drawImage(video, 0, 0, 300, 300)
				let image = canvas.toDataURL('image/jpeg')
				let img = document.getElementById('photoImg')
				img.style.display = 'block'
				img.src = image
				pzBtn.style.display = 'none'
				repet.style.display = 'block'
				use.style.display = 'block'
			})
		},
		//   保存人脸识别访问记录
        saveFaceAccess(){
            let obj = {};
            if(this.task_show_facedialog_rule.range=='task'){
                obj ={
                    type:1,
                    taskId:this.$route.query.id
                }
            }else if(this.task_show_facedialog_rule.range=='course'){
                obj ={
                    type:2,
                    taskId:this.$route.query.id,
                    courseId:this.businessId,
                }
            }else{
                obj ={
                    type:3,
                    taskId:this.$route.query.id,
                    courseId:this.businessId,
                    resId:this.resId
                }
            }
            return new Promise((resolve,reject)=>{
                this.$api.learning.saveFaceAccess(obj).then(res=>{
                    resolve(res)
                }).catch(err=>{
                    reject(err)
                })
            })
		},
		//拍照完点击使用按钮
		finallyBtn(isBioassay) {
			var img = document.getElementById('photoImg')
			this.base64ImgData = img.src;
			let video = document.getElementById('faceVideo')
			if(video?.srcObject){
				video.srcObject.getTracks()[0].stop()
			}
			let data = {
				businessId: this.userPaperId || this.businessId,
				businessType: this.type,
				userImage: this.base64ImgData,
        taskId: this.is_task_courseRule_face_image_export ?  this.taskId : ''
			}
			if (this.type === 4) {
				//更换手机号
				data = {
					userImage: this.base64ImgData,
          isSave: false,
          businessSource: 2, //对比来源, 0考试(默认是考试)，1课程，2学员修改手机号
				}
			}
			this.$api.face
				.postcamera({ data })
				.then((res) => {
					var token = localStorage.getItem('token')
					var tokenParam = token + 'true'
					var md5Param = md5(tokenParam).toLowerCase()
					var resData = res.data && res.data.toLowerCase()
					if (md5Param == resData && res.code === 0) {
						sessionStorage.setItem('isFaceByTask',1)
						localStorage.setItem('currentCourseId',this.businessId)
						this.saveFaceAccess();
						this.photograph = false
						this.photographAlbum = false;
						this.successDialog = true
						this.timer = setInterval(() => {
							this.time = this.time - 1
							if (this.time == 1) {
								clearInterval(this.timer)
								this.goSuccessAdd()
							}
						}, 1000)
					} else {
            this.$message.error(res.message)
						this.photograph = false
						this.photographAlbum = false;
						this.failDialog =  true;
						var date = new Date().getTime()
						this.maxtime = date + 10 * 60 * 1000
						if (this.facecount == 0) {
							this.timeClock = setInterval(() => {
								this.failtime = this.failtime - 1
								if (this.failtime == 1) {
									clearInterval(this.timeClock)
									this.facefail()
								}
							}, 1000)
						}
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		//去人脸识别成功地址
		goSuccessAdd() {
      this.successDialog = false;
      this.$emit('CloseSuccessFacedialog')
      if (this.type === 4) {
          //修改手机号
          this.$emit('closeFacedialog')
          this.$parent.result = true
          return
      }
			if (this.address) {
				if (JSON.stringify(this.address) === '{}') {
					this.$parent.verification = false
					this.$parent.result = true
					if (this.commitExam == 'exam') {
						this.$parent.commitSuccess = true
					}
					return
				}
				// 如果是实训和直播 直接打开新页面
				if (this.address.query.type === 11 || this.address.query.type === 12) {
					window.open(this.address.path)
				} else {
					this.$router.push(this.address)
				}
			} else {
				// this.$emit('result', true);
				this.$parent.result = true
			}
			this.$parent.verification = false
		},
		//人脸识别重试按钮
		tryAgain() {
			var currentime = new Date().getTime()
			if (currentime > this.maxtime) {
				this.$message('页面超时，已失效')
				this.$router.push(this.invalidAddress)
			}
			var pzBtn = document.getElementById('btn_snap')
			var repet = document.getElementById('repet')
			var use = document.getElementById('use')
			var img = document.getElementById('photoImg')
			img.style.display = 'none'
			pzBtn.style.display = 'block'
			repet.style.display = 'none'
			use.style.display = 'none'
			this.failDialog = false
			if((this.task_show_facedialog_rule.isBioassay===false) && (this.wdSubBranch || this.wdBranch) && (this.type!=4)){
				this.photographAlbum = true;
			}else{
				this.photograph = true
			}
			if(this.photograph){
				this.$nextTick(() => {
					this.getImage()
				})
			}
			this.facecount = this.facecount - 1
		},
		//去人脸识别失败地址
		facefail() {
      this.failDialog = false;
      this.$emit('failCloseFacedialog');
      if (this.type === 4) {
          //修改手机号
          this.$emit('closeFacedialog')
          return
      }
			if (this.failAddress) {
				if (JSON.stringify(this.failAddress) === '{}') {
					this.$parent.verification = false
					this.$parent.failSubmit = true
					return
				}
				this.$router.push(this.failAddress)
				// window.location.href=encodeURI(`${window.location.protocol}//${window.location.host}` + this.failAddress)
			}
			this.$parent.verification = false
			this.$parent.failSubmit = true
		},
	    currBranchInfo(){
			this.$api.usercenter.currBranchInfo().then(res=>{
				if(res.data){
					this.wdSubBranch = res.data.wdSubBranch;
					this.wdBranch = res.data.wdBranch
				}
			})
		}
	},
}
/* eslint-disable no-new */
</script>
<style lang="less" scoped>
.face_image_export_checkbox{
  margin-top: 10px;
  /deep/.el-checkbox{
    display: flex;
    white-space: inherit;
  }
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label{
    color:#316fff ;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #316fff;
    border-color: #316fff;
  }
}
.facedialogBox{
    z-index: 999999;
    width: 100%;
    // height: 100vh;
    overflow: hidden;
    position: relative;
}
.faceAuthDialog {
	/deep/.el-dialog {
		width: 389px;
		height: 386px;
		border-radius: 10px;
	}
	/deep/.el-dialog__header {
		padding: 32px 26px 12px 0px;
		/deep/.el-dialog__title {
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #1b2257;
			line-height: 34px;
		}
	}
	.faceAuthText {
		margin-top: 24px;
		text-align: center;
	}
	.smallText {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		vertical-align: middle;
	}
	.green {
		width: 14px;
		height: 16px;
		vertical-align: middle;
	}
	/deep/.el-dialog--center .el-dialog__body {
		padding: 0px 60px 0px 60px !important;
	}
	.imgParentBox {
		margin: 0 auto;
		margin-top: 24px;
		width: 150px;
		height: 150px;
		img {
			margin: 0 auto;
			width: 100%;
			height: 100%;
		}
	}
}
.rejectionPrompt {
	/deep/.el-dialog {
		width: 400px;
		height: 300px;
		border-radius: 10px;
		text-align: center;
		.el-dialog__header {
			display: none;
		}
		.el-dialog__body {
			padding-bottom: 30px;
			padding: 40px 24px 30px;
			text-align: center;
			.icon-img {
				font-size: 50px;
				margin-bottom: 19px;
			}
			.titles {
				font-size: 24px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #1b2257;
				line-height: 34px;
				margin-bottom: 16px;
			}
			.text,
			span {
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
			}
		}
		.el-dialog__footer {
			padding: 0 65px 30px;
			.dialog-footer {
				display: flex;
				justify-content: space-between;
				width: 240px;
				margin: 0 auto;
				.el-button {
					width: 100%;
					height: 40px;
					text-align: center;
					&.el-button--default {
						span {
							color: #666;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}
.publicWhite {
	display: inline-block;
	width: 97px;
	height: 40px;
	border-radius: 6px;
	border: 1px solid #bfc8d9;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #bfc8d9;
	line-height: 40px;
	cursor: pointer;
	// margin-bottom: 32px;
}
.publicBlue {
	display: inline-block;
	width: 97px;
	height: 40px;
	background: #316fff;
	border-radius: 6px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 40px;
	cursor: pointer;
	// margin-bottom: 32px;
}
.publicBtn {
	// margin-bottom: 32px;
	display: flex;
	justify-content: space-between;
	height: 40px;
}
#confirmBtn {
	margin: 0 auto;
}
.publicLongBtn {
	display: inline-block;
	width: 240px;
	height: 40px;
	background: #316fff;
	border-radius: 6px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ffffff;
	line-height: 40px;
	cursor: pointer;
}
.confirmDialog,
.tipsPause {
	/deep/.el-dialog__headerbtn .el-dialog__close {
		display: none;
	}
	/deep/.el-dialog {
		width: 340px;
		height: auto;
		border-radius: 10px;
	}
	/deep/.el-dialog__header {
		padding: 32px 0px 0px 0px;
		/deep/.el-dialog__title {
			margin-top: 32px;
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #1b2257;
			line-height: 34px;
		}
	}
	.startFace {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 26px;
		text-align: center;
	}
	/deep/.el-dialog--center .el-dialog__body {
		padding: 12px 0px 24px 0px;
	}
	/deep/.el-dialog__footer {
		// padding-left: 63px;
		// padding-right: 63px;
		// padding-bottom: 30px;
	}
}
.tipsDialogTwo {
	/deep/.el-dialog__headerbtn .el-dialog__close {
		display: none;
	}
	/deep/.el-dialog {
		width: 340px;
		height: auto;
		border-radius: 10px;
	}
	/deep/.el-dialog__header {
		padding: 32px 0px 0px 0px;
		/deep/.el-dialog__title {
			margin-top: 32px;
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #1b2257;
			line-height: 34px;
		}
	}
	// .publicWhite{
	//   margin-bottom:0px
	// }
	// .publicBlue{
	//   margin-bottom:0px
	// }
	// .publicBtn{
	//   margin-bottom:0px
	// }
	.startFace {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 26px;
		text-align: center;
	}
	/deep/.el-dialog--center .el-dialog__body {
		padding: 12px 30px 24px 30px;
	}
	/deep/.el-dialog__footer {
		padding-left: 63px;
		padding-right: 63px;
		padding-bottom: 32px;
	}
}
.successDialog {
	/deep/.el-dialog__headerbtn .el-dialog__close {
		display: none;
	}
	/deep/.el-dialog {
		width: 400px;
		height: auto;
		border-radius: 10px;
	}
	/deep/.el-dialog__header {
		padding: 0px;
	}
	.right {
		font-size: 50px;
		margin-bottom: 19px;
	}
	/deep/.el-dialog--center .el-dialog__body {
		padding: 40px 0px 32px 0px;
		text-align: center !important;
	}
	.successtext {
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #1b2257;
		line-height: 34px;
	}
	/deep/.el-dialog__footer {
		padding-bottom: 30px;
	}
}
.failDialog {
	/deep/.el-dialog__headerbtn .el-dialog__close {
		display: none;
	}
	/deep/.el-dialog {
		width: 400px;
		height: auto;
		border-radius: 10px;
	}
	.error {
		width: 50px;
		// height: 57px;
		margin-bottom: 19px;
	}
	.failtext {
		margin-bottom: 12px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #1b2257;
		line-height: 34px;
	}
	.failContent {
		margin-bottom: 12px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #1b2257;
		line-height: 34px;
	}
	/deep/.el-dialog__header {
		padding: 0px;
	}
	.count {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 26px;
	}
	#surplusCount {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1972ff;
		line-height: 26px;
	}
	/deep/.el-dialog--center .el-dialog__body {
		padding: 40px 0px 32px 0px;
		text-align: center !important;
	}
	/deep/.el-dialog__footer {
		padding-bottom: 30px;
	}
}
.faceidentifyDialog {
	.top {
		width: 750px;
		height: 974px;
		background: linear-gradient(143deg, #368dff 0%, #4a66f5 100%);
	}
	.people {
		width: 400px;
		height: 243px;
		border-radius: 10px;
		text-align: center;
	}
	/deep/.el-dialog {
		width: 460px !important;
		height: 516px !important;
		border-radius: 10px;
	}
	/deep/.el-dialog--center .el-dialog__body {
		padding: 24px 30px 24px 30px;
	}
	.faceidentifybtn {
		margin-bottom: 40px;
	}
	/deep/.el-dialog__footer {
		text-align: center;
		padding: 0px 30px 0px 30px;
	}
	/deep/.el-dialog__header {
		padding: 24px 0px 0px 0px;
	}
	.tips {
		margin-top: 8px;
		margin-bottom: 40px;
		width: 364px;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1b2257;
		line-height: 20px;
	}
	.careful {
		width: 90px;
		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #1b2257;
		line-height: 25px;
	}
}
.photographDialog {
	#camera {
		float: left;
		margin: 20px;
	}
	/deep/.el-dialog__header {
		padding: 24px 26px 24px 26px !important;
	}
	/deep/.el-dialog__title {
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		line-height: 28px;
	}
	.twoBtn {
		height: 40px;
		display: flex;
		justify-content:  space-around;
	}
	#contentHolder {
		// width: 300px;
		// height: 300px;
		margin-bottom: 22px;
	}
	#btn_snap {
		display: inline-block;
		width: 97px;
		height: 40px;
		border-radius: 6px;
		border: 1px solid #bfc8d9;
		text-align: center;
		background: #316fff;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 40px;
		cursor: pointer;
		cursor: pointer;
		/*禁止选择*/
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Chrome/Safari/Opera */
		-khtml-user-select: none; /* Konqueror */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently not supported by any browser */
	}
	#photo {
		width: 248px;
		height: 248px;
		border-radius: 50%;
		position: absolute;
		z-index: 9000;
	}
	#photoImg {
		width: 248px;
		height: 248px;
		border-radius: 50%;
	}
	/deep/.el-dialog {
		width: 412px !important;
		height: 516px !important;
		border-radius: 10px;
		background: rgba(37, 37, 51, 0.8);
	}
	.introduce {
		margin-bottom: 40px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #316fff;
		line-height: 20px;
	}
	.faceVideo {
		border-radius: 50%;
	}
	/deep/.el-dialog__header {
		padding: 24px 26px 20px 0px;
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		line-height: 28px;
	}
	/deep/.el-dialog--center .el-dialog__body {
		padding: 0px 0px 22px 0px;
		height: 248px;
	}
	.contentHolder {
		padding-left: 82px;
		padding-right: 82px;
	}
	/deep/.el-dialog__footer {
		padding-left: 82px;
		padding-right: 82px;
		padding-bottom: 0px;
	}
	.repet {
		// margin-right: 20px;
		width: 97px;
		height: 40px;
		border-radius: 6px;
		display: inline-block;
		text-align: center;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #bfc8d9;
		line-height: 40px;
		border: 1px solid #bfc8d9;
		cursor: pointer;
	}
	.use {
		display: inline-block;
		width: 97px;
		height: 40px;
		border-radius: 6px;
		border: 1px solid #bfc8d9;
		text-align: center;
		background: #316fff;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 40px;
		cursor: pointer;
	}
	.clickPhotograph {
		margin: 0 auto;
		width: 157px;
		height: 40px;
		background: #316fff;
		border-radius: 6px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 22px;
	}
}
.uploadPhotoAlbum{
	background: #316fff;
	color: #fff;
	width: 97px;
    height: 40px;
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
	line-height: 40px;
	padding:0;
	cursor: pointer;
    border: 1px solid #bfc8d9;
}
.uploadPhotoAlbum-reborn{
	width: 97px;
    height: 40px;
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    line-height: 40px;
    border: 1px solid #bfc8d9;
	cursor: pointer;
	padding:0;
	background: transparent;
}
.messTip{
	z-index: 99999!important;
}
</style>
