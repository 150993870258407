import { render, staticRenderFns } from "./account_new.vue?vue&type=template&id=0b232d69&scoped=true"
import script from "./account_new.vue?vue&type=script&lang=js"
export * from "./account_new.vue?vue&type=script&lang=js"
import style0 from "./account_new.vue?vue&type=style&index=0&id=0b232d69&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b232d69",
  null
  
)

export default component.exports