<template>
    <div class="live-list">
        <!-- 产品 李波 确认去掉 -->
        <!-- <div class="tab">
            <p class="text" v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" >
                {{tab.value}}
            </p>
        </div> -->
        <div class="content">
            <div class="list" v-for="item in liveList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.propagatePic || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/scss/live-pic.png'" alt="">
                    <img v-show="item.showNew" class="icon" src="../../assets/images/learning/new-icon.png" alt="">

                </div>
                <div class="middle item">
                    <div class="title">
                        <h1>{{item.title}}</h1>
                    </div>
                    <p class="live-time" >{{$t('WDNPC_WEB_LIVE_BROADCAST_TIME',{text:'直播时间：'})}}{{item.beginTime && item.beginTime.replace(/-/g,'.')}} - {{item.planEndTime && item.planEndTime.replace(/-/g,'.')}}</p>
                    <p class="source">{{$t('WDNPC_WEB_SOURCE',{text:'来源：'})}}{{item.sourceName}}</p>
                </div>
                <div class="right item">
                    <div class="button live-button" @click="goExam(item)" :class="{status : !item.status}">
                        <svg-icon v-show="item.isLocked" icon-class="lock"></svg-icon>
                        {{item.status === 1? $t('WDNPC_WEB_ENTER_LIVE_STREAMING',{text:'进入直播'}): item.status === 0 ?$t('WDNPC_WEB_NOT_STARTED',{text:'未开始'}) : item.status === 2 && (item.isLiveReplay && item.isRecord ) ? $t('WDNPC_WEB_PLAYBACK',{text:'回放'}) : $t('WDNPC_WEB_ENDED',{text:'已结束'})}}



                    </div>
                </div>
            </div>
            <article v-if="noData" class="no-data">
                <img class="notraining" src="../../assets/images/common/zb-nodata.png" alt />
                <p>{{$t('WDNPC_WEB_NO_LIVE_CONTENT_YET',{text:'还没有直播内容呦'})}}～</p>
            </article>
            <p class="loadingmore" v-if="loading">{{$t('WDNPC_WEB_LOADING',{text:'加载中'})}}...</p>
            <p class="loadingmore" v-if="noMore">- {{$t('WDNPC_WEB_ALL_CONTENT_LOADED',{text:'已加载全部内容'})}} -</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import {goLiveHome} from "../../modules/live/common/livePlatform";
// import DeviceCheckDialog from '../modules/live/page/dialogs/check/DeviceCheckDialog.vue'
export default {
    name:'liveList',
    data(){
        return {
            isDeviceCheckDialog:false,
            tabIndex:1,
            tabList:[
                {
                    id:1,
                    value:'WDNPC_WEB_ALL',//{text:'全部'})'
                },
            ],
            liveList:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            loading:false,
            noData:null,
            info:null,
        }
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')

                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    components:{
        // DeviceCheckDialog
    },
    computed: {
        noMore () {
            return this.liveList.length === this.paging.total && this.paging.total !== 0
        },
    },
    methods:{
        // tabClick(item){
        //     this.tabIndex = item.id
        // }
        testingFinish(){
            console.log(this.info,'item===')
            goLiveHome(this, this.info, this.info.liveId,localStorage.getItem('current_language'),localStorage.getItem('timezone'));

          // this.$api.live.getBjyPlayUrl({liveCourseId: this.info.liveId}).then(async (resB) => {
          //   if(resB.data.roomStatus==1){ // 腾讯
          //     this.$api.live.audienceEnterRoom({id: this.info.liveId}).then(async (res) => {
          //       if (res.success) {
          //         this.$router.push({
          //           path: '/studentSee',
          //           query:{
          //             roomId: this.info.liveId,
          //             type: 1,
          //           }
          //         })
          //       }
          //     });
          //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==5){ // 百家云
          //     this.$api.live.audienceEnterRoom({id: this.info.liveId}).then(async (res) => {
          //       if (res.success) {
          //         window.open(window.location.origin + `/bjyStudent?&contentId=${this.info.liveId}`)
          //       }
          //     })
          //   }else if(resB.data.roomStatus==4){ // 百家云
          //     this.$api.live.audienceEnterRoom({id: this.info.liveId}).then(async (res) => {
          //       if (res.success) {
          //         window.open(window.location.origin + `/bjyCustomStudent?&roomId=${resB.data.room_id}`)
          //       }
          //     })
          //   }else if(resB.data.roomStatus==6){
          //     window.open(window.location.origin + `/bjyStudent?&contentId=${this.info.liveId}`)
          //   }
          // });
          this.isDeviceCheckDialog = false;

        },
        goExam(item){
                    console.log(this.isDeviceCheckDialog,'this.isDeviceCheckDialog')

            // 上锁
            if(item.isLocked){
                this.$message.warning({
                    message:this.$t('WDNPC_WEB_IF_THE_LIVE_BROADCAST_IS_NOT_ENABLED',{text:'该直播未开启，开启条件为前面的课程学习进度达到'}) +`${item.lockCourseRate}%`,
                    offset:70
                })
                if(item.lockExamCondition){
                    this.$message.warning({
                        message:this.$t('WDNPC_WEB_IF_THE_LIVE_BROADCAST_IS_NOT_ENABLED_RTN',{text:'该直播未开启，开启条件为前面的考试得分及格'}),
                        offset:120
                    })
                }
            }else {
                //
                if(item.status === 0){
                    this.$message.warning(this.$t('WDNPC_WEB_THE_LIVE_BROADCAST_HAS_NOT_STARTED',{text:'直播未开始'})+'！')
                }else if(item.status === 1){
                    // this.isDeviceCheckDialog = true;
                    // this.info = item
                    // console.log(this.isDeviceCheckDialog,'this.isDeviceCheckDialog')


                  // this.$api.live.getBjyPlayUrl({liveCourseId: item.liveId}).then(async (resB) => {
                  //   if(resB.data.roomStatus==1){ // 腾讯
                  //     this.$api.live.audienceEnterRoom({id: item.liveId}).then(async (res) => {
                  //       if (res.success) {
                  //         this.$router.push({
                  //           path: '/studentSee',
                  //           query:{
                  //             roomId: item.liveId,
                  //             type: 1,
                  //           }
                  //         })
                  //       }
                  //     });
                  //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==5){ // 百家云
                  //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.liveId}`)
                  //   } else if(resB.data.roomStatus==4){
                  //     if (item.status === 2 && (item.isLiveReplay && item.isRecord ) ) {
                  //       window.open(window.location.origin + `/bjyStudent?&contentId=${item.liveId}`)
                  //     } else {
                  //       this.$api.live.audienceEnterRoom({id: item.liveId}).then(async (res) => {
                  //         if (res.success) {
                  //           window.open(window.location.origin + `/bjyCustomStudent?&roomId=${resB.data.room_id}`)
                  //         }
                  //       })
                  //     }
                  //   } else if(resB.data.roomStatus==6){
                  //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.liveId}`)
                  //   }
                  // });
            
                    goLiveHome(this, item, item.liveId,localStorage.getItem('current_language'),localStorage.getItem('timezone'));
                }else {
                    if(item.isLiveReplay && item.isRecord){
                        // 显示回放按钮
                      // this.$api.live.getBjyPlayUrl({liveCourseId: item.liveId}).then(async (resB) => {
                      //   if(resB.data.roomStatus==1){ // 腾讯
                      //     if(item.isReplay){
                      //       this.$api.live.saveViewUser(item.liveId).then((res) => {
                      //         if(res.success) {
                      //           this.$router.push({
                      //             path: '/livePlayback',
                      //             query: {
                      //               roomId: item.liveId,
                      //               sourceId: item.replayResourceId,
                      //             }
                      //           })
                      //         }
                      //           });
                      //     }else{
                      //       this.$message.warning('视频处理中，请次日查看~')
                      //     }
                      //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==4){ // 百家云
                      //     this.$api.live.saveViewUser(item.liveId).then((res) => {
                      //       if(res.success) {
                      //         this.$router.push({
                      //           path: '/bjyLivePlayBack',
                      //           query:{
                      //             contentId: item.liveId,
                      //           }
                      //         })
                      //       }
                      //     })
                      //   }
                      // });

                        goLiveHome(this, item ,item.liveId,localStorage.getItem('current_language'),localStorage.getItem('timezone'));
                    }else{
                        this.$message.warning(this.$t('WDNPC_WEB_THE_LIVE_BROADCAST_HAS_ENDED',{text:'直播已结束'}))
                    }
                }
            }

        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData(){
            const params = {
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,
            }
            this.$api.live.getDataList({params}).then(({ data: { list, total } }) => {
                if(list && list.length){
                    this.noData = false
                }else {
                    this.noData = true
                }
                if(this.paging.params.pageNum>1){
                    this.liveList = this.liveList.concat(list);
                } else {
                    this.liveList = list;
                }
                this.paging.total = total;
            });
        }
    }
}
</script>
<style lang="stylus" scoped>
@import './../../assets/css/learningList.styl'
</style>
