import Axios from 'axios'
import URL from '../config/url'
// import store from '../store'
// import { Message } from 'element-ui'
import resetMessage from './resetMessage'
// import router from '../router';
const baseURL = URL.API

const service = Axios.create({ withCredentials: false, baseURL })

const tipMessage = ['旧密码输入错误', '已经实名认证过，不允许修改用户姓名', '验证码不能为空', '手机号已经绑定到其他账号，请更换手机号']

// 请求拦截器
service.interceptors.request.use(
	(config) => {
		localStorage.getItem('token') && (config.headers['token'] = localStorage.getItem('token'))
		localStorage.getItem('organ') && (config.headers['organ'] = localStorage.getItem('organ'))
		localStorage.getItem('actor') && (config.headers['actor'] = localStorage.getItem('actor'))
		localStorage.getItem('branch') && (config.headers['branch'] = localStorage.getItem('branch'))
        config.headers['source-host'] = 'pc'
        config.headers["locale"] = localStorage.getItem("current_language")
		config.headers["_timezone"] = localStorage.getItem("timezone")
		return config
	},
	(err) => {
		console.error(err)
		//   return Promise.reject(err)
	}
)

// 响应拦截器
service.interceptors.response.use(
	(config) => {
		return config
	},
	(err) => {
		console.error(err)
		return Promise.reject(err)
	}
)
/* eslint-disable */

function API(config) {
	const { url = '', data = null, params = null, headers = { 'content-type': 'application/json' }, method = 'get', timeout = 10000,responseType='',isToast = true, } = config || {}
	return new Promise((resolve, reject) => {
		service({
			method, // 请求方式
			url, // 请求路径
			timeout, // 请求超时
			headers, // header体
			data, // body参数
			params, //  URL参数
      responseType,
      isToast//是否显示toast提示

		})
			.then((res) => {
				if (res.data.code && res.data.code !== 302 && res.data.code !== 50001 && isToast) {
					if (tipMessage.includes(res.data.message)) {
						resetMessage({
							showClose: true,
							message: res.data.message,
							type: 'warning',
						})
					} else {
						resetMessage({
							showClose: true,
							message: res.data.message,
							type: 'error',
						})
					}
					/**
					 * 登录失效跳转登录
					 */
					if (+res.data.code === 303 || +res.data.code === 10000) {
						if (window.location.pathname === '/login') return
						let pathname = null
						if (window.location.pathname) {
							pathname = '?name=' + (window.location.pathname.substr(1) + '&'+window.location.search.substr(1))
                        }
						// 应急首页、登录定制化配置
						// 行业标签 meet_emergency    应急
						// const branchIndustry = store.state.dynamic.pageSetData?store.state.dynamic.pageSetData.branchIndustry:""
						// if(branchIndustry === 'meet_emergency'){
						// 	router.replace('/yj_training_options')
						// 	return
						// }

						let devOptions = localStorage.getItem('devOptions');
						// alert(devOptions)
                        localStorage.clear()
						sessionStorage.clear()
						localStorage.setItem('devOptions',devOptions)
						window.location = `//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login${pathname}`

						// resolve('跳转登录页')
					}
					if (+res.data.code === 403) {
						if (window.location.pathname === '/login') return
						window.location = `https://${window.location.host}/login`
						localStorage.clear()
						sessionStorage.clear()
						resolve('跳转登录页')
					}
					if (+res.data.code === 302) {
						// if(window.location.pathname === '/login') return
						// window.location = `https://${window.location.host}/login`
						// localStorage.clear()
						// sessionStorage.clear()
						console.log('1111111')
						resolve({
							message: res.message,
							headers: res.headers,
						})
					}
				}
				resolve({
					...res.data,
					headers: res.headers,
				})
			})
			.catch((err) => {
				  reject(err)
				console.error(err)
				if (err && err.message && isToast) {
					if (err.message === 'Network Error') {
						resetMessage({
							showClose: true,
							message: '网络异常',
							type: 'error',
						})
					}
					if (err.message.indexOf('timeout') !== -1) {
						resetMessage({
							showClose: true,
							message: '网络超时',
							type: 'error',
						})
					}
				}
			})
	})
}

export default API
