<template>
    <div
        id="J_prismPlayer"
    />
</template>

<script>
/* eslint-disable */

export default {
    name: "videoplayer",
    props: {
        value: {
            type: [String],
            default: ""
        },
    },

    data() {
        return {
            player: null,
        };
    },
    mounted() {
    },
    methods: {
        dispose() {
            // 关闭窗口关闭播放。
            this.player.dispose();
        },
        initPlayer(source,params) {
            // source 资源链接。用于audio和video.
            const player = new Aliplayer(
                {
                id: 'J_prismPlayer',
                width: "100%",// 播放器高度
                height: "600px", // 播放器宽度
                autoplay: false,//播放器是否自动播放
                vid : source.videoMeta.videoId,// 媒体转码服务的媒体Id。
                playauth : source.playAuth, // 视频播放凭证
                cover: source.videoMeta.coverURL,// 播放器默认封面图片，请填写正确的图片url地址。需要autoplay值为false时，才生效。Flash播放器封面也需要开启允许跨域访问。
                qualitySort: 'asc',// 指定排序方式，只有使用vid + plauth播放方式时支持 asc：示按正序排序（即：从小到大排序） 默认值：asc，仅H5支持。
                format: params.format,// 音频  指定播放地址格式  只有使用vid的播放方式时支持可选值
                mediaType: params.mediaType, // 指定返回音频还是视频，只有使用vid的播放方式时支持，默认值为video
                vodType: '1',
                type: '1',//音频1  视频空
                encryptType: 1,// 加密类型，播放点播私有加密视频时，默认值为0，取值：0~1。
                isLive: false,// 播放内容是否为直播，直播时会禁止用户拖动进度条。
                rePlay: false,// 播放器自动循环播放
                playsinline: true,// H5是否内置播放，有的Android浏览器不起作用。
                preload: false,// 播放器自动加载，目前仅H5可用
                controlBarVisibility: 'hover',// 控制面板的实现，默认为值为：hover。click：点击 hover：停留 always
                useH5Prism: true,// 指定使用H5播放器。
                // skinLayout 功能组件布局配置，不传该字段使用默认布局
                skinLayout: [
                    {
                        name: "bigPlayButton",
                        align: "cc",
                        x: 0,
                        y: 0
                    },
                    {
                        name: "controlBar",
                        align: "blabs",
                        x: 0,
                        y: 0,
                        children: [
                            {
                                name: "progress",
                                align: "blabs",
                                x: 0,
                                y: 44
                            },
                            {
                                name: "playButton",
                                align: "tl",
                                x: 15,
                                y: 12
                            },
                            {
                                name: "timeDisplay",
                                align: "tl",
                                x: 10,
                                y: 7
                            },
                            {
                                name: "fullScreenButton",
                                align: "tr",
                                x: 10,
                                y: 12
                            },
                            {
                                name: "volume",
                                align: "tr",
                                x: 5,
                                y: 10
                            }
                        ]
                    }
                ]
            },
             player => {
                    console.log("音频播放器创建了", player);
                }
            );
            this.player = player;
        },
    }
};
</script>

<style scoped></style>
