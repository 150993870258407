
const routerArr = [
    // {
    //     path: '/',
    //     meta: { title:'登录' , login:false },
    //     redirect: { path: '/login' },
    //     component: () => import(/* webpackChunkName: 'entrance' */ './page/login.vue'),
    // },
    {
      path: '/',
      meta: { title:'首页' , login:false },
    },
    {
        path: '/login',
        name: 'login',
        meta: { title:'登录' , login:false },
        component: () => import(/* webpackChunkName: 'entrance' */ './page/login.vue'),
    },
    {
        path: '/register',
        name: 'register',
        meta: { title:'注册' , login:false },
        component: () => import(/* webpackChunkName: 'entrance' */ './page/register.vue'),
    },
    {
        path: '/forget',
        name: 'forget',
        meta: { title:'忘记密码' , login:false },
        component: () => import(/* webpackChunkName: 'entrance' */ './page/forget.vue'),
    },
    // 人社分销相关协议法规start
    {
        path: '/registerProtocol',
        name: 'registerProtocol',
        meta: { title:'用户注册协议' , login:false },
        component: () => import(/* webpackChunkName: 'registerProtocol' */ './page/renshefenguan/protocol.vue'),
    },
    {
        path: '/user-privacy',
        name: 'UserPrivacy',
        meta: { title:'个人信息保护制度' , login:false },
        component: () => import(/* webpackChunkName: 'UserPrivacy' */ './page/renshefenguan/privacy.vue'),
    },
    {
        path: '/network-regulation',
        name: 'NetworkRegulation',
        meta: { title:'网络安全管理制度' , login:false },
        component: () => import(/* webpackChunkName: 'NetworkRegulation' */ './page/renshefenguan/regulation.vue'),
    },
    // 人社分销相关协议法规end
    {
        path: '/service-agreement',
        name: 'service-agreement',
        meta: { title:'用户协议' , login:false },
        component: () => import(/* webpackChunkName: 'entrance' */ './page/service-agreement.vue'),
    },
    {
        path: '/privacy',
        name: 'privacy',
        meta: { title:'法律声明及隐私政策' , login:false },
        component: () => import(/* webpackChunkName: 'entrance' */ './page/privacy.vue'),
    },
    {
        path: '/shuidianPrivacy',
        name: 'shuidianPrivacy',
        meta: { title:'法律声明及隐私政策' , login:false },
        component: () => import(/* webpackChunkName: 'entrance' */ './page/shuidianPrivacy.vue'),
    },
    
    // {
    //     path: '/demo_homepage',
    //     name: 'demo',
    //     meta: { title:'法律声明及隐私政策' , login:false },
    //     component: () => import(/* webpackChunkName: 'entrance' */ './page/demo.vue'),
    // },
    
]
export default routerArr
