<template>
  <div class="personalInfoForm">
      <el-form class="form" ref="form" :model="form" label-width="84px">
        <el-form-item label="姓名：">
            <el-input v-model="form.name" :disabled="isRealName" placeholder="请输入姓名" style="width:320px"></el-input>
        </el-form-item>
        <el-form-item label="昵称：">
            <el-input v-model="form.nickname" placeholder="请输入昵称" :maxlength="15" style="width:320px"></el-input>
        </el-form-item>
        <el-form-item class="genderRadio" label="性别：">
            <el-radio-group class="radioGroup" v-model="form.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
                <el-radio :label="3">保密</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="生日：" >
            <el-date-picker
                v-model="form.birthday"
                :picker-options="pickerOptions"
                style="width:320px"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="所在城市：">
            <el-select
                v-model="form.areaCode"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                style="width:320px"
                :loading="loading">
                <el-option
                    v-for="item in filterOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="身份证号：" v-if="form.idCardNo">
            <el-input v-model="form.idCardNo" disabled placeholder="请输入身份证号" style="width:320px"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" v-else>
            <el-button class="identifyBtn" @click="toIdentify">前往身份认证</el-button>
        </el-form-item>
        <el-form-item>
            <el-button class="saveBtn" type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MD5 from 'js-md5'
export default {
    name:'personalInfoForm',
    data(){
        return{
            loading:false,
            pickerOptions:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            filterOptions:[],
            form:{
                name:'',
                gender:'',
                birthday:'',
                areaCode:'',
                nickname:''
            },
            areaList:[],
            isRealName:false
        }
    },
    async mounted(){
        this.postIsRealNameAuthentication()
        await this.getTree()
        this.getPersonalInfo()
    },
    methods:{
        // 我的资料
        postIsRealNameAuthentication () {
            this.$api.monitor.postIsRealNameAuthentication({}).then(res => {
                if(res.success){
                    const m = `${localStorage.getItem('token')}true`
                    this.isRealName = res.data === MD5(m)
                }
            })
        },
        getPersonalInfo(){
            this.$api.usercenter.findUser().then(res => {
                if(res.code == 0){
                    let {name,nickname,gender,birthday,areaCode,idCardNo} = res.data
                    this.form = {name,nickname,gender,birthday,areaCode,idCardNo}
                    this.remoteMethod('',areaCode)
                }
            })
        },
        remoteMethod(query,code){
            if (query !== '') {
                setTimeout(() => {
                    this.filterOptions = this.areaList.filter(item => {
                        return item.name.includes(query)
                    });
                }, 200);
            } else {
                this.filterOptions = this.areaList.filter(item => {
                    return item.code == code
                })
            }
        },
        //获取城市列表
        getTree(){
            if(this.$store.state.areaList.length>0){
                this.areaList = this.$store.state.areaList
            }else{
                return this.$api.usercenter.getTree().then(res => {
                    res.data.forEach(item => {
                        const L1Name = item.name
                        item.children && item.children.length && item.children.forEach(el => {
                            const L2Name = el.name
                            if(!el.children){
                                this.areaList.push({
                                    name: `${L1Name}-${L2Name}`,
                                    code: el.code,
                                    id: el.id
                                })
                            }else if(el.children.length){
                                el.children.forEach(v => {
                                    const L3Name = v.name
                                    this.areaList.push({
                                    name: `${L1Name}-${L2Name}-${L3Name}`,
                                    code: v.code,
                                    id: v.id
                                    })
                                })
                            }
                        })
                    })
                    this.$store.commit('saveCityTree',this.areaList)
                })
            }
        },
        onSubmit(){
            if(localStorage.getItem("theme")=="shanghaijiaoda"){
                this.$api.usercenter.updateShjdUser(this.form).then(res => {
                    if(res.success){
                        this.$message.success('修改成功')
                        this.updateUserInfo()
                    }
                })
            }else{
                if(!this.form.nickname || !this.form.nickname.length){
                    this.$message.error('昵称不能为空')
                    return
                }
                this.$api.usercenter.updateUser(this.form).then(res => {
                    if(res.success){
                        this.$message.success('修改成功')
                        this.updateUserInfo()
                    }
                })
            }
        },
        updateUserInfo(){
            this.$api.usercenter.findUser().then(res => {
                if(res.code == 0){
                    this.$store.commit('saveSubUserInfo',res.data)
                    localStorage.setItem('subUserInfo',JSON.stringify(res.data))
                }
            })
        },
        toIdentify(){
            this.$parent.$emit('changeTab','身份认证')
        }
    }
}
</script>

<style lang="stylus" scoped>
    .form  
        width 404px
        margin 0 auto
        /deep/ .el-form-item
            margin-bottom 16px
        /deep/ .el-form-item__label
            padding 0
            font-size 16px
            color #333
        .genderRadio
            /deep/ .el-form-item__content
                line-height 40px
            .radioGroup
                /deep/ .el-radio__label
                    font-size 16px
                    color #333
        .saveBtn
            margin-top 24px
            width 320px
            height 40px
            background #316FFF
        .identifyBtn {
            width 100%
            padding 9px 20px
            /deep/ >span {
                font-size: 16px;
                color: #666666;
                line-height: 22px;
            }
        }
</style>