export default (API) => ({
  // 获取应急全员定向培训-项目选择列表数据
  getOptionsList({ dataId = '' }) {
    return API({
      url: `/modular/ds/v1/sheet/getDataById`,
      method: 'post',
      params: {
        dataId,
      },
    });
  },
});
