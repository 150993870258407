export default API => ({
 // 获取当前用户对机构的评价
findMyBranchEvaluation({data,params}) {
  return API({ 
        url: `/saas/v1/branchEvaluation/findMyBranchEvaluation`,
        data,
        params,
        method: 'get'
    }); 
},
// 评价保存
branchEvaluationSave({data,params}) {
  return API({ 
        url: `/saas/v1/branchEvaluation/save`,
        data,
        params,
        method: 'post'
    }); 
},

})
