export const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[124567]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
export const isPhoneLogin = /^1[3-9]\d{9}$/
export const isEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
export const isIdentitycard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
export const isWorkPhone = /(^(\d{11})$|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/
export const passReg = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![\W]+$)[0-9A-Za-z\W]{8,20}$/

// 判断是整数：
export const intReg = /^[0]{1}$|^[1-9]{1}[0-9]*$/

export const phoneValidatorLogin = (rule, value, callback) => {
    if ( isPhoneLogin.test(value) ) {
        callback()
    } else {
        callback(new Error('请填写正确的手机号'))
    }
}

export const phoneValidator = (rule, value, callback) => {
    if ( isPhone.test(value) ) {
        callback()
    } else {
        callback(new Error('请填写正确的手机号'))
    }
}

export const workPhoneValidator = (rule, value, callback) => {
    if ( isWorkPhone.test(value) ) {
        callback()
    } else {
        callback(new Error('请输入正确的电话号'))
    }
}

export const emailValidator = (rule, value, callback) => {
    if ( isEmail.test(value) ) {
        callback()
    } else {
        callback(new Error('请输入正确的电子邮箱'))
    }
}

export const passwordValidator = (rule, value, callback) => {
    if( passReg.test(value) ){
        callback()
    }else{
        callback(new Error('请设置8-20位，数字、大小写字母、字符组合密码'))
    }
}
export function organCodeValidator (rule, value, callback) {
    const reg = /^[A-Z0-9]{18}$/;
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error('统一社会信用代码18位数字或大写字母组成'));
    }
  }

  // 元氏县社会信用统一代码和注册号 15位或者18位
export function organCodeValidatorysx (rule, value, callback) {
    const reg = /(^[A-Z0-9]{18}$)|(^[A-Z0-9]{15}$)/;
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error('统一社会信用代码/注册号18/15位数字或大写字母组成'));
    }
  }
// 18位身份证号码校验
export function idCardRegex (rule, value, callback) {
    const reg = /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}[0-9X]$/;
    if (reg.test(value)) {
      callback();
    } else {
        callback(new Error('请输入正确的身份证号'))
    }
  }
  

