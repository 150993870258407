<template>
  <div>
    <div class="faceAuthDialog">
      <el-dialog
        :visible.sync="faceAuthDialog"
        title="实名认证"
        center
        :close-on-click-modal="false"
        :before-close="faceAuthClose"
      >
        <div class="faceAuthText">
          请用微信或手机浏览器扫描下方二维码
          <div>完成实名认证</div>
        </div>
        <div class="imgParentBox" id="qrcodeBox"></div>
        <div class="faceAuthText">
          <img class="green" src="../assets/images/face/green.png" /><span
            class="smallText"
            >个人隐私信息安全保障中</span
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import QRCode  from "qrcodejs2"
import md5 from "js-md5";
export default {
  name: "realName",
  data() {
    return {
      QRCode: null,
      faceAuthDialog:false,
      userInfo:{},
      domainUrl: null,
    };
  },
  mounted() {
    let base =  require('js-base64').Base64;
    const hostname = window.location.hostname;
    const port = !!window.location.port ;
    let url= port? base.encode('hrss-pc.testing3.wdeduc.com'):base.encode(hostname);
    this.configuration(url)
    
  },
  methods: {
    // 关闭弹框
    faceAuthClose(){
      this.faceAuthDialog=false
      this.$parent.closeRealName = false;
    },
    //实名认证
    getUserInfo () {
        this.$api.face.getAccountUserInfo({}).then(res => {
          this.userInfo = res.data;
          this.qrcode();
        })
    },
    //实名认证
    configuration(url){
       this.$api.configure.configurationNew(url).then(res => {
          if(res.data){
              // 获取h5域名
              let h5_address = res.data.frontendConfig.find((item) => item.key === 'h5_address')
              if(h5_address && h5_address.value){
                    this.domainUrl = h5_address.value
                }else if(res.data.solutionInfo && res.data.solutionInfo.domain){
                    res.data.solutionInfo.domain.forEach(item => {
                        if (+item.frontend_template_id === 4) {
                            this.domainUrl = item.domain
                            this.getUserInfo()
                        }
                    })
                }
          }
        })
    },
    //实名认证
    async qrcode () {
        this.faceAuthDialog=true
        this.$nextTick(()=>{
            console.log('this.QRCode', this.QRCode)
            if (this.QRCode) return
            if(localStorage.getItem('theme')=='renshe'){
              this.domainUrl = localStorage.getItem('rensheH5Domain');
            }
            this.QRCode = new QRCode('qrcodeBox', {
                width: 150,
                height: 150,        // 高度
                // text:  `https://${this.domainUrl || 'hrss-stu.testing2.wdeduc.com'}/login?phone=${this.userInfo.phone}`,   // 二维码内容
                text:  `${window.location.protocol}//${this.domainUrl}/login?phone=${this.userInfo.phone}`,   // 二维码内容
                display: 'none'
                // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                // background: '#f0f',   // 背景色
                // foreground: '#ff0'    // 前景色
            })
        })
    },
  },
};
/* eslint-disable no-new */
</script>
<style lang="less" scoped>
.faceAuthDialog {
  /deep/.el-dialog {
    width: 389px;
    height: 386px;
    border-radius: 10px;
  }
  /deep/.el-dialog__header {
    padding: 32px 26px 12px 0px;
    /deep/.el-dialog__title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1b2257;
      line-height: 34px;
    }
  }
  .faceAuthText {
    margin-top: 24px;
    text-align: center;
  }
  .smallText {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    vertical-align: middle;
  }
  .green {
    width: 14px;
    height: 16px;
    vertical-align: middle;
  }
  /deep/.el-dialog--center .el-dialog__body {
    padding: 0px 60px 0px 60px !important;
  }
  .imgParentBox {
    margin: 0 auto;
    margin-top: 24px;
    width: 150px;
    height: 150px;
    img {
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
