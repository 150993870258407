const UrlName = '/resource/v1/resource'
const UrlNameResAccessToken = '/resource/v1/resAccessToken'
export default API => ({
  // 老接口 可能需要替换
//   getVideoPlayURLById ({ data, params }) {
//     return API({ url: `${UrlName}/getVideoPlayURLById`, data, params })
//   },

  // oss上传获取凭证等图片
  getAliyunConf() {
    return API({ url: '/resource/v1/resource/getUpFloadFileVoucher', method:  'post' })
  },
  // oss 上传文档的凭证
  getAliyunDocConf() {
    return API({ url: '/resource/v1/resource/getUploadDocVoucher', method:  'post' })
  },
  //   新接口
  getPlayAuthToken ({ data, params }) {
    return API({ url: `${UrlNameResAccessToken}/getPlayAuthToken`, data, params })
  },
  getPlayAuthTokenInfo ({ data, params }) {
    return API({ url: `${UrlNameResAccessToken}/getPlayAuthTokenInfo`, data, params })
  },
  // 老接口 可能需要替换   图片用老接口
  getImageOrDocUrl ({ data, params }) {
    return API({ url: `${UrlName}/getImageOrDocUrl`, data, params })
  },
    // 新接口 文档
    getDocumentAccessToken ({ data, params }) {
        return API({ url: `${UrlNameResAccessToken}/getDocumentAccessToken`,data, params })
    },

  // 最近学习
  getVideoPlayURL ({ data, params }) {
    return API({ url: `${UrlName}/getVideoPlayURL`, data, params })
  },

  // 查视频完整url
  findById ({ data, params }) {
    return API({ url: `${UrlName}/findById`, data, params })
  },
})