export default API => ({
    //培训任务线下实训预约列表
    // 获取用户指定培训任务可选择线下实训列表及预约状态
    getUserTaskTrainingList(params) {
        return API({
            url: '/task/v1/client/getUserTaskTrainingList',
            params,
        })
    },
    // 判断用户指定培训任务是否已经预约线下实训项目
    isUserTaskTrainingReserved(params) {
        return API({
            url: '/task/v1/client/isUserTaskTrainingReserved',
            params,
        })
    },
    // 重新预约新的线下实训项目并取消旧的预约
    replaceTaskTraining(params) {
        return API({
            url: '/task/v1/client/replaceTaskTraining',
            params,
            method: 'POST'

        })
    },
    //  预约指定线下实训项目
    reserveTaskTraining(params) {
        return API({
            url: '/task/v1/client/reserveTaskTraining',
            params,
            method: 'POST'
        })
    },
    // 取消预约指定线下实训项目
    cancelTaskTraining(params) {
        return API({
            url: '/task/v1/client/cancelTaskTraining',
            params,
            method: 'POST'

        })
    },
})

