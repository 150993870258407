/**
 *@functionName:
 *@params1: 参数1
 *@params2: 参数2
 *@description:个人中心-我的消息
 *@date: 2021-08-16 11:03:26
*/
const urlName = '/notify/v1'
export default API => ({
    // 列表
    findByCondition(params,data) {
        return API({
            url: `${urlName}/user/msg/list` ,
            method:  'post',
            params,
            data
        });
    },
    // 查询是否有未读消息
    existUnread(){
        return API({
            url: `${urlName}/user/msg/existUnread` ,
            method:  'get',
        });
    },
    // 全部已读消息
    readAllMessage(){
        return API({
            url: `${urlName}/user/msg/readAll` ,
            method:  'get',
        });
    },
    // 删除消息
    deleteMessage(params) {
        return API({
            url: `${urlName}/user/msg/delete` ,
            method:  'get',
            params,
        });
    },
    // 阅读消息
    readOne(params) {
        return API({
            url: `${urlName}/user/msg/readOne` ,
            method:  'get',
            params,
        });
    }
})

