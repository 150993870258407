
const routerArr = [
    {
        path: '/learning',
        name: 'learning',
        meta: { title:'学习中心' , login:true },
        component: () => import(/* webpackChunkName: 'learning' */ './page/learning.vue'),
    },
    {
        path: '/learning/taskDetail',
        name: 'taskDetail',
        meta: { title:'培训任务详情' , login:true  },
        component: () => import(/* webpackChunkName: 'learning' */ './page/taskDetail.vue'),
    },
    {
        path: '/examDetails',
        name: 'examDetails',
        meta: { title:'考试详情' , login:true },
        component: () => import(/* webpackChunkName: 'learning' */ './page/examDetails.vue'),
    },
    {
        path: '/learning/courseDetails',
        name: 'courseDetails',
        meta: { title:'课程详情' , login:true },
        component: () => import(/* webpackChunkName: 'learning' */ './page/courseDetails.vue'),
    },
    {
        path: '/checkFormWj',
        name: 'checkFormWj',
        meta: { title:'表单问卷' , login:true },
        component: () => import(/* webpackChunkName: 'learning' */ './page/checkFormWj.vue'),
    },
    {
        path: '/learning/formPage',
        name: 'formPage',
        meta: { title:'表单问卷' , login:true },
        component: () => import(/* webpackChunkName: 'learning' */ './page/formPage.vue'),
    },
    {
        path: '/learning/assignment',
        name: 'assignment',
        meta: { title:'表单问卷' , login:true },
        component: () => import(/* webpackChunkName: 'learning' */ './page/assignment-submit.vue'),
    },
    {
        path: '/learning/subscribeList',
        name: 'subscribeList',
        meta: { title:'预约列表' , login:true },
        component: () => import(/* webpackChunkName: 'education' */ './page/subscribeList.vue'),
    },
    {
        path: '/course/play',
        name: 'coursePlay',
        meta: { title:'视频' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './course/video.vue'),
    },
    /**
     * 老路由
     */
    {
        path: '/video',
        name: 'video',
        meta: { title:'视频' , login:true },
        component: () => import(/* webpackChunkName: 'exam' */ './course/video.vue'),
    },
]
export default routerArr
