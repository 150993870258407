const urlName = '/usercenter/v2/t-c/region'
export default (API) => ({
	getTree() {
		return API({
			url: `${urlName}/getTree`,
		})
    },
    getRegioni18nTree() {
		return API({ url: `/usercenter/v2/t-b/regionI18n/getTree` })
	},
})
