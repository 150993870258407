const urlName = '/exam/v1';
export default API => ({
    // 加入指定练习，返回用户试卷ID
    // joinExam(practiceId){
    //     return API({
    //         url:`${urlName}/userPractice/joinExam?practiceId=${practiceId}`,
    //         method:'get',
    //     })
    // },
    joinExam ({ data, params }) {
        return API({ url: `${urlName}/userPractice/joinExam`, data, params })
    },
    // 考试中-试题信息
    findQuestForExam(userPracticeId){
        return API({
            url:`${urlName}/userPractice/findQuestForExam?userPracticeId=${userPracticeId}`,
            method:'get',
        })
    },
    // 整体提交试卷答案
    commitAllPaper(data){
        return API({
            url:`${urlName}/userPractice/commitAllPaper`,
            method:'post',
            data
        })
    },
    // 单次考试结果-详情
    findUserPaperInfo(userPracticeId){
        return API({
            url:`${urlName}/userPractice/findUserPaperInfo?userPracticeId=${userPracticeId}`,
            method:'get',
        })
    },
    //练习列表
    findUserExams ({ data, params }) {
        return API({ url: `${urlName}/userPracticeX/findUserExams`, data, params })
    },
    //练习模式
    modelInfo ({ data, params }) {
        return API({ url: `${urlName}/userPractice/modelInfo`, data, params })
    },
    // 单次提交练习试卷答案
    commitPaper(data){
        return API({
            url:`${urlName}/userPractice/commitPaper`,
            method:'post',
            data
        })
    },
    // 问题标记接口(mark:(0:取消标记;1:标记))
    questionMark(businessId, businessType, mark, practiceId, questionId){
        return API({
            url:`${urlName}/userPracticeQuestionX/question/mark?businessId=${businessId}&businessType=${businessType}&mark=${mark}&practiceId=${practiceId}&questionId=${questionId}`
        })
    },
})