const urlName = '/order/v1';
import URL from '../../config/url';
export default API => ({
    //查询所有可用商品分类
    findAll(params) {
        return API({
            url: `${urlName}/open/goodsCategory/findAll` ,
            method:  'get',
            params
        });
    },
    findGoodsList(params, data) {
        return API({
            url: `${urlName}/saleSystem/distributionGoods/mall/channelGoodsList` ,
            method:  'POST',
            params,
            data
        })
    },
    getConfiguration() {
        let url = URL.confUrl;
        return API({ 
            url: `/modular/admin/v1/configurations/${url}`
        });
    },
})

