export default API => ({
    // oss上传获取凭证等图片
    getAliyunConf() {
        return API({ url: '/resource/v1/resource/getUpFloadFileVoucher', method:  'post' })
    },
    // oss 上传文档的凭证
    getAliyunDocConf() {
        return API({ url: '/resource/v1/resource/getUploadDocVoucher', method:  'post' })
    },
    // 课时资源音频 视频保存
    saveVideoAndAudio(data) {
        return API({ url: '/resource/v1/resource/saveVideoAndAudio', method:  'post', data })
    },
    // 获取上传视频的id
    getUploadVideoParam(url) {
        return API({ url: `/resource/v1/resource/createUploadVideo?${url}`, method:  'post' })
    },
    refreshUploadVideo(url) {
        return API({ url: `/resource/v1/resource/refreshUploadVideo?${url}` })
        // return axios.get('/resource/v1/resource/refreshUploadVideo', data);
    },
    // 课时资源-保存音频和文档V2 放弃了。
    saveFile(data) {
        return API({ url: '/resource/v1/resource/saveAudioAndDoc', method:  'post',  data})
    },
    saveImageAndDoc(data) {
        return API({ url: '/resource/v1/resource/saveImageAndDoc', method:  'post',  data})
    },
    // // 课时资源-新增视频 放弃了。
    saveVideo(data) {
        return API({ url: '/resource/v1/resource/saveVideo', method:  'post',  data})
    },
    // 课时资源-修改
    updateResource(data) {
        return API({url: '/resource/v1/resource/updateResourceById', method:  'post',  data});
        // return API({url: `/resource/v1/resource/updateResourceById?id=${data.id}&name=${data.name}&resourceCategoyId=${data.resourceCategoyId}`});
    },
    // 素材资源 -是否被引用
    checkCourseReference(url) {
        return API({ url: `/resource/v1/resource/checkCourseReference?${url}`, method:  'post'})
    },
    // 课时资源-删除
    deleteResource(url) {
        return API({ url: `/resource/v1/resource/delete?${url}`, method:  'post'})
    },
    // // 课时资源-列表
    findByConditionWithPage(url, data) {
        return API({ url: `/resource/v1/resource/findByConditionWithPage?${url}`, method:  'post',  data})
    },

    // // 查视频完整url
    previewMedia(videoId) {
        return API({ url: `/resource/v1/resource/getVideoPlayURL?videoId=${videoId}`})
    },
    // 查看完整视频 通过vid
    previewMedias(videoId) {
        return API({ url: `/resource/v1/resAccessToken/getPlayAuthToken?resId=${videoId}`})
    },
    // 文档，图片url
    getImageOrDocUrl(id) {
        return API({ url: `/resource/v1/resource/getImageOrDocUrl?id=${id}`})
    },
    // 文档，图片url
    getImageOrDocUrls(id) {
        return API({ url: `/resource/v1/resAccessToken/getDocumentAccessToken?resId=${id}`})
    },
    // 根据资源ID查询视频播放地址列表
    getVideoPlayURLById(id) {
        return API({ url: `/resource/v1/resource/getVideoPlayURLById?id=${id}`})
    },
    // 根据资源ID查询视频播放地址列表
    getAudioOrDocUrl(id) {
        return API({ url: `/resource/v1/resource/getAudioOrDocUrl?id=${id}`})
    },
    findById(id) {
        return API({ url: `/resource/v1/resource/findById?id=${id}`})
    },
    //  版权归属方接口
    findValidByWithPage(data){
        return API({ url: `/resource/v1/copyrightUnit/findValidByWithPage`,method:'post',data})
    },
    downHomeworkFile(resId){
        return API({ url: `/resource/v1/resource/downHomeworkFile?resId=${resId}`})
    },
    // 获取客户信息
    getCoustomerList(params) {
        return API({ url: `/usercenter/v1/saas/getAll?${params}`})
    },
    // scrom素材预览 ----开始
    getScormInfoById(params){
        return API({ url: `/resource/v1/resource/getScormInfoById?resId=${params.id}`})
    },
})