const url = '/apis/noAuth/standard';

export default API => ({
  getStandardList(data) {
    return API({ url: `${url}/getStandardList`, method: 'post', data });
  },
  getFirstCategoryTree() {
    return API({ url: `${url}/getFirstCategoryTree`, method:'get' })
  },
  getStandardById(id) {
    return API({ url: `${url}/getStandardById?id=${id}`, method:'get' })
  }
})