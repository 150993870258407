const urlName = '/order/v1'
export default API => ({
    //查询所有可用商品分类
    findstuAll(params) {
        return API({
            url: `${urlName}/open/goodsCategory/findAllBranchBySaasId`,
            params,
            method:  'get',
        });
    },
    //查询机构下所有可用商品分类
    findAll() {
        return API({
            url: `${urlName}/platform/goodsCategory/branchGetGoodsCategoryTree` ,
            method:  'get'
        });
    },
    //学员查询商品
    findstuByCondition(params,data) {
        return API({
            url: `${urlName}/trainOpenPlatform/stu/findGoods?pageSize=${params.pageSize}&pageNum=${params.pageNum}` ,
            method:  'post',
            data
        });
    },
    //培训服务商查询商品
    findByCondition(data) {
        return API({
            // url: `${urlName}/trainOpenPlatform/branch/findGoods?pageSize=${data.pageSize}&pageNum=${data.pageNum}&selectBranchId=${data.selectBranchId?data.selectBranchId:''}&categoryCode=${data.categoryCode?data.categoryCode:''}&name=${data.name?data.name:''}` ,
            url: `${urlName}/trainOpenPlatform/branch/findGoods`,
            params: data,
            method:  'get',
        });
    },
    //查询普通商品详情
    findstuGoodsDetailById(data) {
        return API({
            url:`${urlName}/open/goodsLearner/findGoodsDetailById?goodsId=${data.goodsId}`,
            method:'get',
        });
    },
    // 查询分销商品详情
    findGoodsDetailById(data) {
        return API({
            url:`${urlName}/trainOpenPlatform/getDistributionGoodsInfo?id=${data.goodsId}`,
            method:'get',
        });
    },
    // 申请分销
    getDistribute(data) {
        return API({
            url:`${urlName}/trainOpenPlatform/distribute?distributionGoodsId=${data.distributionGoodsId}`,
            method:'get',
        });
    },
    //培训开放平台学生端按条件分页查询服务商
    getStuBranches(data) {
        return API({
            url:`${urlName}/trainOpenPlatform/stu/findBranch?pageSize=${data.pageSize}&pageNum=${data.pageNum}&branchName=${data.branchName}`,
            method:'post',
        });
    },
    //培训开放平台服务商端按条件分页查询服务商
    getProBranches(data) {
        return API({
            url:`${urlName}/trainOpenPlatform/branch/findBranch?pageSize=${data.pageSize}&pageNum=${data.pageNum}&branchName=${data.branchName}`,
            method:'post',
        });
    },
    //立即报名下单
    order(data) {
        return API({
            url:`${urlName}/open/order/placeOrder`,
            method:'post',
            data
        });
    },
    //判断是否可以购买
    checkUserCanByGoods(data) {
        return API({
            url:`${urlName}/open/goods/checkUserCanByGoods?goodsId=${data.goodsId}&orderType=${data.orderType}`,
            method:'get',
        });
    },
    //判断是否可以购买
    checkAdmin() {
        return API({
            url:`/usercenter/v1/administrator/isAdmin`,
            method:'get',
        });
    },
})

