<template>
    <div class="courseItem-box">
        <el-tree
          :data="itemList"
          :props="defaultProps"
          accordion
          highlight-current
          :default-expanded-keys="expandedKeys"
          node-key="id"
          ref="tree"
          class="courseItem-select-tree"
          @node-click="handleNodeClick"
          @node-expand="nodeExpandClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
              <!-- 对勾 学过没学过 -->
            <i class="el-icon-check " v-if="data.type" :class="data.studyInfo && data.studyInfo.learnRate===0 ? 'nolearned' : 'learned'"></i>
            <span
              :title="node.label"
              class="dy-label"
              :class="{lastplay:data.studyInfo && data.studyInfo.lastPlay}"
              :style="{
                marginLeft:
                  data.parentId == 0 &&
                  data.childrenList &&
                  data.childrenList.length > 0
                    ? '0px'
                    : '20px'
              }"
            >
              <svg-icon icon-class="shipin" v-if="data.type === 1"></svg-icon>
              <svg-icon icon-class="audio" v-if="data.type === 2"></svg-icon>
              <svg-icon icon-class="lianxi" v-if="data.type === 10"></svg-icon>
              <svg-icon icon-class="zhiboke" v-if="data.type === 11"></svg-icon>
              <svg-icon icon-class="shixunke" v-if="data.type === 12"></svg-icon>
              {{ node.label }}
            </span>
            <span v-if="data.dateExpireStatus == 2 || courseExpireStatus == 2" class="guoqi">已过期不可查看</span>
            <span v-if="data.studyInfo && data.studyInfo.learnRate && data.type != 10 && data.type != 11 && data.type != 12 " class="learnRate">{{data.studyInfo && data.studyInfo.learnRate}}%</span>
            <span @click.stop="jumpReplay(data)" v-if="data.isLiveReplay && data.type === 11 && data.liveStatus === 2 && !data.lock" class="learnRate">观看回放</span>
            <span class="lock-icon"  v-if="data.lock">
                <svg-icon icon-class="lock"></svg-icon>
            </span>
          </span>
        </el-tree>
        <facedialog v-if="verification" :businessId="businessId" :confirmdialog="confirmdialog" :address="verificationAddress" :failAddress="verificationFailAddress" :type=1 :pauseFrame="true"></facedialog>
    </div>
</template>
<script>
import {goLiveHome} from "../modules/live/common/livePlatform";

export default {
    name: 'courseItem',
    props: {
        itemList: {
            type: Array,
            default: () => []
        },
        expandedKeys:{
            type: Array,
            default: () => []
        },
        courseName: {
            type: String,
            default: ''
        },
        courseExpireStatus:{
            type:Number
        },
        isFaceRecognize:{
            default:null,
        }
    },
     data () {
        return {
            defaultProps: {
                children: 'childrenList',
                label: 'name'
            },
            confirmdialog:{
              show:true,
            },
            // expandedKeys: [],
            // isFaceRecognize: null,
            activeNames: [0],
            isSelect:'',
            verification: false,
            businessId:'',
            verificationAddress: {},
            verificationFailAddress: {},
        }
     },
     created() {
        //  //taskType等于1是训练营过来的
        // if(this.$route.query.taskType == 1){
        //    console.log("zxc")
        // }else{
        //    this.findfaceNeed()
        // }
     },
     methods:{
        //  //查询培训任务规则
        // async findfaceNeed() {
        //     await this.$api.examination.findfaceNeed(this.$route.query.taskId).then(res=>{
        //         this.isFaceRecognize = res.data.isCourseFaceRecognize;
        //     })
        // },
        nodeExpandClick (data, node) {
            this.updateChildren(node)
        },
        updateChildren (list) {
            if (list.childNodes.length > 0) {
                list.childNodes.forEach(item => {
                    item.expanded = true;
                    this.updateChildren(item);
                })
            }
        },





         // 观看回放
         jumpReplay(item) {
            console.log('观看回放')
             goLiveHome(this, item, item.liveRoomId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
           // this.$api.live.getBjyPlayUrl({liveCourseId: item.liveRoomId}).then(async (resB) => {
           //   if(resB.data.roomStatus==1){ // 腾讯
           //     if(item.isReplay){
           //       this.$api.live.saveViewUser(item.liveRoomId).then((res) => {
           //         if(res.success) {
           //           this.$router.push({
           //             path: '/livePlayback',
           //             query: {
           //               roomId: item.liveRoomId,
           //               sourceId: item.detailId,
           //             }
           //           })
           //         }
           //       });
           //     }else{
           //       this.$message.warning('视频处理中，请次日查看~')
           //     }
           //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==4){ // 百家云
           //    this.$api.live.saveViewUser(item.liveRoomId).then((res) => {
           //      if(res.success) {
           //        this.$router.push({
           //          path: '/bjyLivePlayBack',
           //          query:{
           //            contentId: item.liveRoomId,
           //          }
           //        })
           //      }
           //    })
           //   }
           // });
         },
         //课程保存
        async postCourseLearningLogSaveLog (item) {
            const data = {
                courseId: this.$route.query.id || item.courseId, // 课程id
                taskId: this.$route.query.taskId || '',// 训练营或任务
                taskType:this.$route.query.taskType || null,//2：任务；1：训练营
                courseItemId: item.id, // 素材对应id
                cur: 1,// 当前观看位置
                max:1,// 视频/音频/文档页数
                pollingTime: 1,//循环调用接口时间

            }
            return await this.$api.course
                .postCourseLearningLogSaveLog({ data })
                .then(res => {
                console.log(res)
                })
        },
        // 获取实训详情
        getdrillDetail(item){
            this.$api.learning.getdrillDetail({id:item.detailId}).then(res => {
                if(res.data){
                    if(this.isFaceRecognize){
                        this.verificationAddress = {
                            path:window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`,
                            query:{
                                type:item.type
                            }
                        }
                    }else {
                        window.open(window.location.origin + `/training_detail?courseId=${res.data.courseId}&labId=${res.data.labId}`)
                    }

                }
            })
        },
        // 节点
         handleNodeClick(item){
             if(!item.type){
                 return false;
             }
             console.log(item,'item=====')
            // this.isSelect = e;
            if(item.dateExpireStatus == 2 || this.courseExpireStatus == 2){
                this.$message.info('已过期不可查看')
            }else {
                if(this.$route.query.taskType == 1 && (item.type === 11 || item.type === 12)){
                    this.$message.warning('无内容，无法学习')
                    return false;
                }
                if(item.lock){
                    this.$message.warning(`该内容未开启，开启条件为前面的内容学习进度达到 ${item.lockCourseRate}%`)
                    return false;
                }
                // 判断是否需要人脸验证
                // isRealName  是否已实名
                // const isRealName = localStorage.getItem('isRealName') || null
                var courseUserLearnInfo = JSON.parse(sessionStorage.getItem('courseUserLearnInfo'))
                if (this.isFaceRecognize && !(courseUserLearnInfo && courseUserLearnInfo.learnRate === 100)) {
                    this.businessId = item.courseId;
                    this.verification = true;
                    this.confirmdialog.show = true;
                    if(item.type === 10 || item.type === 12){
                        this.postCourseLearningLogSaveLog(item)
                    }
                    if(item.type === 10){
                        this.verificationAddress = {
                            path:'/practice',
                            query:{
                                practiceId:item.detailId,
                                pathName:this.$route.path,
                                taskType:this.$route.query.taskType || null,
                                id:this.$route.query.id,
                                taskId:this.$route.query.taskId,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath

                            }
                        }
                    }else if(item.type === 11){
                        if(item.liveStatus === 2){
                            this.$message.warning('直播已结束')
                            return
                        }
                        if(!item.detailId){
                            this.$message.warning('暂未关联直播，请稍后查看')
                            // this.$api.live.getBjyPlayUrl({liveCourseId: item.liveRoomId}).then(async (urlRes) => {
                          //   if (urlRes.data.roomStatus==4) {
                          //     this.$message.warning('直播已结束')
                          //   } else {
                          //     this.$message.warning('暂未关联直播，请稍后查看')
                          //   }
                          // })
                        } else {
                            goLiveHome(this, item, item.liveRoomId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))
                          // this.$api.live.getBjyPlayUrl({liveCourseId: item.liveRoomId}).then(async (resB) => {
                          //   if(resB.data.roomStatus==1){ // 腾讯
                          //     this.$api.live.audienceEnterRoom({id: item.liveRoomId}).then(async (res) => {
                          //       if (res.success) {
                          //         this.postCourseLearningLogSaveLog(item)
                          //         this.verificationAddress = {
                          //           path:window.location.origin + `/studentSee?type=1&roomId=${item.liveRoomId}`,
                          //           query:{
                          //             type:item.type
                          //           }
                          //         }
                          //       }
                          //     });
                          //
                          //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==5){ // 百家云
                          //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.detailId}`)
                          //   }else if(resB.data.roomStatus==4){ // 百家云sdk
                          //     this.$api.live.audienceEnterRoom({id: item.liveRoomId}).then(async (res) => {
                          //       if (res.success) {
                          //         window.open(window.location.origin + `/bjyCustomStudent?&roomId=${resB.data.room_id}`)
                          //       }
                          //     });
                          //   }else if(resB.data.roomStatus==6){
                          //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.detailId}`)
                          //   }});
                        }
                    }else if(item.type === 12){
                        this.getdrillDetail(item)
                    }else {
                        this.verificationAddress = {
                            path:'/course/play',
                            query:{
                                trainId:this.$route.query.taskId || this.$route.query.id,
                                courseId:item.courseId,
                                detailId:item.detailId,
                                studyProcess:item.studyInfo?item.studyInfo.learnRate:'',
                                courseName:this.courseName,
                                taskType:this.$route.query.taskType || null,//2：任务；1：训练营
                                taskId:this.$route.query.taskId,
                                id:this.$route.query.id,
                                backPathName:this.$route.path,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath
                            }
                        }
                    }
                    this.verificationFailAddress = {};
                } else {

                    //0 目录；1，视频；2，音频；3，文档；4，图片；6，scorm；9，试卷；10，练习；11，直播；12，实锤
                     if(item.type === 10 || item.type === 12){
                        this.postCourseLearningLogSaveLog(item)
                    }
                    if(item.type === 10){
                        this.$router.push({
                            path:'/practice',
                            query:{
                                practiceId:item.detailId,
                                pathName:this.$route.path,
                                taskType:this.$route.query.taskType || null,
                                id:this.$route.query.id,
                                taskId:this.$route.query.taskId,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath
                            }
                        })
                    }else if(item.type === 11){
                        if(item.liveStatus === 2){
                            this.$message.warning('直播已结束')
                            return
                        }
                        if(!item.detailId){
                            this.$message.warning('暂未关联直播，请稍后查看')
                            // this.$api.live.getBjyPlayUrl({liveCourseId: item.liveRoomId}).then(async (urlRes) => {
                          //   if (urlRes.data.roomStatus==4) {
                          //     this.$message.warning('直播已结束')
                          //   } else {
                          //     this.$message.warning('暂未关联直播，请稍后查看')
                          //   }
                          // })
                        } else {
                            goLiveHome(this, item, item.liveRoomId,localStorage.getItem('current_language'),localStorage.getItem('timezone'))

                            // this.$api.live.getBjyPlayUrl({liveCourseId: item.liveRoomId}).then(async (resB) => {
                          //   if(resB.data.roomStatus==1){ // 腾讯
                          //     this.$api.live.audienceEnterRoom({id: item.liveRoomId}).then(async (res) => {
                          //       if (res.success) {
                          //         this.postCourseLearningLogSaveLog(item)
                          //         window.open(window.location.origin + `/studentSee?type=1&roomId=${item.detailId}`)
                          //       }
                          //     });
                          //   }else if(resB.data.roomStatus==2 || resB.data.roomStatus==5){ // 百家云
                          //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.detailId}`)
                          //   }else if(resB.data.roomStatus==4){ // 百家云sdk
                          //     this.$api.live.audienceEnterRoom({id: item.liveRoomId}).then(async (res) => {
                          //       if (res.success) {
                          //         window.open(window.location.origin + `/bjyCustomStudent?&roomId=${resB.data.room_id}`)
                          //       }
                          //     });
                          //   }else if(resB.data.roomStatus==6){
                          //     window.open(window.location.origin + `/bjyStudent?&contentId=${item.detailId}`)
                          //   }
                          // });
                        }

                    }else if(item.type === 12){
                        this.getdrillDetail(item)
                    }else {
                        this.$router.push({
                            path:'/course/play',
                            query:{
                                trainId:this.$route.query.taskId || this.$route.query.id,
                                courseId:item.courseId,
                                detailId:item.detailId,
                                studyProcess:item.studyInfo?item.studyInfo.learnRate:'',
                                courseName:this.courseName,
                                taskType:this.$route.query.taskType || null,//2：任务；1：训练营
                                taskId:this.$route.query.taskId,
                                id:this.$route.query.id,
                                backPathName:this.$route.path,
                                backMoudle:this.$route.query.backMoudle,
                                backPath:this.$route.query.backPath
                            }
                        })
                    }

                }
            }

         }
     }
}
</script>
<style lang="stylus" scoped>
.courseItem-box{
    .courseItem-select-tree{
        /deep/.el-tree-node{
            border: 1px solid #D7DCEE;
            // margin-bottom 16px

            &.is-expanded{
                margin-bottom 16px
                .el-tree-node__content{
                    margin-bottom 16px
                }
            }
            .nolearned{
                color:#D3D3D3
                font-weight: bold;
                vertical-align: middle
            }
            .lock-icon{
                float right;
                height:60px;
                line-height :60px;
                padding-right:16px;
                color:#ccc;
            }

            .learned{
                color:#5D9CFF;
                font-weight: bold;
                vertical-align: middle
            }
            .custom-tree-node{
                width 100%
            }
            .dy-label{

                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #1B2257;
                width 400px
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block
                vertical-align: middle
            }
            .el-tree-node__content{
                height 60px
                line-height: 60px
                background: rgba(49, 111, 255, 0.07);
            }
            .el-tree-node__children{
                width 845px
                margin 0 auto
                .el-tree-node{
                    border:0
                    margin-bottom 0
                }
                .el-tree-node__content{
                    background: #F7FAFF;
                    margin-bottom 8px
                }
                .dy-label{

                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #1B2257;
                }
            }
            .el-tree-node__expand-icon{
                color:#1972FF;
                font-size:24px
            }
            .el-tree-node__expand-icon.is-leaf{
                color:transparent
            }

            .learnRate{
                float right
                padding-right 16px
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #43BC60;
            }
            .icon-label{
                width 14px
                vertical-align: middle;
            }
            .lastplay{
                color: #1972FF;
            }
        }
    }

}

</style>
