
const routerArr = [
    {
        path: '/toC',
        name: 'toC',
        meta: { title:'伟东云职业教育生态平台' , login:false },
        component: () => import(/* webpackChunkName: 'toC' */ './page/toC.vue'),
    },
]
export default routerArr
