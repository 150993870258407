/** 
可视化实施配置首页商品网格状展示
*/
<template>
  
  <div class="grid-layout-c-w">
    <!-- <div class="configTile"><font>课程列表</font>
      <span class="moreGoods" @click="toGoodsList">更多 <svg-icon icon-class="arrow-right"></svg-icon></span>
    </div>  -->
    <!-- 网格布局 -->
    <ul class="grid-layout-list couseGoodsListStyle"  v-if="courseListArray.length && !Array.isArray(courseListArray[0])">
      <li class="grid-layout-item" @click="toGoodsDetail(item)" v-for="item in courseListArray" :key="item.id">
        <img class="course-bg-img" :src="item.picUrl"/>
        <p class="course-name hide">{{item.name}}</p>
        <div class="price-signup">
          <span class="sale-price">{{item.isFree?'免费':`¥${item.salePrice}`}}<span class="base-price" v-show="!item.isFree">{{item.basePrice}}</span></span>
          <span class="signup-num">{{item.purchaseNo}}人已经报名</span>
        </div>
        <p class="course-tag" v-show="item.taskType">{{tagName(item.taskType)}}</p>
      </li> 
    </ul> 
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    name: 'GridLayoutCourse',
    props: {
      // pageSize: {
      //   type:Number,
      //   default: 10
      // },
      receiveProps: {
        type: [Object, Array],
        default: () => {
          return {
            tempData: {
              specialData: {
                classTitle: '',
                articleClassID: '',
                code: ''
              }
            }
          }
        },
      }
    },
    data() {
      return {
        courseListArray: [],
      }
    },
    mounted() {
      this.getData()
      console.log(this.receiveProps,'商品组件数据')
    },
    methods: {
      async getData() {
        const params = {
          pageNum: 1,
          pageSize: 5,
        }
        const dataObj = {
          categoryCodes: this.receiveProps.tempData.specialData.code,
          goodsIds: '',// 商品id，多个逗号隔开
          type: 3,
          name: ''
        }
        let res = await this.$api.coursegoods.findByConditionList(params, dataObj)
        if (res.data && res.data.list) {
          this.courseListArray = res.data.list;
        }
      },
      toGoodsList() {
        this.$router.push('/courseGoods')
      },
      toGoodsDetail(item) {
        this.$router.push({
            path: '/courseGoodsDetail',
            query: {
              goodsId: item.id,
              goodsKind: item.goodsKind //1 普通商品 2 分销商品
            }
        }) 
      },
      tagName(type) {
        let res = ''
        if(type === 1) {
          res = '必修课'

        } else if(type === 2) {
          res = '选修课'

        } else if(type === 3) {
          res = '公开课'

        }
        return res
      },
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.grid-layout-c-w
  width: 1200px 
  margin: 0 auto
  .configTile
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #1B2257;
    line-height: 28px;
    margin-top 32px
    padding-left 12px
    position relative
    height 28px;
    font:before

      position absolute
      content ''
      width: 3px;
      height: 18px;
      background: #316FFF;
      left 0
      top 50%
      transform translateY(-50%)
    .moreGoods
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #999999;
      line-height: 22px;
      position absolute
      right 0
      top 50%
      transform translateY(-51%)
      font-weight normal;
      cursor pointer
  .grid-layout-list
    width: 1200px
    margin: 0 auto
    display: flex
    flex-wrap: wrap
    .grid-layout-item
      position relative
      margin-right: 20px
      margin-bottom: 20px
      width: 224px;
      // height: 256px
      box-sizing: border-box
      // border 1px solid #eee
      padding-bottom: 15px
      // box-shadow: #000000  透明度8%  
      //    投影向下2px  模糊度：14
      box-shadow: 0px 2px 14px  rgba(0,0,0,.08)
      border-radius: 4px
      cursor: pointer
      
      .course-bg-img
        width: 224px;
        height: 168px;
        border-radius: 4px 4px 0 0
      .course-name
        width: 200px;
        margin: 12px auto 16px
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
      .price-signup
        width: 200px;
        margin 0 auto 
        display: flex 
        justify-content: space-between
        // align-items: flex-end 
        align-items: center
        .free 
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          height: 28px;
          font-weight: 800;
          color: #FF4655;
          line-height: 28px;
          text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
        .sale-price
          height: 28px;
          font-size: 18px;
          font-family: Avenir-Heavy, Avenir;
          font-weight: 800;
          color: #FF4655;
          line-height: 28px;
          text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
          .base-price
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
            padding-left 6px 
            text-decoration: line-through
        .signup-num
          height: 16px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
      .course-tag
        position absolute 
        left 4px
        top 4px 
        // width: 56px;
        height: 20px;
        padding 0 7px
        background: rgba(37, 37, 51, 0.7);
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
        border-radius: 2px;
        line-height: 20px 
        text-align: center 
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
      &:nth-child(5n) 
        margin-right: 0px
.hide 
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
</style>
