const urlName = '/exam/v1';
const USER = '/usercenter/v1';
export default API => ({
    // 获取考试基础信息
    findBasicExamById({params,data}){
        return API({
            url:`${urlName}/exam/findBasicExamById`,
            method:'get',
            params,
            data
        })
    },
    // 单次考试结果-统计
    findUserPaperSurface(userPaperId){
        return API({
            url:`${urlName}/userPaper/findUserPaperSurface?userPaperId=${userPaperId}`,
            method:'get',
        })
    },
    // 考试中-试题信息
    findQuestForExam(userPaperId,examId){
        return API({
            url:`${urlName}/userPaper/findQuestForExam?userPaperId=${userPaperId}&examId=${examId}`,
            method:'get',
        })
    },
    // 整体提交试卷答案
    commitAllPaper(data){
        return API({
            url:`${urlName}/userPaper/commitAllPaper`,
            method:'post',
            data
        });
    },
    // 加入指定考试，返回用户试卷ID
    joinExam({data,params}){
        return API({
            url:`${urlName}/exam/joinExam`,
            data,
            params,
            method:'get',
        })
    },
    findById(userId){
        return API({
            url:`${USER}/user/findById?userId=${userId}`,
            method:'get',
        })
    },
    // 单次考试结果-详情
    findUserPaperInfo(userPaperId){
        return API({
            url:`${urlName}/userPaper/findUserPaperInfo?userPaperId=${userPaperId}`,
            method:'get',
        })
    },
    // 查询训练营考试详情
    findExamListByIds(bussinessId, examId, type) {
        return API({ //简介
            url: `${urlName}/exam/findExamListByIds?bussinessId=${bussinessId}&examId=${examId}&type=${type}`,
            method:'get',
        });
    },
    // 查询指定考试历史记录
    findUserPaperListById(bussinessId, examId, type) {
        return API({ //简介
            url: `${urlName}/userPaper/findUserPaperListById?bussinessId=${bussinessId}&examId=${examId}&pageNum=1&pageSize=999&type=${type}`,
            method:'get',
        });
    },
    //任务规则
    findfaceNeed(taskId) {
        return API({
            url: `/task/v1/taskRule/findTaskRule?taskId=${taskId}`,
        })
    },
    // 用户切屏保存接口
    userSwitchScreen(userPaperId) {
        return API({
            url: `${urlName}/userPaper/monitor/userSwitchScreen?userPaperId=${userPaperId}`,
            method:'get',
        });
    },
    //人社分管定制接口-考试计划保存和更新
    saveExamPlanRoomUserPaper({params}) {
        return API({
            url: `/affairs/v1/examPlanRoomUserPaper/save`,
            method:'get',
            params
        });
    },
})