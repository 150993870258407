<template>
	<div class="top-account" :class="{ noWhite: noWhite }">
		<el-dropdown class="fr user-dropdown" @visible-change="toggleUser" trigger="click">
			<div class="flex user-account">
				<p class="txt" :class="tempColor ? 'block' : ''">
					{{ subUserInfo.name || '--' }}
				</p>
				<p :class="tempColor ? 'block' : ''">
					<i :class="[{ 'el-icon-arrow-down': !showUser }, { 'el-icon-arrow-up': showUser }]"></i>
				</p>
			</div>

			<el-dropdown-menu slot="dropdown" class="account-dropdown-menu">
				<el-dropdown-item>
					<ul @click="setRealName">
						<li>
							{{ subUserInfo.name || '--' }}
                            <!-- 国际化不展示实名认证 -->
							<span v-if="!isI18nFlag" :class="{ isRealName: true, RealNamed: subUserInfo.idAuthStatus, Unnamed: !subUserInfo.idAuthStatus }">
								<svg-icon icon-class="yishimingicon" v-if="subUserInfo.idAuthStatus"></svg-icon>
								{{ subUserInfo.idAuthStatus ? '已实名' : '未实名' }}
							</span>
						</li>
						<li>{{ subUserInfo.phone }}</li>
					</ul>
				</el-dropdown-item>
				<el-dropdown-item divided>
					<ul @click="showPersonalCenter">
						<li>
							<span>{{ $t('WDNPC_WEB_PERSONAL_CENTER', { text: '个人中心' }) }}</span>
						</li>
					</ul>
				</el-dropdown-item>
				<el-dropdown-item divided>
					<ul @click="showBranchList" v-if="branchList && branchList.length > 1">
						<li>{{ $t('WDNPC_WEB_SWITCH_INSTITUTION', { text: '切换机构' }) }}</li>
						<li>当前：{{ branchName || '--' }}</li>
					</ul>
					<ul v-else>
						<li>{{ $t('WDNPC_WEB_CURRENT_INSTITUTION', { text: '所在机构' }) }}</li>
						<li>{{ branchName || '--' }}</li>
					</ul>
				</el-dropdown-item>
				<el-dropdown-item divided>
					<ul>
						<li @click="signOut">
							{{ $t('WDNPC_WEB_MAIN_WEB_LOG_OUT', { text: '退出登录' }) }}
						</li>
					</ul>
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
		<img class="fr user-img" v-if="subUserInfo.headImage" :src="subUserInfo.headImage" alt="" />
		<img class="fr user-img" v-if="!subUserInfo.headImage" src="../../../../assets/images/common/userL.png" alt="" />

		<span class="my-message-icon fr" @click="handleMessage">
			<svg-icon icon-class="message" v-if="iconShow"></svg-icon>
			<span class="unread-circle" v-show="existUnreadStatus"></span>
		</span>
		<el-dropdown v-if="isI18nFlag" class="fr language-dropdown" @visible-change="toggleLanguage" trigger="click" @command="handleCommand">
			<p>{{internationalName}}<i :class="[{'el-icon-arrow-down': !showLanguage},{'el-icon-arrow-up': showLanguage}]"></i>
			</p>
			<el-dropdown-menu slot="dropdown" class="language-dropdown-menu">
				<el-dropdown-item divided :command="key" v-for="(value,key) in international"  :key="key">
					{{ value }}
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
		<el-dialog title="" :visible.sync="branchListShow" :modal="false" width="400px" center>
			<div class="account-dialog">
				<p class="branchTitle">{{ $t('WDNPC_WEB_SWITCH_INSTITUTION', { text: '切换机构' }) }}</p>
				<ul class="ct">
					<li
						v-for="item in branchList"
						:key="item.ucode"
						@click="primaryBranch(item.ucode, item.name)"
						:class="{ primaryUcode: primaryUcode === item.ucode, mt10: true }"
					>
						<span v-show="branchUcode === item.ucode" class="current">{{ $t('WDNPC_WEB_CURRENT', { text: '当前' }) }}</span
						>{{ item.name }}
					</li>
				</ul>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primaryUcode" @click="setBranch">{{ $t('WDNPC_WEB_CONFIRM', { text: '选好了' }) }}</el-button>
			</div>
		</el-dialog>
		<el-dialog title="" :visible.sync="isRealNameShow" :modal="false" width="464px" center>
			<div class="account-dialog">
				<p class="branchTitle">实名认证</p>
				<p class="qrcodetitle ct">请使用微信或手机浏览器扫描下方二维码，完成实名认证.</p>
				<div class="qrcode">
					<img src="../../../../assets/images/common/qrcodeXia.png" class="xia" alt="" />
					<img src="../../../../assets/images/common/qrcodeShang.png" class="shang" alt="" />
					<div id="qrcode"></div>
				</div>
				<p class="qrcodetitle ct">个人隐私信息安全保障中</p>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import Vue from 'vue'
import QRCode from 'qrcodejs2'
import getLanguageData from '../../../../locale/locale'

export default {
	props: {
		replacePage: {
			default: '/login',
			type: String,
		},
		tempColor: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isI18nFlag: false, //判断是否是国际化项目
			iconShow: true,
			domainUrl: null,
			showUser: false,
			showLanguage:false,
			newVal: null,
			branchList: [],
			branchListShow: false,
			primaryUcode: '',
			primaryName: '',
			branchName: '',
			branchUcode: '',
			isRealNameShow: false,
			QRCode: null,
			noWhite: false,
			existUnreadStatus: false,
			setUpLanguageShow: false,
			internationalName: 'English',
		}
	},
	watch: {
		'$store.state.unreadMessage'(val) {
			console.log()
			this.existUnreadStatus = val
		},
	},
	computed: {
		// 不同机构
		subUserInfo() {
			return this.$store.state.subUserInfo
		},
		international() {
			return {
				"zh-CN":"中文",
				"en-US":'English',
				"fr-FR":"Français"
			}
		},
	},
	created() {
        this.isI18nFlag = localStorage.getItem('is_i18n') === 'true'
		this.iconShow = window.location.hostname.includes('sdsj') ? false : true
		if (localStorage.getItem('token')) {
			window.addEventListener('setItem', this.watchStorage)
			this.getFindUserBranchList()
		}
		if (this.tempColor) {
			//导航栏主题是白色时
			this.noWhite = true
		}
		if(localStorage.getItem('current_language')){
			let innertational  = Object.keys(this.international).filter(item => item == localStorage.getItem('current_language'))
			if(innertational && innertational.length > 0){
				this.internationalName = this.international[innertational[0]]
			}
		}
		if (localStorage.getItem("is_i18n") == "true") {
			this.isI18nFlag = true;
		} else {
			this.isI18nFlag = false;
		}
	},
	destroyed() {
		window.removeEventListener('setItem', this.watchStorage)
	},
	mounted() {
		if (localStorage.getItem('token')) {
			this.existUnread()
		}
	},
	methods: {
		async handleCommand(key,val){
			console.log(val);
			localStorage.setItem('current_language',key)
            this.internationalName = this.international[key];
            document.getElementsByTagName('html')[0].setAttribute('lang', key)
			await getLanguageData()
					this.$i18n.locale = key
			this.$store.commit('setLanguage',key)
					this.setUpLanguageTimeout = setTimeout(() => {
						this.setUpLanguageShow = false
					}, 100)
		},
		// 获取是否有未读消息
		existUnread() {
			this.$api.message.existUnread().then((res) => {
				if (res.data) {
					this.existUnreadStatus = true
				} else {
					this.existUnreadStatus = false
				}
			})
		},
		// 我的消息
		handleMessage() {
			sessionStorage.setItem('userCenterCurrentTab', '我的消息')
			this.$router.push({
				path: '/personalCenter_new',
			})
		},
		showPersonalCenter() {
			this.$router.push({
				path: '/personalCenter_new',
			})
		},
		toggleUser(show) {
			this.showUser = show
		},
		toggleLanguage(show) {
			this.showLanguage = show
		},
		setRealName() {
            if(this.isI18nFlag) return //国际化不展示实名认证

			if (this.subUserInfo.idAuthStatus) return
			this.isRealNameShow = !this.isRealNameShow
			this.$nextTick(() => {
				this.qrcode()
			})
		},
		async qrcode() {
			if (this.QRCode) return
			let domainArr = localStorage.getItem('domain')
			let domainUrl = domainArr && JSON.parse(domainArr).find((item) => item.frontend_template_id == 4).domain
			this.QRCode = new QRCode('qrcode', {
				width: 154,
				height: 154, // 高度
				text: `${window.location.protocol}//${domainUrl}/login?phone=${this.subUserInfo.phone}`, // 二维码内容
				display: 'none',
				// render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				// background: '#f0f',   // 背景色
				// foreground: '#ff0'    // 前景色
			})
		},
		async watchStorage() {
			this.newVal = sessionStorage.getItem('signOutStorage')
			if (this.newVal == 'signOut') {
				this.pathList()
			}
		},
		async signOut() {
			sessionStorage.setItem('expect', 'yes')
			this.newVal = sessionStorage.getItem('signOutStorage')
			if (this.newVal == 'login' && this.$route.path === '/course/testPaper') {
				Vue.prototype.resetSetItem('signOutStorage', 'bullet')
			} else {
				this.pathList()
			}
		},
		pathList() {
			this.$api.account_new.logout().then((res) => {
				if (res.success) {
                    const currentLanguage = localStorage.getItem('current_language')
                    const saasId = localStorage.getItem('saasId')
                    localStorage.clear()
                    sessionStorage.clear()
                    // 退出登录的时候存储一下当前语言
                    localStorage.setItem('current_language', currentLanguage)
                    localStorage.setItem('saasId', saasId)
					this.$router.push('/login_new')
				}
			})
		},
		async getFindUserBranchList() {
			this.branchList = JSON.parse(localStorage.getItem('branchList'))
			this.branchUcode = localStorage.getItem('branchUcode')
			this.branchName = localStorage.getItem('branchName')
		},
		showBranchList() {
			this.branchListShow = !this.branchListShow
			this.primaryUcode = ''
			this.primaryName = ''
		},
		primaryBranch(branchUcode, branchName) {
			this.primaryUcode = branchUcode
			this.primaryName = branchName
		},
		async setBranch() {
			sessionStorage.setItem('index', '/' + this.replacePage)
			if (!this.primaryUcode) {
				this.showBranchList()
				return
			}

            this.branchUcode = this.primaryUcode
			this.branchName = this.primaryName
			localStorage.setItem('branchUcode', this.primaryUcode)
            localStorage.setItem('branchName', this.primaryName)

			this.$api.account_new.switchBranch(this.primaryUcode).then((res) => {
				if (res.success) {
                    this.branchListShow = false
					// if (this.replacePage.indexOf('/') == -1) {
					// 	setTimeout(async () => {
					// 		location.replace('/' + this.replacePage)
					// 	}, 500)
					// } else {
					// 	setTimeout(async () => {
					// 		location.replace(this.replacePage)
					// 	}, 500)
					// }
				}
			})
		},
	},
}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/account.styl';
.language-dropdown
  margin-top:29px;
  color:#fff;
  margin-right:18px;
.language-dropdown-menu {
    /deep/.el-dropdown-menu__item--divided{
      margin-top:0!important;
    }
}
.unread-circle
  position: relative
  width:8px;
  height:8px;
  border-radius:50%;
  background:#D14242;
  display: block;
  margin-left: 16px;
  margin-top: -26px;
  z-index: 2
.my-message-icon
  margin-right:10px;
  margin-top:29px;
  .icon
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    font-size:22px;
    border-right 1px solid #fff
    padding-right 12px
.account-dropdown-menu {
  text-align: left;
  border-radius: 6px;
  margin-top: 22px !important;
  /deep/ .el-dropdown-menu__item--divided:before{
    margin 0px
  }
  .el-dropdown-menu__item {

    padding: 0px;
    &>ul{
      padding: 14px 20px;
    }
  }
}
.shjd-account .user-img{
  margin-top:11px
}
.shjd-account.white .user-dropdown .user-account{
  line-height 60px
}
.shjd-account .user-dropdown .user-account i{
  display flex;
  align-items center;
}
</style>
