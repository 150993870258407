<template>
<div>
  <el-row v-for="(item, index) in layout" :key="item.aid">
    <template v-for="(blockName, i) in item.block_name">
      <el-col :span="+templateConf[index].items[i].column_num || 24" :key="i">
        <template v-if="Array.isArray(blockName)">
          <el-row>
            <el-col
              v-for="(a, j) in blockName"
              :span="+templateConf[index].items[i].children[j].column_num || 24"
              :key="`${a}${j}`">
                <component
                  v-if="a"
                  :is="a"
                ></component>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <component
            v-if="blockName"
            :is="blockName"
          ></component>
        </template>
      </el-col>
    </template>
  </el-row>
</div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'blockDefault',
  props: {
    layout: {
      type: Array,
      default: () => ([])
    },
    templateConf: {
      type: Array,
      default: () => ([])
    },
    components: {
      type: Object,
      default: () => ({})
    }
  },
  created () {
    this.components.keys().forEach(key => {
      Vue.components(key, this.components[key])
    })
  }
}
</script>

<style>

</style>