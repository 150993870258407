
const routerArr = [
    {
        path: '/assessList',
        name: 'assessList',
        meta: { title:'人才智能' , login:true },
        component: () => import(/* webpackChunkName: 'evaluation' */ './page/assessList.vue'),
    },
    {
        path: '/assess',
        name: 'assess', 
        meta: { title:'测评页' , login:true },
        component: () => import(/* webpackChunkName: 'evaluation' */ './page/assess.vue'),
    },
    {
        path: '/evaluation',
        name: 'evaluation', 
        meta: { title:'测评作答详情' , login:true },
        component: () => import(/* webpackChunkName: 'evaluation' */ './page/evaluation.vue'),
    },
    {
        path: '/reportDetails',
        name: 'reportDetails', 
        meta: { title:'报告详情' , login:true },
        component: () => import(/* webpackChunkName: 'evaluation' */ './page/reportDetails.vue'),
    },
]
export default routerArr
