/**
 * @file
 * @author tanghao
 * @date 2019-06-19
 */

import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
import task from './module/task';
//引入
import dynamic from '@wd/lowcode-pc/plugin/store/index.js';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //
        theme:localStorage.getItem('theme'),
        pageConfigs: [],
        testPaper: '',
        userInfo:JSON.parse(localStorage.getItem('userInfo')) || {},
        subUserInfo:JSON.parse(localStorage.getItem('subUserInfo')) || {},
        areaList:[],
        unreadMessage:false,//是否有未读消息
        isNewSaas:false//用户中心改造标识
    },
    mutations: {
        setSaasFlag(state, val){
            state.isNewSaas = val
        },
        //主题
        setTheme(state, payload){
            state.theme = payload
        },
        setPageConfigs(state, payload) {
            state.pageConfigs = payload;
        },
        // 账号级别
        saveUserInfo(state, payload) {
            state.userInfo = payload;
        },
        updateUserInfo(state,payload){
            state.userInfo[payload.key] = payload.value;
        },
        // 机构级别
        saveSubUserInfo(state, payload) {
            state.subUserInfo = payload;
        },
        updateSubUserInfo(state,payload){
            state.subUserInfo[payload.key] = payload.value
        },
        // 城市列表
        saveCityTree(state,payload){
            state.areaList = payload
        },
        // 是否有未读消息
        unreadMessageState(state,payload){
            state.unreadMessage = payload;
        },
        logout(state){
            state.userInfo = {}
            // 和域名相关的一些字段需要保留
            const branchId = localStorage.getItem('branchId')
            const branchUcode = localStorage.getItem('branchUcode')
            const saasId = localStorage.getItem('saasId')
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem('branchId', branchId)
            localStorage.setItem('branchUcode', branchUcode)
            localStorage.setItem('saasId', saasId)
        }
    },
    actions: {
    },
    getters: {
        // getConfigByPage(state) {
        //     return (module, pageName) => state.pageConfigs.find(
        //         page => page.moduleId === module && page.name === pageName,
        //     );
        // },

        // storeGetPageConfByUrl: (state) => (name) => {
        //     console.log('state.pageConfigs-----', state.pageConfigs,name)
        //     let a = state.pageConfigs.find(
        //         page => page.uri === name
        //     );
        //     console.log('a-----', a)
        // },
        // getConfigByPage(state) {
        //     return (path) => state.pageConfigs.find(
        //         page => page.uri.includes(path)
        //     );
        // },
        // getConfigByPageNav(state) {
        //     return state.pageConfigs
        // }
    },
    modules: {
        app,
        pages,
        task,
        dynamic,
    },
});
