
const routerArr = [
    {
        path: '/jobIdx',
        name: 'jobIdx',
        meta: { title:'招聘' , login:true },
        component: () => import(/* webpackChunkName: 'job' */ './page/jobIdx.vue'),
    },
    {
        path: '/jobDetail',
        name: 'jobDetail',
        meta: { title:'招聘详情' , login:true },
        component: () => import(/* webpackChunkName: 'job' */ './page/jobDetail.vue'),
    },
    {
        path: '/morePositionList',
        name: 'morePositionList',
        meta: { title:'更多招聘' , login:true },
        component: () => import(/* webpackChunkName: 'job' */ './page/morePositionList.vue'),
    },

]
export default routerArr
