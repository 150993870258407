
export default API => ({
  // 订单页面获取订单详情
    getOrderByNo(id) {
        return API({
            url: `/order/v1/open/order/getOrderByNo?orderNo=${id}`,
        });
    },
    // 查询订单状态
    getOrderStatus(id) {
        return API({
            url: `/order/v1/open/order/getOrderStatus?orderNo=${id}`,
        });
    },
})