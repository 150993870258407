// 保存该模块下的路由信息
const routerArr = [
	{
		path: '/login_new',
		name: 'login',
		meta: { title: '登录', login: false },
		component: () => import(/* webpackChunkName: 'login' */ './login/login.vue'),
    },
    {
		path: '/register_new',
		name: 'register',
		meta: { title: '注册', login: false },
		component: () => import(/* webpackChunkName: 'register' */ './register/register.vue'),
    },
    {
		path: '/forget_new',
		name: 'forget',
		meta: { title: '忘记密码', login: false },
		component: () => import(/* webpackChunkName: 'forget' */ './forget/forget.vue'),
	},
	{
		path: '/userAgreement',
		name: 'userAgreement',
		meta: {
			title: '用户协议',
			login: false,
		},
		component: () => import(/* webpackChunkName: 'userAgreement' */ './userAgreement/userAgreement.vue'),
	},
	{
		path: '/privacyPolicy',
		name: 'privacyPolicy',
		meta: {
			title: '法律声明及隐私政策',
			login: false,
		},
		component: () => import(/* webpackChunkName: 'privacyPolicy' */ './privacyPolicy/privacyPolicy.vue'),
    },
    {
        path: '/personalCenter_new',
        name: 'personalCenter',
        meta: { 
            title:'个人中心',
            login: false,
        },
        component: () => import(/* webpackChunkName: 'personalCenter' */ './personalCenter/personalCenter.vue'),
    },
]
export default routerArr
