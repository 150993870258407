const urlName = '/usercenter/v2/t-c/user'
export default (API) => ({
	getUserInfo() {
		return API({
			url: `${urlName}/getUserInfo`,
		})
    },
    updateBySelf(data) {
		return API({
            url: `${urlName}/updateBySelf`,
            method:'post',
            data
		})
	},
})
