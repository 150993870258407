export default {
    STATUS_CODE: {
        SUCCESS: 200,
        NO_LOGIN: 602,
        UNDEFINED: undefined,
    },
    navigation: {
        crowd: 'C', // 使用人群，分 B、C
        textAlign: 'left'
    },
    LOGIN_PAGE: ['login', 'register', 'forget'],
};
