const zhLogin = {
    'FORM_WEB_SINGLE_CHOICE':"单选",
    'FORM_WEB_MULTICHOICE':"多选",
    'FORM_WEB_PLEASE_SELECT':"请选择",
    'FORM_WEB_PLEASE_ENTER':"请输入",
    'FORM_WEB_PLEASE_LOGIN':"请登录",
    'FORM_WEB_CONFIRM':"确认",
    'FORM_WEB_SUBMITTED_SUCCESS':"表单已成功提交",
    'FORM_WEB_COLLECTION_OF_THIS_FORM_HAS_END':"该表单收集时间已结束，感谢您的关注",
    'FORM_WEB_NEED_TO_FILL_OUT_THE_FORM_AGAIN':"需要再次填写表单？",
    'FORM_WEB_VIEW_RECENT_ANSWER':"查看最近答卷",
    'FORM_WEB_FILL_IN_AGAIN':"再次填写",
    'FORM_WEB_PLEASE_SELECT_ADDRESS':"请选择地址",
    'FORM_WEB_SUBMIT':"提交",
    'FORM_WEB_CLOSE':"关闭",
    'FORM_WEB_SORRY_ONCE':"抱歉，每个用户仅限填一次",
    'FORM_WEB_VIEW_ANSWER_SHEET':"查看答卷",
    'FORM_WEB_GOT_IT':"知道了",
    'FORM_WEB_REQUIRED_ITEM_CONNOT_BE_BLANK':"必选项不能为空",
}

const enLogin = {
    'FORM_WEB_SINGLE_CHOICE':"Single Choice",
    'FORM_WEB_MULTICHOICE':"Multi-Choice",
    'FORM_WEB_PLEASE_SELECT':"Please Select",
    'FORM_WEB_PLEASE_ENTER':"Please Enter",
    'FORM_WEB_PLEASE_LOGIN':"Please Login",
    'FORM_WEB_CONFIRM':"Confirm",
    'FORM_WEB_SUBMITTED_SUCCESS':"The form was submitted successfully",
    'FORM_WEB_COLLECTION_OF_THIS_FORM_HAS_END':"The collection time of this form has ended. Thank you for your attention",
    'FORM_WEB_NEED_TO_FILL_OUT_THE_FORM_AGAIN':"Need to fill out the form again?",
    'FORM_WEB_VIEW_RECENT_ANSWER':"View recent answers",
    'FORM_WEB_FILL_IN_AGAIN':"Fill in again",
    'FORM_WEB_PLEASE_SELECT_ADDRESS':"Please select an address",
    'FORM_WEB_SUBMIT':"Submit",
    'FORM_WEB_CLOSE':"Close",
    'FORM_WEB_SORRY_ONCE':"Sorry, each user can only fill in once",
    'FORM_WEB_VIEW_ANSWER_SHEET':"View answer sheet",
    'FORM_WEB_GOT_IT':"got it",
    'FORM_WEB_REQUIRED_ITEM_CONNOT_BE_BLANK':"Required item cannot be blank",
}
const frLogin = {
    'FORM_WEB_SINGLE_CHOICE':"Choix unique",
    'FORM_WEB_MULTICHOICE':"Choix multiples",
    'FORM_WEB_PLEASE_SELECT':"Veuillez sélectionner",
    'FORM_WEB_PLEASE_ENTER':"Veuillez entrer",
    'FORM_WEB_PLEASE_LOGIN':"Veuillez vous connecter",
    'FORM_WEB_CONFIRM':"Confirmation",
    'FORM_WEB_SUBMITTED_SUCCESS':"Formulaire soumis avec succès",
    'FORM_WEB_COLLECTION_OF_THIS_FORM_HAS_END':"Le temps de collecte de ce formulaire est terminé. Merci de votre attention",
    'FORM_WEB_NEED_TO_FILL_OUT_THE_FORM_AGAIN':"Dois - je remplir à nouveau le formulaire?",
    'FORM_WEB_VIEW_RECENT_ANSWER':"Voir les réponses récentes",
    'FORM_WEB_FILL_IN_AGAIN':"Remplir à nouveau",
    'FORM_WEB_PLEASE_SELECT_ADDRESS':"Veuillez sélectionner une adresse",
    'FORM_WEB_SUBMIT':"Présentation",
    'FORM_WEB_CLOSE':"Fermer",
    'FORM_WEB_SORRY_ONCE':"Désolé, il ne peut être rempli qu'une seule fois par utilisateur",
    'FORM_WEB_VIEW_ANSWER_SHEET':"Voir le tableau des réponses",
    'FORM_WEB_GOT_IT':"J'ai compris",
    'FORM_WEB_REQUIRED_ITEM_CONNOT_BE_BLANK':"L'élément requis ne peut pas être vide",
}
export default {
    zhLogin,
    enLogin,
    frLogin
}