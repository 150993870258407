
const routerArr = [
    {
        path: '/salesSystem/courseGoods',
        name: 'salesCourseGoods',
        meta: { title:'商品列表' , login:false },
        component: () => import(/* webpackChunkName: 'salesSystem' */ './page/courseGoods.vue'),
    },
]
export default routerArr
