export const goLiveHome = function (vm, item, contentId,language,timezone) {
    // 判断当前是直播或者直播间 且直播正常结束 不允许观看回放 情况下 提示直播结束并阻断页面跳转
    if((item.type === 4 || item.type === 11) && !item.isLiveReplay && (item.status === 2 || item.liveStatus === 2) && !item.isLocked){
        vm.$message.error('直播已结束')
        return
    }
    vm.$api.live.saveHeaderInfo({
        backUrl: window.location.href,
        roomId: contentId,
        token: localStorage.getItem("token"),
        organ: localStorage.getItem("organ"),
        actor: localStorage.getItem("actor"),
        branch: localStorage.getItem('branch'),
        channel: localStorage.getItem('channel'),
        subUser: localStorage.getItem('subUserInfo')
    }).then(unlimite => {
        liveHomeLink(unlimite,language,timezone)
        // window.location.href = `https://live-h5.testing3.svc.k8s.bjo.wdcloud.cc:1025/liveHome?unlimitedId=${unlimite.data.unlimited_id}`
    })
}

function liveHomeLink(unlimite,language,timezone) {
    let hostname = window.location.hostname
    let port = window.location.port;
    let isIP=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
    let API  = ''
    let locationHref = window.location.href
    if (locationHref.includes('testing')) {
        API = 'https://pc-live.testing3.wdeduc.com'
    } else if (locationHref.includes('fat')) {
        API = 'https://pc-live.fat.wdeduc.com'
    } else if (locationHref.includes('uat')) {
        API = 'https://pc-live.uat.wdeduc.com'
    } else  {
        //to do
        API = 'https://pc-live.wdeduc.com'

    }
    if(isIP.test(hostname) || hostname.includes('dev') || !!port){
        API = 'https://pc-live.testing3.wdeduc.com:1025';
    }
    window.open(API + `/liveHome?&liveCode=${unlimite.data.unlimited_id}&current_language=${language}&timezone=${timezone}`)
    // window.open(   API + `/liveHome?&liveCode=${res.data.unlimited_id}`)
}

