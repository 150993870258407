
// 考试类型
const useType = {
    // cls：类名
    1: {
        text:'模拟考试',//{text:'模拟考试'}),
        tag:'warning',
        cls:'tip1',
        icon:'exam_mock'
    },
    2:{
        text:'正式考试',//{text:'正式考试'}),
        tag:'',
        cls:'tip2',
        icon:'exam_formal'
    }
}
export default {
    useType,
}