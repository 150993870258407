<template>
    <div class="header-box">
        <div class="nav">
            <div class="container">
                <div class="left" @click="goHome">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc/shuidian/head-logo.png" alt="">
                </div>
                <div class="menu">
                    <ul>
                        <li class="item" v-for="nav in NAVList" :key="nav.id" :class="{active : nav.url === activeNav}" @click="tabClick(nav)">
                            <p class="title">{{nav.name}}</p>
                            <!-- <p class="line" v-show="nav.url === '/'+activeNav.split('/')[1]"></p> -->
                        </li>
                    </ul>
                </div>
                <div class="right" v-if="!token">
                  <el-button class="button" @click="jumpLogin('shuidian_homepage','login')" round>登录</el-button>
                  <el-button class="button" @click="jumpLogin('shuidian_homepage','register')" round>注册</el-button>
                </div>
                <template v-else>
                  <account class="account" :replacePage="'shuidian_homepage'"/>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import account from './account.vue';
import config from '@/config/url'
export default {
    data(){
        return{
            token:'',
            NAVList:[],
            activeNav:this.$route.path,
        }
    },
    components:{
      account
    },
    watch: {
        $route() {
          setTimeout(() => { //路由跳转
            if(this.$route.path.includes('learning')){
                this.activeNav = '/learning'
            }else if(this.$route.path.includes('exam')){
                this.activeNav = '/examList'
            }else{
                this.activeNav = this.$route.path
            }   
              this.token = localStorage.getItem('token')
          }, 100)
        }
    },
    async mounted(){
        this.getNav()
        this.token = localStorage.getItem('token')
    },
    methods:{
        goHome(){
            this.$router.push({
                path:'/shuidian_homepage'
            })
        },
        getNav(){
            this.$api.configure.configurationNew(config.confUrl).then(res => {
                if(res.data.navagation){
                    this.NAVList = res.data.navagation.mainMenu || [];
                }
            })
        },
        // 导航切换
        tabClick(nav){
            if(nav.url.includes('learning')){
                this.activeNav = '/learning'
            }else if(this.$route.path.includes('exam')){
                this.activeNav = '/examList'
            }else {
                this.activeNav = nav.url
            }
            console.log(this.activeNav,'this.activeNav')
            if (this.$route.url === '/course/testPaper') {
                return;
            } else if(nav.url === this.$route.path) {
                return
            }
            this.$router.push(nav.url)
        },
        jumpLogin(name,type) {
          this.$router.push({
            name: type,
            query: {
                name
            }
          })
        }
    }
}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/shuidianNav.styl'
</style>