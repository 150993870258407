const urlName = '/order/v1'
export default API => ({
    //查询所有可用商品分类
    findAll({params,data}) {
        return API({
            url: `${urlName}/open/goodsCategory/findAll` ,
            method:  'get',
            params,
            data
        });
    },
    //查询商品
    findByCondition(data) {
        console.log(data,'data--')
        let goodsName = decodeURIComponent(data.name)
        return API({
            url: `${urlName}/open/goodsLearner/findByCondition?pageSize=${data.pageSize}&pageNum=${data.pageNum}&categoryCodes=${data.categoryCodes}&type=${data.type}&goodsIds=${data.goodsIds}&name=${goodsName}` ,
            method:  'get',
            data
        });
    },
    findByConditionList(params, data) {
        let goodsName = decodeURIComponent(data.name) 
        data.name = goodsName
        return API({
            url: `${urlName}/open/goodsLearner/findGoodsList` ,
            method:  'POST',
            params,
            data
        });
    }, 

})

