let RouterArr = []
const Routers = require.context('./../modules/', true, /\.router\.js$/)
Routers.keys().forEach(key => {
  RouterArr = [
    ...RouterArr,
    ...Routers(key).default
  ]
})

RouterArr.forEach(item => {
    item.meta.highlight = item.path
})

export default RouterArr
