
const routerArr = [
    {
        path: '/studentSee',
        name: 'studentSee',
        meta: { title:'直播间' , login:true },
        component: () => import(/* webpackChunkName: 'live' */ './page/studentSee.vue'),
    },
    {
        path: '/liveList',
        name: 'liveList',
        meta: { title:'直播列表' , login:true },
        component: () => import(/* webpackChunkName: 'live' */ './page/liveList.vue'),
    },
    {
        path: '/livePlayback',
        name: 'livePlayback',
        meta: { title:'直播回放' , login:true },
        component: () => import(/* webpackChunkName: 'live' */ './page/livePlayback.vue'),
    },

    {
      path: '/bjyCustomStudent',
      name: 'bjyCustomStudent',
      meta: { title:'直播间' , login:true },
      component: () => import(/* webpackChunkName: 'live' */ './page/bjyCustomStudent.vue'),
    },
     {
        path: '/bjyLivePlayBack',
        name: 'bjyLivePlayBack',
        meta: { title:'直播回放' , login:true },
        component: () => import(/* webpackChunkName: 'live' */ './page/bjyLivePlayBack'),

    }

]
export default routerArr
