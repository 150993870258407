<template>
  <el-dialog class="course-dialog" :visible.sync="show" :close-on-click-modal="false" :show-close="false">
  <div class="playboxscore">
      <div class="playboxscore-content">
        
        <div class="course-title">
          <img class="course-title-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/course-title-span.png"/>
          <span :class="frFont ? 'course-title-span course-title-font' : 'course-title-span'">{{$t('WDNPC_WEB_COURSE_RATING',{text:'课程评分'})}}</span>
        </div>
        <div class="course-pfwjx2">
          <p v-for="numbercount in count" :key="numbercount">
            <span @click="clickbright(numbercount)">
              <svg-icon icon-class="score-star" class-name="score-star-icon-huang "></svg-icon>
            </span>
          </p>
          <p v-for="numbercount in 5-count" :key="numbercount+count">
            <span @click="clickbright(numbercount+count)">
              <svg-icon icon-class="score-star" class-name="score-star-icon-gray"></svg-icon>
            </span>
          </p>
        </div>
        <div class="course-tip" v-if="issubmit">
          {{$t('WDNPC_WEB_CLICK_XINGX_TO_COMMENT',{text:'点击星星可以进行评价哦'})}}
        </div>
        <div v-if="issubmit" class="course-footer course-footer1">
          <el-button @click="clickclose">{{$t('WDNPC_WEB_CANCEL',{text:'取消'})}}</el-button>
          <el-button type="primary" :class="count==0?'course-active':''" @click="clickSubmit">{{$t('WDNPC_WEB_SUBMIT',{text:'提交'})}}</el-button>
        </div>
        <div v-else class="course-footer course-footer2">
          <el-button type="primary" @click="clickclose">{{$t('WDNPC_WEB_CLOSE',{text:'关闭'})}}</el-button>
        </div>
      </div>
    </div>
</el-dialog>

  
</template>
<script>

export default {
  name: "playboxscore",
  props: {
    myScore:{type:Number,default:0},
    issubmit:{type:Boolean,default:false},

  },

  data () {
    return{
      show:true,
      count:this.myScore,
      frFont:false,//是否是法语
    }
  },
  created () {
    if(localStorage.getItem('current_language') && localStorage.getItem('current_language') == 'fr-FR'){
      this.frFont = true
    }else{
      this.frFont = false
    }
  },
  async mounted () {
   
  },
 
  methods:{
    clickbright(clickbright){
      if(this.issubmit){
        this.count=clickbright;
      }
    },
    getParent (parent, fn,item) {
        if (parent[fn]) {
            parent[fn](item)
        } else {
            this.getParent(parent.$parent, fn,item)
        }
    },
    clickclose(){
      this.getParent(this.$parent, 'clickclose');
    },
    clickSubmit(){
      if(this.count>0){
        this.getParent(this.$parent, 'evaluateCourse',this.count);
      }
      
      
    },
  },
};
</script>
<style lang="stylus">
  .course-dialog .el-dialog
    background: none !important;
    box-shadow: none !important;
  .playboxscore
    
    &-content
      width 400px
      background #FFFFFF
      box-shadow 0px 0px 20px 0px rgba(0, 0, 0, 0.1)
      border-radius 10px
      margin 0 auto
      text-align center
      margin-top 80px
      padding-bottom 30px
      
      .course-title
        height 80px
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .course-title-img
          width 295px
          height 231px
          position: absolute;
          left: 53px;
          top: -151px;
        // .course-close
        //   width 64px
        //   position: absolute;
        //   right: 24px;
        //   top: 14px;
        .course-title-span
          position:absolute;
          color: #fff;
          font-size:26px;
        .course-title-font
          font-size:15px;
      .course-tip
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-top:16px;
        padding-bottom:5px;
      .course-pfwjx2
        // padding-bottom 16px
        margin-top: 40px
        .score-star-icon-huang{
            font-size:40px;
            color:#FFD118;
        }
        .score-star-icon-gray{
           font-size:40px;
           color:#D8D8D8;
        }
        p
          display inline-block
          margin-right 8px
          &:last-child
            margin-right 0px
          img
            width 48px
            cursor pointer
      .course-footer
        margin-top: 21px;
      .course-footer1
        .el-button--primary:active
          outline: 0;
        .el-button
          width: 120px;
          height: 40px;
          line-height:38px;
          font-size: 16px;
          font-weight: 400;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          padding:0px;
        .el-button--default
          background: #FFFFFF;
          border: 1px solid #D7DCEE;
          color: #666666;
          box-sizing:border-box;
        .el-button--primary
          background: rgba(49, 111, 255, 1);
          color: #FFFFFF;
          border:none;
          opacity:1;
          margin-left:30px;
        .course-active.el-button--primary
          background: rgba(49, 111, 255, 0.3);
      .course-footer2
        .el-button
          width: 240px;
          height: 40px;
          background: #316FFF;
          margin:0 auto;
          border:none;



</style>
