
const routerArr = [
    {
        path: '/shuidian_homepage',
        name: 'shuidian_homepage',
        meta: { title:'水电首页' , login:false },
        component: () => import(/* webpackChunkName: 'education' */ './page/index.vue'),
    },
]
export default routerArr
