export default {
    state: {
        config: {},
        language:"zh_CN",
    },

    getters: {
        // examListConfig(state) {
        //     return state.config.blocks.find(
        //         block => block.name === 'exam_list',
        //     );
        // },

        // examDetailConfig(state) {
        //     return state.config.blocks.find(
        //         block => block.name === 'exam_detail',
        //     );
        // },
    },

    mutations: {
        setConfig(state, payload) {
            state.config = payload;
        },
        setLanguage(state, payload) {
            console.log('触发了');
            state.language = payload;
        },
    },

    actions: {
        setConfig({ commit, config }) {
            commit('setConfig', config);
        },
    },
};
