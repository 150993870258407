const routerArr = [
    // 客户端下载页面
    {
        path: '/download/pc-client',
        name: 'DownloadClient',
        meta: { title:'客户端下载' , login:false },
        component: () => import(/* webpackChunkName: 'DownloadClient' */ './page/download.vue'),
    },
    {
        path: '/settled',
        name: 'Settled',
        meta: { title:'机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'settled' */ './page/settled.vue'),
    },
    {
        path: '/yj_settled',
        name: 'yj_settled',
        meta: { title:'应急机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'yj_settled' */ './page/yj_settled.vue'),
    },
    // 应急---工贸培训
    {
        path: '/yj_gm_settled',
        name: 'yj_gm_settled',
        meta: { title:'应急机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'yj_gm_settled' */ './page/yj_gm_settled.vue'),
    },
    // 应急---工贸培训
    {
        path: '/yj_slfh_settled',
        name: 'yj_slfh_settled',
        meta: { title:'应急机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'yj_slfh_settled' */ './page/yj_slfh_settled.vue'),
    },


    // 应急---合肥试用
    {
        path: '/yj_hf_settled',
        name: 'yj_hf_settled',
        meta: { title:'应急机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'yj_slfh_settled' */ './page/yj_hf_settled.vue'),
    },


    // 应急---安庆试用
    {
        path: '/yj_aq_settled',
        name: 'yj_aq_settled',
        meta: { title:'应急机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'yj_aq_settled' */ './page/yi_aq_settled.vue'),
    },


    // 应急---非煤培训
    {

        path: '/yj_fm_settled',
        name: 'yj_fm_settled',
        meta: { title:'应急机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'yj_gm_settled' */ './page/yj_fm_settled'),
    },

    // 全员定向大培训 - 项目选择页面（定制）
    {
        path: '/yj_training_options',
        name: 'yj_training_options',
        meta: {
            title: '全员定向大培训',
            login: false,
        },
        component: () => import( /* webpackChunkName: 'yj_training_options' */ './page/trainingOptions/trainingOptions'),
    },

  // 应急---元氏县
  {

    path: '/yi_ysx_settled',
    name: 'yi_ysx_settled',
    meta: { title:'应急机构入驻' , login:false },
    component: () => import(/* webpackChunkName: 'yi_ysx_settled' */ './page/yi_ysx_settled'),
},

    





]
export default routerArr
