const urlName = '/api/course/student'
const urlName1 = '/api/resource/student'

export default API => ({
    // 课程列表  url参数 pageNum 页数 pageSize 每页条数
    getFindCourseList({ data, params }) {
        return API({
            url: `${urlName}/findCourseList` ,
            params,
            data
        });
    },
    // 热门课程 url参数 count 获取的条数
    getFindHotCourse({ data, params }) {
        return API({
            url: `${urlName}/findHotCourse`,
            params,
            data
        });
    },
    // 推荐课程 url参数 count 获取的条数
    getFindRecommendCourse({ data, params }) {
        return API({
            url: `${urlName}/findRecommendCourse` ,
            params,
            data
        });
    },
    // 课程详情 url参数 id 课程id
    getFindCourseById({ data, params }) {
        return API({
            url: `${urlName}/findCourseById` ,
            params,
            data
        });
    },
    // 课程章节 url参数 courseId 课程id
    getFindCourseItemInfo({ data, params }) {
        return API({
            url: `${urlName}/findCourseItemInfo` ,
            params,
            data
        });
    },
    // 视频资源 url参数 resourceId 视频资源id
    getVideoUrlByResourceId({ data, params }) {
        return API({
            url: `${urlName1}/getVideoUrlByResourceId` ,
            params,
            data
        });
    }
})
