
const routerArr = [
    {
        path: '/course',
        name: 'course',
        meta: { title:'训练营' , login:true },
        component: () => import(/* webpackChunkName: 'train' */ './page/training.vue'),
    },
    {
        path: '/course/catalog',
        name: 'coursecatalog', 
        meta: { title:'训练营详情' , login:true },
        component: () => import(/* webpackChunkName: 'train' */ './page/courseDetails.vue'),
    },
    {
        path: '/training_detail',
        name: 'trainingDetail', 
        meta: { title:'在线实训' , login:true },
        component: () => import(/* webpackChunkName: 'train' */ './page/trainingDetail.vue'),
    },
]
export default routerArr
