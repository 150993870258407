export default callAPI => ({
    
    // 呼叫考官查询状态
    findByStatus(params){
      return callAPI({
        url: `/monitor/v1/examCall/findByStatus`,
        method:'get',
        params
      });
    },
})
