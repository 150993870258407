export default API => ({
    // 评论
    stuSave(data) {
        return API({
            url: "/order/v1/evaluation/stu/save" ,
            method:'post',
            data,
        });
    },
    findByOrderNo(params) {
        return API({
          url: '/order/v1/evaluation/stu/findByOrderNo',
          mathod: 'get',
          params
        })
    },

})
