const urlName = '/assess/v1'
export default API => ({
    homeDisplayList () {
        return API({ url: `${urlName}/open/creditCompanyJz/homeDisplayList`, method: 'post'});
    },
    findList ({ data, params }) {
        return API({ url: `${urlName}/open/creditCompanyJz/findList`, method: 'post',data, params});
    },
    findByNameAndIdCard ({ data, params }) {
        return API({ url: `${urlName}/open/creditPersonJz/findByNameAndIdCard`, method: 'post',data, params});
    },
    getRegionList({ data, params }) {
        return API({url: '/gx-homemaking/v1/region/getRegionList',params,data});
    },
    trainingBasesave ({ data, params }) {
        return API({ url: '/gx-homemaking/v1/trainingBase/save', method: 'post',data, params});
    },
})
