
// 试看
export default API => ({
    //  试看播放免登陆
    getPlayAuthToken (data) {
        return API({ url: `/course/v1/open/courseItem/getPlayAuthToken?resId=${data.detailId}`, method:'get' })
    },
    // 老接口 可能需要替换   图片用老接口
    getImageOrDocUrl (data) {
        return API({ url: `/course/v1/open/courseItem/getPicUrl?resId=${data.detailId}`, method:'get' })
    },
    getDocumentAccessToken (data) {
        return API({ url: `/course/v1/open/courseItem/getDocumentAccessToken?resId=${data.detailId}`, method:'get' })
    },
})