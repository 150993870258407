<template>
    <div class="zuoye">
        <ul>
            <li v-for="(ele) in itemList" :key="ele.id" >
                <div class="sourse" @click="toDetail(index,ele)">
                    <p>
                        <img src="./../assets/images/learning/ic-zydl@2x.png"  class="icon" alt="">
                        <span>{{ele.name}}</span>
                    </p>
                    <p>
                        <span class="nosubmitTxt" v-if="ele.status==0">未提交</span>
                        <span class="submited" v-else-if="ele.status==1">已提交</span>
                        <span class="remarked" v-else>已点评</span>
                    </p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'zuoyeItem',
    props: {
        itemList: {
            type: Array,
            default: () => []
        },
        courseId:{
            type:String,
            default:''
        },
        taskId:{
            type:String,
            default:''
        }
    },
     data () {
        return {
        }
     },
     created() {
     },
     methods:{
        //  status:0未提交、1已提交未点评、2已提交已点评通过、3已提交已点评未通过
         toDetail(e,item){
            this.$router.push({
                path:'/learning/assignment',
                query:{
                    courseId:this.courseId,
                    homeworkId:item.id,
                    taskId:this.taskId,
                }
            })
         }
     }
}
</script>
<style lang="stylus" scoped>
.nosubmitTxt
    color:#FD220F
.submited,.remarked
    color:#43BC60
.zuoye
    ul
        margin-top:10px;
        li
            border:1px solid #D7DCEE;
            background: #F7FAFF;
            padding-left:12px;
            padding-right:12px;
            margin-top:16px;
.sourse
    background: #F7FAFF;
    box-sizing: border-box
    cursor pointer
    position relative;
    display :flex;
    align-items center;
    height:60px;
    justify-content space-between
    p:nth-child(2)
        span 
            font-size:14px;
    img     
        margin-right 8px
        align-self: center;
        width 14px
        position relative
        top 2px
    p:nth-child(1) span 
        font-size: 16px;
        color: #1B2257;
        font-weight:500;
        vertical-align: middle
        max-width: 640px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
</style>