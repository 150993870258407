
const routerArr = [
    {
        path: '/courseGoods',
        name: 'courseGoods',
        meta: { title:'课程' , login:false },
        component: () => import(/* webpackChunkName: 'coursegoods' */ './page/courseGoods.vue'),
    },
    // {
    //     path: '/moreCourseGoods',
    //     name: 'moreCourseGoods',
    //     meta: { title:'课程' , login:false },
    //     component: () => import(/* webpackChunkName: 'coursegoods' */ './page/moreCourseGoods.vue'),
    // },
    {
        path: '/courseGoodsDetail',
        name: 'courseGoodsDetail',
        meta: { title:'商品详情' , login:false },
        component: () => import(/* webpackChunkName: 'coursegoods' */ './page/courseGoodsDetail.vue'),
    },
    {
        path: '/orderPage',
        name: 'orderPage',
        meta: { title:'确认订单' , login:true },
        component: () => import(/* webpackChunkName: 'coursegoods' */ './page/orderPage.vue'),
    },


]
export default routerArr
