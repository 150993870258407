<template>
    <div class="training-camp-list">
        <div class="tab">
            <span v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" @click="tabClick(tab)">
                {{tab.value}}
            </span>
        </div>
        <div class="content">
            <div class="list" v-for="item in trainList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.iconUrl" alt="">
                    <img v-show="item.isNewTrain" class="icon" src="../assets/images/learning/new-icon.png" alt="">
                </div>
                <div class="middle item">
                    <div class="title">
                        <h1>{{item.name}}</h1>
                    </div>
                    <p class="introduce" @click="info=true;currentTrain = item">训练营简介</p>
                    <p class="bottom-container">
                        <span class="shichang">学习时长: {{formateTime(item.learnTotalTime)}} 小时</span>
                        <span class="studyProcess" :style="validity === '' || validity === 'true' ? 'border-right: 1px solid #ccc;' :'border-right:0'">已完成: {{item.studyProcess}}%</span>
                        <span class="time" v-show="validity === '' || validity === 'true'">有效期至：{{item.trainEndTime.split(' ')[0].replace(/-/g,'.')}}</span>
                    </p>
                </div>
                <div class="right item">
                    <div class="button" @click="toCatalog(item)">{{$dateFormat.dateFormat() > item.trainEndTime  ? '查看':item.isNewTrain?'开始学习':'继续学习'}}</div>
                </div>
            </div>
            <article v-show="noData" class="no-data">
                <img class="notraining" src="../assets/images/common/nodata.png" alt />
                <p>您还没有学习内容</p>
            </article>
            <p class="loadingmore" v-if="loading">加载中...</p>
            <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
        </div>
        
        <dialogInfo :info="info" :currentCourse="currentTrain" :nameType='"训练营简介："' @infoShow="infoShow"></dialogInfo>
    </div>
</template>
<script>
import $ from 'jquery';
import dialogInfo from './../modules/train/page/dialogInfo.vue'
export default {
    name:'trainingCamp',
    components:{dialogInfo},
    props:{
        validity:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            info: false,
            loading: false,
			currentTrain: {},
            trainList:[],
            tabIndex:-1,
            noData:null,
            tabList:[
                {
                    id:-1,
                    value:'全部',
                },
                {
                    id:1,
                    value:'未开始',
                },
                {
                    id:2,
                    value:'进行中',
                },
                {
                    id:3,
                    value:'已结束',
                }
            ],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            trainStatus:null
        }
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    computed: {
        noMore () {
            return this.trainList.length === this.paging.total && this.paging.total !== 0
        },
    },
    methods:{
        formateTime(val) {
            if(val){
                if((val / 3600).toFixed(2)>0){
                    return (val / 3600).toFixed(2);
                }else{
                    return (val / 3600).toFixed(0);
                }
                
            }else{
                return val;
            }
        },
        // 跳转详情
        toCatalog(item) {
            if(item.status === 1){
                this.$message.info('训练营未开始')
            }else if(this.$dateFormat.dateFormat() > item.trainEndTime ){
                this.$message.warning('已到期，无法学习')
            }else {
                this.$router.push({
                    path: "/course/catalog",
                    query: {
                        taskId:item.id,
                        backMoudle:'trainingCamp',
                        dymode: this.$route.query.dymode,
                        taskType:1,// 训练营 1 任务2
                        validity:this.validity,
                        backPath:this.$route.path
                    }
                });
            }
            
        },
        infoShow(val) {
            this.info = val;
        },
        tabClick(item){
            this.tabIndex = item.id
            this.paging.params = {
                pageNum: 1,
                pageSize: 10
            }
            this.trainStatus = item.id === -1 ? null : item.id;
            this.getData();
        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        getData() {
            this.$api.training
                .trainList({
                    params: {
                        pageNum: this.paging.params.pageNum,
                        pageSize: this.paging.params.pageSize,
                        trainStatus: this.trainStatus
                    }
                })
                .then(({ data: { list, total } }) => {
                    if(list && list.length){
                        this.noData = false
                    }else {
                        this.noData = true;
                    }
                    if(this.paging.params.pageNum>1){
                        this.trainList = this.trainList.concat(list);
                    } else {
                        this.trainList = list;
                    }
                    
                    this.paging.total = total;
                });
                
        },
    }
}
</script>
<style lang="stylus" scoped>
@import './../assets/css/learningList.styl'
</style>