<template>
    <el-dialog
        title="考试设备须知、双机位监考考试现场及网络要求"
        :visible.sync="dialogVisible.show"
        width="1000px"
        :before-close="handleClose"
        top="5vh"
        class="dialogExamEquipment"
        :center="true"
    >
        <div class="box">
            <img width="100%" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-pc/liulanqi.png" alt="">
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible.show = false">知道了</el-button>
        </span>
        </el-dialog>
</template>
<script>
export default {
    name:'dialogExamEquipment',
    props:{
        dialogVisible:{
            typeof:Object,
            default() {
                return {
                    show:false
                };
            },
        }
    },
    methods:{
        handleClose(){
            this.dialogVisible.show = false;
        }
    }
}
</script>
<style lang="stylus" scoped>
.box{
    max-height: 600px
    overflow:scroll
}
.dialogExamEquipment{
    /deep/.el-dialog__title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
    }
    /deep/.dialog-footer{
        button{
            width: 240px;
            height: 40px;
            background: #316FFF;
            span{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}

</style>