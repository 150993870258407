<template>
<div class="top-account" :class="{'noWhite': noWhite}">
  <el-dropdown class="fr user-dropdown" @visible-change="toggleUser" trigger="click" >
      <div class="flex user-account">
        <p class="txt" :class="tempColor ? 'block': ''">
          {{ subUserInfo.name || '--' }}
        </p>
        <p :class="tempColor ? 'block': ''">
          <i :class="[{'el-icon-arrow-down': !showUser},{'el-icon-arrow-up': showUser}]"></i>
        </p>
      </div>
      <el-dropdown-menu slot="dropdown" class="account-dropdown-menu" >
        <el-dropdown-item>
          <ul @click="setRealName">
            <li>
              {{ subUserInfo.name || '--' }}
              <span
                v-if="typeof isRealName === 'boolean'"
                :class="{isRealName: true, RealNamed: isRealName, Unnamed: !isRealName}"
                >
                <svg-icon icon-class="yishimingicon" v-if="isRealName"></svg-icon>
                {{isRealName ? '已实名' : '未实名'}}
              </span>

            </li>
            <li>{{ getTel }}</li>
          </ul>
        </el-dropdown-item>
        <el-dropdown-item divided>
            <ul @click="showPersonalCenter">
              <li>
                <span>个人中心</span>
              </li>
            </ul>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <ul
            @click="showBranchList"
            v-if="showSwitchBranch"
          >
            <li>切换机构</li>
            <li>当前：{{ branchName || '--' }}</li>
          </ul>
          <ul v-else>
            <li>所在机构</li>
            <li>{{ branchName || '--' }}</li>
          </ul>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <ul>
            <li @click="signOut">
              退出登录
            </li>
          </ul>
        </el-dropdown-item>
      </el-dropdown-menu>
  </el-dropdown>
  <img
    class="fr user-img"
    v-if="subUserInfo.headImage"
    :src="subUserInfo.headImage"
    alt=""
  />
  <img class="fr user-img" v-if="!subUserInfo.headImage" src="../../../../assets/images/common/userL.png" alt="" />

  <span class="my-message-icon fr" @click="handleMessage">
    <svg-icon icon-class="message" v-if="iconShow"></svg-icon>
    <span class="unread-circle" v-show="existUnreadStatus"></span>
  </span>
  <el-dialog
    title=""
    :visible.sync="branchListShow"
    :modal="false"
    width="400px"
    center
  >
    <div class="account-dialog">
      <p class="branchTitle">切换机构</p>
      <ul class="ct">
        <li
          v-for="item in branchList"
          :key="item.id"
          @click="primaryBranch(item.id)"
          :class="{ primary: primary === item.id, mt10: true }"
        >
          <span v-show="branchId === item.id" class="current">当前</span
          >{{ item.name }}
        </li>
      </ul>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="setBranch">选好了</el-button>
    </div>
  </el-dialog>
  <el-dialog
    title=""
    :visible.sync="isRealNameShow"
    :modal="false"
    width="464px"
    center
  >
    <div class="account-dialog">
      <p class="branchTitle">实名认证</p>
      <p class="qrcodetitle ct">请使用微信或手机浏览器扫描下方二维码，完成实名认证.</p>
      <div class="qrcode">
        <img src="../../../../assets/images/common/qrcodeXia.png" class="xia" alt="">
        <img src="../../../../assets/images/common/qrcodeShang.png" class="shang" alt="">
        <div id="qrcode"></div>
      </div>
      <p class="qrcodetitle ct">个人隐私信息安全保障中</p>
    </div>
  </el-dialog>
</div>
</template>
<script>
import Vue from 'vue';
import MD5 from 'js-md5'
import QRCode  from "qrcodejs2"
export default {
  props: {
    replacePage: {
      default: '/login',
      type: String,
    },
    tempColor: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconShow:true,
      domainUrl: null,
      showUser: false,
      newVal: null,
      branchList: [],
      branchListShow: false,
      primary: '',
      branchName: '',
      branchId: '',
      isRealName: null,
      isRealNameShow: false,
      QRCode: null,
      noWhite: false,
      existUnreadStatus:false,
    }
  },
  watch:{
    '$store.state.unreadMessage'(val){
      console.log();
      this.existUnreadStatus = val;
    }
  },
  computed:{
    getTel(){
        const reg = /^(\d{3})\d{4}(\d{4})$/
        if (this.userInfo.phone) {
          return this.userInfo.phone.replace(reg, '$1****$2')
        } else {
          return '--'
        }
    },
    userInfo(){
      return this.$store.state.userInfo
    },
    // 不同机构
    subUserInfo(){
      return this.$store.state.subUserInfo
    },
    /* 行业标签
        meet_emergency应急
    */
    branchIndustry() {
        return this.$store.state.dynamic.pageSetData.branchIndustry
    },
    showSwitchBranch(){ // 是否显示切换机构
        const isShow = this.$store.state.dynamic.frontendConfig.find(item => item.key === 'is_show_switch_branch')
        const flag = isShow && isShow.value==='true'
        if(flag&&this.branchList && this.branchList.length > 1){
            return true
        }else return false
    }
  },
  created() {
      this.iconShow = window.location.hostname.includes('sdsj') ? false : true;
    if (localStorage.getItem('token')) {
        window.addEventListener('setItem', this.watchStorage)
        this.getFindUserBranchList()
        // this.getAccountUserInfo()
        this.postIsRealNameAuthentication()
    }
    if(this.tempColor){//导航栏主题是白色时
        this.noWhite = true
    }
  },
  destroyed(){
    window.removeEventListener('setItem', this.watchStorage);
  },
  mounted () {
    // this.getSubUserInfo()
    if(localStorage.getItem('token')){
      this.existUnread();
    }
  },
  methods: {
    // 获取是否有未读消息
     existUnread(){
          this.$api.message.existUnread().then(res=>{
              if(res.data){
                  this.existUnreadStatus = true;
              }else{
                  this.existUnreadStatus = false;
              }
          })
      },
    // 我的消息
    handleMessage(){
      sessionStorage.setItem('userCenterCurrentTab','我的消息')
       this.$router.push({
        path:'/personalCenter'
      })
    },
    showPersonalCenter(){
      sessionStorage.setItem('userCenterCurrentTab','我的资料');
      this.$router.push({
        path:'/personalCenter'
      })
    },
    toggleUser(show) {
      this.showUser = show;
    },
    setRealName () {
      if (this.isRealName) return
      this.isRealNameShow = !this.isRealNameShow
      this.$nextTick(() => {
        this.qrcode()
      })
    },
    async qrcode () {
      if (this.QRCode) return
      let domainArr = localStorage.getItem('domain')
      let domainUrl = domainArr && JSON.parse(domainArr).find(item => item.frontend_template_id == 4).domain
        let jumpRoute = 'login'
        // if(this.branchIndustry === 'meet_emergency'){// 应急定制
        //     jumpRoute = 'yj_training_options'
        // }
      this.QRCode = new QRCode('qrcode', {
        width: 154,
        height: 154,        // 高度
        text:  `${window.location.protocol}//${domainUrl}/${jumpRoute}?phone=${this.userInfo.phone}&realName=1`,   // 二维码内容
        display: 'none'
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      })
    },
    postIsRealNameAuthentication () {
      this.$api.monitor.postIsRealNameAuthentication({}).then(res => {
        const m = `${localStorage.getItem('token')}true`
        this.isRealName = res.data === MD5(m)

        localStorage.setItem('isRealName',this.isRealName)

      })
    },
    async watchStorage(){
      this.newVal=sessionStorage.getItem('signOutStorage')
      if(this.newVal=='signOut') {
        this.pathList()
      }
    },
    async signOut () {
      sessionStorage.setItem('expect', 'yes')
      this.newVal=sessionStorage.getItem('signOutStorage')
      if(this.newVal=='login' && this.$route.path==='/course/testPaper') {
        Vue.prototype.resetSetItem('signOutStorage', 'bullet')
      } else {
        this.pathList()
      }
    },
    async pathList() {
      const params = { token: localStorage.getItem('token') }
      await this.$api.usercenter.postAccounLogout({ params })
      const branchId = localStorage.getItem('branchId')
      const saasId = localStorage.getItem('saasId')
      sessionStorage.clear()
      localStorage.clear()
      localStorage.setItem('branchId', branchId)
      localStorage.setItem('saasId', saasId)
        // 应急首页、登录定制化配置
        // 行业标签 meet_emergency    应急
        // if(this.branchIndustry === 'meet_emergency'){
        //     this.$router.replace('/');
        // }else{
            this.$router.push('/login');
        // }
    },
    getAccountUserInfo () {
        this.$api.usercenter.getAccountUserInfo({}).then(res => {
          this.userInfo = res.data
        })
    },
    async getFindUserBranchList () {
       //todo
       if (!localStorage.getItem('branchList')) {
         await this.$api.usercenter.getFindUserBranchList({}).then(res => {
          if (res.data && res.data.length) {
            const branchList = res.data.filter(item => Object.prototype.toString.call(item) === '[object Object]').map(item => item).map(item => {
              return {
                name: item.name,
                id: item.branch,
                actor: item.actor,
                organ: item.organ,
                userId: item.userId,
                code: item.code
              }
            })
            this.branchList = branchList;
            if (branchList.length) {
              localStorage.setItem('branchList', JSON.stringify(branchList))
              localStorage.setItem('organ', branchList[0].organ)
              localStorage.setItem('actor', branchList[0].actor)
              localStorage.setItem('branch', branchList[0].id)
              localStorage.setItem('userId', branchList[0].userId)
              localStorage.setItem('code', branchList[0].code)
              this.$sensors.login(branchList[0].code)
              this.branchName = branchList[0].name;
              this.branchId = branchList[0].id;
            }
          }
          this.$emit('getBranchInfo', this.branchName);
        });
       }  else {
          this.branchList = JSON.parse(localStorage.getItem('branchList'))
          if (!this.branchList.length) return
          if (localStorage.getItem('branch')) {
            this.branchList.forEach(item => {
              if (item.id == localStorage.getItem('branch')) {
                this.branchId = item.id;
                this.branchName = item.name
              }
            })
          } else {
            this.branchId = this.branchList[0].id;
            this.branchName = this.branchList[0].name
          }
          this.$emit('getBranchInfo', this.branchName);
       }

    },
    showBranchList () {
        this.branchListShow = !this.branchListShow
        this.primary = ''
    },
    primaryBranch (branchId) {
        this.primary = branchId
    },
    async setBranch () {
        sessionStorage.setItem('index','/'+this.replacePage)
        if (!this.primary) {
          this.showBranchList()
          return
        }
        this.branchList.forEach(async item => {
          if (item.id == this.primary) {
            this.branchId = item.id
            this.branchName = item.name;
            localStorage.setItem('organ', item.organ)
            localStorage.setItem('actor', item.actor)
            localStorage.setItem('branch', item.id)
            localStorage.setItem('userId', item.userId)
            localStorage.setItem('code', item.code)
            if (this.replacePage.indexOf("/") == -1) {
              setTimeout(async () => {
                location.replace("/" + this.replacePage);
                // sessionStorage.setItem('dy-mode', '0')
              }, 500);
            } else {
              setTimeout(async () => {
                location.replace(this.replacePage);
                // sessionStorage.setItem('dy-mode', '0')
              }, 500);
            }

          }
        })

    },
    // getSubUserInfo(){
    //   if (localStorage.getItem('token')) {
    //       this.$api.usercenter.findUser().then(res => {
    //       if(res.code == 0){
    //         this.$store.commit('saveSubUserInfo',res.data)
    //         localStorage.setItem('subUserInfo',JSON.stringify(res.data))
    //       }
    //     })
    //   }
    // },
  }
}
</script>
<style lang="stylus" scoped>
@import './../../../../assets/css/account.styl';
.language-dropdown
  margin-top:29px;
  color:#fff;
  margin-right:18px;
.language-dropdown-menu {
    /deep/.el-dropdown-menu__item--divided{
      margin-top:0!important;
    }
}
.unread-circle
  position: relative
  width:8px;
  height:8px;
  border-radius:50%;
  background:#D14242;
  display: block;
  margin-left: 16px;
  margin-top: -26px;
  z-index: 2
.my-message-icon
  margin-right:10px;
  margin-top:29px;
  .icon
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    font-size:22px;
    border-right 1px solid #fff
    padding-right 12px
.account-dropdown-menu {
  text-align: left;
  border-radius: 6px;
  margin-top: 22px !important;
  /deep/ .el-dropdown-menu__item--divided:before{
    margin 0px
  }
  .el-dropdown-menu__item {

    padding: 0px;
    &>ul{
      padding: 14px 20px;
    }
  }
}
.shjd-account .user-img{
  margin-top:11px
}
.shjd-account.white .user-dropdown .user-account{
  line-height 60px
}
.shjd-account .user-dropdown .user-account i{
  display flex;
  align-items center;
}
</style>
