
const routerArr = [
    {
        path: '/institutionalEntry',
        name: 'institutionalEntry',
        meta: { title:'机构入驻' , login:false },
        component: () => import(/* webpackChunkName: 'institutionalEntry' */ './page/institutionalEntry.vue'),
    },
]
export default routerArr
