var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courseItem-box"},[_c('el-tree',{ref:"tree",staticClass:"courseItem-select-tree",attrs:{"data":_vm.itemList,"props":_vm.defaultProps,"accordion":"","highlight-current":"","default-expanded-keys":_vm.expandedKeys,"node-key":"id"},on:{"node-click":_vm.handleNodeClick,"node-expand":_vm.nodeExpandClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('span',{staticClass:"custom-tree-node"},[(data.type)?_c('i',{staticClass:"el-icon-check",class:data.studyInfo && data.studyInfo.learnRate===0 ? 'nolearned' : 'learned'}):_vm._e(),_c('span',{staticClass:"dy-label",class:{lastplay:data.studyInfo && data.studyInfo.lastPlay},style:({
            marginLeft:
              data.parentId == 0 &&
              data.childrenList &&
              data.childrenList.length > 0
                ? '0px'
                : '20px'
          }),attrs:{"title":node.label}},[(data.type === 1)?_c('svg-icon',{attrs:{"icon-class":"shipin"}}):_vm._e(),(data.type === 2)?_c('svg-icon',{attrs:{"icon-class":"audio"}}):_vm._e(),(data.type === 10)?_c('svg-icon',{attrs:{"icon-class":"lianxi"}}):_vm._e(),(data.type === 11)?_c('svg-icon',{attrs:{"icon-class":"zhiboke"}}):_vm._e(),(data.type === 12)?_c('svg-icon',{attrs:{"icon-class":"shixunke"}}):_vm._e(),_vm._v(" "+_vm._s(node.label)+" ")],1),(data.dateExpireStatus == 2 || _vm.courseExpireStatus == 2)?_c('span',{staticClass:"guoqi"},[_vm._v("已过期不可查看")]):_vm._e(),(data.studyInfo && data.studyInfo.learnRate && data.type != 10 && data.type != 11 && data.type != 12 )?_c('span',{staticClass:"learnRate"},[_vm._v(_vm._s(data.studyInfo && data.studyInfo.learnRate)+"%")]):_vm._e(),(data.isLiveReplay && data.type === 11 && data.liveStatus === 2 && !data.lock)?_c('span',{staticClass:"learnRate",on:{"click":function($event){$event.stopPropagation();return _vm.jumpReplay(data)}}},[_vm._v("观看回放")]):_vm._e(),(data.lock)?_c('span',{staticClass:"lock-icon"},[_c('svg-icon',{attrs:{"icon-class":"lock"}})],1):_vm._e()])}}])}),(_vm.verification)?_c('facedialog',{attrs:{"businessId":_vm.businessId,"confirmdialog":_vm.confirmdialog,"address":_vm.verificationAddress,"failAddress":_vm.verificationFailAddress,"type":1,"pauseFrame":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }