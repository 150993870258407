const urlName = '/saas/v1'

export default API => ({
  findByCondition ({ params, data }) {
    return API({ 
      url: `${urlName}/nursingAssistantExamResult/findByCondition`, 
      method: 'post', 
      params, 
      data 
    });
  },
  /**
  * 获取养老机构城市列表
  * 响应数据data：{}
  */
  getTree () {
    return API({ url: `${urlName}/oldAgeInstitution/getRegions` });
  },
})
