const urlName = '/subsidy/v1'
export default API => ({
    // 证书列表
    getCertificateList() {
        return API({
            url: `${urlName}/certificate/findMyCertificates` ,
            method:  'get',
        });
    },
    // 是否需要实名认证 
    // 训练营里面，我的证书只传参数businessId, 我的证书列表里面，点击证书详情，只需要传id
    // 判断 isAuthentication  userAuthenStatus  ,
    // 1.isAuthentication 为0代表证书不需要实名 直接展示证书
    // 2.isAuthentication 为1代表证书 需要实名，
    // 判断 userAuthenStatus  是0  代表用户未实名，此时需要让用户去实名
    // userAuthenStatus是1代表用户已经实名 直接展示证书
    getCertificateWithCheck(key,value) {
        return API({
            url: `${urlName}/certificate/findByIdWithCheck?${key}=${value}` ,
            method:  'get',
        });
    },
    // 订单列表
    myOrderList (params) {
        return API({ 
            url: '/order/v1/open/order/myOrderListWithPage',
            method: 'GET',
            params,
        })
    },
    // 电子凭证
    htmlToPdf ({params,data}) {
        return API({ 
            url: '/saas/v1/offlineOrder/certificate',
            method: 'post',
            params,
            data
        })
    },
    // 线下订单列表
    getOfflineOrder ({params,data}) {
        return API({ 
            url: '/saas/v1/offlineOrder/find',
            method: 'post',
            params,
            data
        })
    },
    getFileStream ({params,data}) {
        return API({ 
            url: '/saas/v1/offlineOrder/getFileStream',
            method: 'get',
            params,
            data,
            responseType:'blob',
            headers:{
              'Content-Type':'application/pdf'
            }
        })
    },
    
    
})
