
const routerArr = [
    {
        path: '/lowcodeForm',
        name: 'lowcodeForm',
        meta: { title:'线下实训' , login:false },
        component: () => import(/* webpackChunkName: 'lowcodeForm' */ './page/lowcodeFormPage.vue'),
    },
  
]
export default routerArr
