// const UrlName = '/course/v1/open'
export default API => ({
    findUserPaperSurface(params) {
        return API({ url: '/exam/v1/userPaper/findUserPaperSurface', method: 'get', params});
    },
    // 考试中直播
    queryLinkLiveData(params) {
      return API({ url: '/exam/v1/userPaper/monitor/queryLinkLiveData', method: 'get', params});
    },
    // 轮询:是否有违规,是否强制交卷,是否有警告等
    queryMsgInform(params) {
      return API({ url: '/exam/v1/userPaper/monitor/queryMsgInform', method: 'get', params});
    },
    // 管理员强制交卷
    adminForceCommit(params) {
      return API({ url: '/exam/v1/userPaper/monitor/adminForceCommit', method: 'get', params});
    },
    // 管理员警告
    adminAlert(data) {
      return API({
        url: '/exam/v1/userPaper/monitor/adminAlert',
        method: 'post',
        data
      });
    },
    // 考试监管保存抓拍
    saveCapture(data) {
      return API({
        url: '/monitor/v1/monitorCaptureRecord/saveCapture',
        method: 'post',
        data
      });
    },
    // 获取人脸
    getFace() {
      return API({ url: '/monitor/v1/humanFace/getFe', method: 'get'});
    },
    //生成h5二维码接口上传
    saveTKData(params) {
        return API({
            url: `/exam/v1/userPaper/monitor/saveTKData`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },

    // 直播连接成功记录接口
    linkLiveSucc(params) {
        return API({
            url: `/exam/v1/userPaper/monitor/linkLiveSucc`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },



    // 轮训:获取拉流地址,直播是否连接
    queryLinkStatusAndPhoneLive(params) {
        return API({
            url: `/exam/v1/userPaper/monitor/queryLinkStatusAndPhoneLive`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },

    // 轮询:获取拉流地址,是否开启直播
    examDetailData(params) {
        return API({
            url: `/exam/v1/exam/findById`,
            params,
            method:'get',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        })
    },
    // 直播异常、正常上报
    linkLiveStatus(params) {
      return API({
        url: `/exam/v1/userPaper/monitor/linkLiveStatus`,
        params,
        method:'get',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
    },


    // 实操考试 获取考试环境url
    getDrillUrlForExam(data) {
      return API({
        url: `/drill/v1/drill/getDrillUrlForExam?courseId=${data.courseId}&labId=${data.labId}&userPaperId=${data.userPaperId}`,
        method: 'post'
      });
    },
    // 实操考试基本信息
    findQuestAndLabidForExam(userPaperId) {
        return API({
            url: `/exam/v1/userPaper/findQuestAndLabidForExam?userPaperId=${userPaperId}`,
            method:'get',
        });
    },

    // 轮询，记录在线人数
    sendHeartbeat(params){
      return API({
        url: `/exam/v1/data/sendHeartbeat`,
        method:'get',
        params
      });
    },
    // 考前监管获取小程序二维码
    getWxCode(tokens) {
      return API({
        url: `/monitor/v1/wx/getwxacodeunlimit?tokens=${tokens}&t=${new Date().getTime()}`,
        method:'post',
        data: {tokens: tokens}
      });
    },
    // 呼叫考官动作
    saveCall(data){
      return API({
        url: `/monitor/v1/examCall/save`,
        method:'post',
        data
      });
    },
    // 呼叫考官查询状态
    findByStatus(params){
      return API({
        url: `/monitor/v1/examCall/findByStatus`,
        method:'get',
        params
      });
    },
    // pc开启动混流
    joinMix(params) {
      return API({
        url: `/exam/v1/userPaper/monitor/joinMix`,
        method:'get',
        params
      });
    },
    // 操作手册
    download(){
      return API({
        url: `/exam/v1/userExam/download`,
        method:'get',
      });
    }
})
