<!-- 
 * @description: 国际化导航
 * @fileName: nav.vue 
 * @author:ninghf 
 * @date: 2022-06-08 11:12:24
!-->
<template>
	<div>
		<div class="menuBox menuBox-menu">
			<div class="menuContent">
				<div class="menu-left">
                    <img class="imglogo" :src="logoUrl" alt="" />
                    <el-menu
                        :mode="'horizontal'"
                        background-color="rgb(51, 51, 51)"
                        active-text-color="#fff"
                        text-color="#fff"
                        :default-active="activeNav"
                        style="width: auto"
                        @select="handleSelect"
                    >
                        <template v-for="item in navName">
                            <template v-if="!item.setting">
                                <el-menu-item v-if="!item.setting" :index="item.url" :key="item.url" :class="isActiveUrl.indexOf(item.url)>-1?'active':''">
                                    <span slot="title" :title="item.name">{{ $t(item.name) }}</span>
                                </el-menu-item>
                            </template>
                            <template v-else>
                                <el-menu-item :index="item.url[current_language]" :key="item.url[current_language]" :class="isActiveUrl.indexOf(item.url[current_language])>-1?'active':''">
                                    <span slot="title" :title="item.name">{{ $t(item.name) }}</span>
                                </el-menu-item>
                            </template>
                        </template>
                    </el-menu>
                </div>
              
                <div class="right globalButton" v-if="!token">
                   <el-dropdown  class="language-dropdown" @visible-change="toggleLanguage" trigger="click" @command="handleCommand">
                  <p>{{internationalName}}<i :class="[{'el-icon-arrow-down': !showLanguage},{'el-icon-arrow-up': showLanguage}]"></i>
                  </p>
                  <el-dropdown-menu slot="dropdown" class="language-dropdown-menu">
                    <el-dropdown-item divided :command="key" v-for="(value,key) in international"  :key="key">
                      {{ value }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                  <el-button class="button" @click="jumpLogin()" round>{{$t('WDNPC_WEB_LOGIN')}}</el-button>
                </div>
				<accountNew v-else :replacePage="this.activeNav" @getBranchInfo="getBranchInfo" />
			</div>
		</div>
	</div>
</template>

<script>
import accountNew from './../../../view/head/crowd/module/account_new.vue';
import getLanguageData from './../../../locale/locale'
export default {
	name: 'Submenus',
	components: {
		accountNew,
	},
	data() {
		return {
			logoUrl: require('../../../assets/images/common/logo.png'),
			theme: '',
			activeNav: '/GlobalHome',
      logoName: '',
      token:'',
      isActiveUrl:'/GlobalHome',
      showLanguage:false,
      current_language:localStorage.getItem('current_language') || 'zh-CN',
      internationalName: 'English',
			navName: [{
                url:'/GlobalHome',
                name:'WDNPC_WEB_HOME_GLOBAL',
                setting:false,
            },{
                url:'/learning',
                name:'WDNPC_WEB_LEARNING_CENTER',
                setting:false,
            },
            {
                url:'/examList',
                name:'WDNPC_WEB_EXAM',
                setting:false,
            },{
                name:'WDNPC_WEB_CERTIFICATE',
                setting:true,
                url:{
                  'en-US':'/certificate-en',
                  'zh-CN':'/certificate-cn',
                  'zh-cn':'/certificate-cn',
                  'fr-FR':'/certificate-fr'
                }
            },{
                setting:true,
                url:{
                  'en-US':'/about-en',
                  'zh-CN':'/about-cn',
                  'zh-cn':'/about-cn',
                  'fr-FR':'/about-fr'
                },
                name:'WDNPC_WEB_ABOUT_US'
            }],
			isSelect: null,
            navigate: [], //所有导航
		}
  },
  computed: {
		// 不同机构
		international() {
			return {
				"zh-CN":"中文",
				"en-US":'English',
				"fr-FR":"Français"
			}
		},
	},
	created() {
    this.theme = localStorage.getItem('theme') || '';
    this.token = localStorage.getItem('token');
    localStorage.setItem('is_i18n',true)
    const navLang = navigator.language;
    if(localStorage.getItem('current_language')==null){
      localStorage.setItem('current_language',navLang)
      this.$i18n.locale = navLang;
    }
    this.internationalName = this.international[localStorage.getItem('current_language')]
    
	},
	watch: {
		$route() {
			sessionStorage.setItem('index', this.$route.meta.highlight)
			setTimeout(() => {
        //路由跳转
        this.isActiveUrl = this.$route.path;
        this.activeNav = this.$route.meta.highlight;
        this.token = localStorage.getItem('token')
			}, 100)
		},
    '$store.state.app.language':{
      handler(){
        this.current_language = localStorage.getItem('current_language');
        if(this.$route.path.indexOf('about-')>-1){
          this.$router.push({
            path:`${this.navName[4].url[this.current_language]}`
          })
          this.isActiveUrl = this.$route.path;
        }else if(this.$route.path.indexOf('certificate-')>-1){
          this.$router.push({
            path:`${this.navName[3].url[this.current_language]}`
          })
          this.isActiveUrl = this.$route.path;
        }
      },
      deep:true,
      immediate:true
    },
	},
	async mounted() {
		setTimeout(() => {
			//刷新
      this.activeNav = sessionStorage.getItem('index') || this.activeNav
      this.token = localStorage.getItem('token')
		}, 100)
	},
	methods: {
    toggleLanguage(show) {
			this.showLanguage = show
		},
    async handleCommand(key,val){
			console.log(val);
			localStorage.setItem('current_language',key)
            this.internationalName = this.international[key];
            document.getElementsByTagName('html')[0].setAttribute('lang', key)
			await getLanguageData()
					this.$i18n.locale = key
			this.$store.commit('setLanguage',key)
					this.setUpLanguageTimeout = setTimeout(() => {
						this.setUpLanguageShow = false
					}, 100)
		},
    jumpLogin(){
      this.$router.push({
					path: '/login_new',
				})
    },
		handleSelect(keyPath) {
      console.log(keyPath);
			this.$router.push(keyPath)
		},
		getBranchInfo(val) {
			this.getLogoName(val)
		},
		getLogoName(value) {
			if (value.length > 23) {
				this.logoName = value.substring(0, 23) + '...'
			} else {
				this.logoName = value
			}
		},
		getActiveNav() {
			this.navName.forEach((d) => {
				if (d.url === this.$route.path || this.$route.path.indexOf(d.url) !== -1) {
					this.isSelect = d
				}
			})
		},
	},
}
</script>
<style lang="stylus" scoped>
// @import './../../../../assets/css/menu';
</style>
<style lang="stylus" scoped>
/deep/.el-menu--horizontal{
  .el-menu-item.active{
    color:#fff !important;
    background: rgba(225,225,225,0.1)!important;
  }
}
.language-dropdown{
  height:64px!important;
  line-height 64px;
}
.globalButton{
  /deep/.el-button{
    background:transparent;
    padding:6px 23px;
    color:#fff;
  }
}
.top-account{
    margin-top:-15px;
}
/deep/ .el-dialog{
  border-radius 10px
  .el-dialog__body{
    padding 19px 25px 25px
  }
}
.dialog-footer{
  /deep/ .el-button{
    width: 240px
    background-color #316FFF
  }
}
.dropdown-title{
  display inline-block
  max-width 120px
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
}
/deep/ .el-dialog__footer{
  padding 5px 20px 30px
}
/deep/ .el-dialog__close{
  font-size 18px;
  font-family PingFangSC-Medium, PingFang SC;
  color #979797
}
/deep/.el-menu.el-menu--horizontal{
    border:none;
}
/deep/ .el-dropdown-menu__item{
  margin-top 0
  overflow hidden
  ul{
    margin 20px 0px;
    li{
      line-height 20px
      font-size 14px
      font-family PingFangSC-Medium, PingFang SC;
    }
  }
}
/deep/ .el-dropdown-menu__item--divided:before{
  height 0
}
.el-menu--horizontal {
  &>.el-submenu, &>.el-menu-item {
    display: inline-block
    height 64px
    line-height 64px
    float: none !important
    border:none;
    span{
      font-size 16px;
      font-family PingFangSC-Medium, PingFang SC;
    }
  }
  /deep/ .is-active {
    background-color rgba(225, 225, 225, .1) !important
  }
}

.el-menu {
  margin-left: 81px;
}
.gongxin .el-menu {
  width: auto;
}

.el-dropdown {
  margin-right: 20px;
  position: relative;
  height 76px
  color #fff
  cursor: pointer;
  .el-dropdown-link {
    height: 76px;
    display: inline-block;
    span{
      line-height: 80px;
      margin-right 30px
    }
  }
}

.el-icon-arrow-down.el-icon--right{
  position: absolute;
  top: 28px;
  right 0px;
}

.menuBox {
  text-align: center;
  background:rgb(51, 51, 51);
  position: fixed;
  height:64px;
  z-index: 100;
  width: 100%;

  .menuContent {
    width: 1200px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-left{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .menuName {
      width: 265px;
      height: 80px;
      position: relative;
      .textType {
        display: block;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  img.fl {
    width: 121px;
    font-family PingFangSC-Medium, PingFang SC;
    margin-top: 10px;
  }
  img.flagFl {
    float: left;
    width: 222px;
    font-family PingFangSC-Medium, PingFang SC;
    margin-top: 24px;
  }
  img.fr {
    width: 40px;
    height: 40px;
    margin-top: 18px;
    margin-right: 20px;
  }
}
</style>
