
const routerArr = [
    {
        path: '/opHomepage',
        name: 'opHomepage',
        meta: { title:'首页' , login:false },
        component: () => import(/* webpackChunkName: 'openPlatform' */ './page/homepage.vue'),
    },
    {
        path: '/openPlatform/serviceProviderList',
        name: 'serviceProviderList',
        meta: { title:'培训服务商' , login:false },
        component: () => import(/* webpackChunkName: 'openPlatform' */ './page/serviceProviderList.vue'),
    },
    {
        path: '/openPlatform/courseGoods',
        name: 'opcourseGoods',
        meta: { title:'商品列表' , login:false },
        component: () => import(/* webpackChunkName: 'openPlatform' */ './page/courseGoods.vue'),
    },
    {
        path: '/openPlatform/courseGoodsDetail',
        name: 'opcourseGoodsDetail',
        meta: { title:'商品详情' , login:false },
        component: () => import(/* webpackChunkName: 'openPlatform' */ './page/courseGoodsDetail.vue'),
    },
    {
        path: '/openPlatform/orderPage',
        name: 'oporderPage',
        meta: { title:'确认订单' , login:true },
        component: () => import(/* webpackChunkName: 'openPlatform' */ './page/orderPage.vue'),
    },
    
    
]
export default routerArr
