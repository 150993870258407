
const routerArr = [
    {
        path: '/personalCenter',
        name: 'personalCenter',
        meta: { title:'个人中心' , login:true },
        component: () => import(/* webpackChunkName: 'account' */ './page/personal.vue'),
    },
]
export default routerArr
