const urlName = '/usercenter/v2/t-c/account'
export default (API) => ({
	login(data) {
		return API({
			url: `${urlName}/login`,
			method: 'post',
			data,
		})
	},
	logout() {
		return API({
			url: `${urlName}/logout`,
			method: 'post',
		})
    },
    register(data) {
		return API({
			url: `${urlName}/register`,
			method: 'post',
			data,
		})
    },
    // 校验本人验证码-忘记密码使用
    checkAuthCode(params) {
		return API({
			url: `${urlName}/checkAuthCode`,
			method: 'post',
			params,
		})
    },
    // 获取手机授权码用于修改密码
	getPhoneAuthCode4ChangePwd(params) {
		return API({
			url: `${urlName}/getPhoneAuthCode4ChangePwd`,
			method: 'post',
			params,
		})
	},
	// 获取邮箱授权码,用于修改密码
	getMailAuthCode4ChangePwd(params) {
		return API({
			url: `${urlName}/getMailAuthCode4ChangePwd`,
			method: 'post',
			params,
		})
    },
    // 手机验证码修改密码
    changePasswordByPhoneAuthCode(data) {
		return API({
			url: `${urlName}/changePasswordByPhoneAuthCode`,
			method: 'post',
			data,
		})
    },
    // 邮箱验证码修改密码
    changePasswordByMailAuthCode(data) {
		return API({
			url: `${urlName}/changePasswordByMailAuthCode`,
			method: 'post',
			data,
		})
    },
	// 获取手机授权码用于登录
	getPhoneAuthCode4Login(params) {
		return API({
			url: `${urlName}/getPhoneAuthCode4Login`,
			method: 'post',
			params,
		})
    },
    // 获取手机授权码用于注册
	getPhoneAuthCode4Register(params) {
		return API({
			url: `${urlName}/getPhoneAuthCode4Register`,
			method: 'post',
			params,
		})
	},
	// 获取邮箱授权码,用于登录
	getMailAuthCode4Login(params) {
		return API({
			url: `${urlName}/getMailAuthCode4Login`,
			method: 'post',
			params,
		})
	},
	switchSaas(saasUcode) {
		return API({
			url: `${urlName}/switchSaas?saasUcode=${saasUcode}`,
			method: 'post',
		})
	},
	switchIdentity(identity) {
		return API({
			url: `${urlName}/switchIdentity?identity=${identity}`,
			method: 'post',
		})
	},
	switchBranch(branchUcode) {
		return API({
			url: `${urlName}/switchBranch?branchUcode=${branchUcode}`,
			method: 'post',
		})
	},
	changePasswordByOld(data) {
		return API({
			url: `${urlName}/changePasswordByOld`,
			method: 'post',
			data,
		})
	},
	// 当前账号手机号获取授权码--换绑使用
	getPhoneAuthCodeBySelf(params) {
		return API({
			url: `${urlName}/getPhoneAuthCodeBySelf`,
			method: 'post',
			params,
		})
	},
	// 获取手机授权码用于换绑手机号
	getPhoneAuthCode4ChangePhone(params) {
		return API({
			url: `${urlName}/getPhoneAuthCode4ChangePhone`,
			method: 'post',
			params,
		})
	},
	// 当前账号邮箱获取授权码--换绑使用
	getMailAuthCodeBySelf(params) {
		return API({
			url: `${urlName}/getMailAuthCodeBySelf`,
			method: 'post',
			params,
		})
	},
	// 获取邮箱授权码,用于换绑邮箱
	getMailAuthCode4ChangeMail(params) {
		return API({
			url: `${urlName}/getMailAuthCode4ChangeMail`,
			method: 'post',
			params,
		})
	},
	// 校验本人验证码-换绑手机号使用
	checkPhoneAuthCodeBySelf(params) {
		return API({
			url: `${urlName}/checkPhoneAuthCodeBySelf`,
			method: 'post',
			params,
		})
	},
	// 校验本人验证码-换绑邮箱使用
	checkMailAuthCodeBySelf(params) {
		return API({
			url: `${urlName}/checkMailAuthCodeBySelf`,
			method: 'post',
			params,
		})
    },
    // 绑定手机号
	bindPhone(data) {
		return API({
			url: `${urlName}/bindPhone`,
			method: 'post',
			data,
		})
    },
    // 变更手机
	changePhone(data) {
		return API({
			url: `${urlName}/changePhone`,
			method: 'post',
			data,
		})
	},
	// 绑定邮箱
	bindMail(data) {
		return API({
			url: `${urlName}/bindMail`,
			method: 'post',
			data,
		})
	},
	// 变更邮箱
	changeMail(data) {
		return API({
			url: `${urlName}/changeMail`,
			method: 'post',
			data,
		})
	},
	// 注销
	destory() {
		return API({
			url: `${urlName}/destory`,
			method: 'post',
		})
	},
})
