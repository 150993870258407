<template>
	<div>
		<el-dialog :visible.sync="showInfo" width="800px" @close="$emit('infoShow', false)" class="pub_dialog courseInfoDialog" center>
			<div class="dialog-detail">
				<img class="pic" :src="currentCourse.pic || currentCourse.iconUrl || currentCourse.coverUrl" alt="" />
				<div class="detail_right">
					<h4>{{ currentCourse.label || currentCourse.name }}</h4>
					<div class="right_bottom">
						<p class="dialog-date" v-if="currentCourse.trainStartTime && type !== 3">
							{{ currentCourse.trainStartTime | dataFormat }}{{$t('WDNPC_WEB_TO',{text:'至'})}}{{ currentCourse.trainEndTime | dataFormat }}
						</p>
						<div class="dialog-date" v-else>
							<p v-if="currentCourse.cycleType === 1">
								{{ currentCourse.startTime.split(' ')[0].replace(/-/g, '.') }} {{$t('WDNPC_WEB_TO',{text:'至'})}} {{ currentCourse.endTime.split(' ')[0].replace(/-/g, '.') }}
							</p>
							<p v-if="currentCourse.cycleType === 2">{{$t('WDNPC_WEB_VALID',{text:'有效期至：'})}}{{ currentCourse.endTime.split(' ')[0].replace(/-/g, '.') }}</p>
							<p v-if="currentCourse.cycleType === 3">{{$t('WDNPC_WEB_LONG_TERM_EFFECTIVE',{text:'长期有效'})}}</p>
						</div>
						<div class="evaluation-box">
							<div class="evaluation _clear">
                <el-rate
                  v-model="rateValue"
                  :disabled-void-color="'#E7E7E7'"
                  :icon-classes="iconClasses"
                  void-icon-class="icon-xingxing-kongxin"
                  disabled-void-icon-class="icon-xingxing-kongxin"
                  disabled
                  allow-half
                  
                ></el-rate>
								
								<p class="score-text">{{ rateValue }}{{$t('WDNPC_WEB_POINTS',{text:'分'})}}</p>
								<span class="course-score" :class="{ypf:issubmit}" @click="playboxscoreclick">{{ issubmit ? $t('WDNPC_WEB_RATED',{text:'已评分'}) : $t('WDNPC_WEB_UNRATED',{text:'未评分'}) }}</span>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="dialog-info" v-if="!type">{{ nameTypeGet }}{{ description || currentCourse.description }}</div>
			<div v-else class="dialog-info">
				<!-- {{ nameType }} -->
				<div v-html="description || currentCourse.description"></div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="$emit('infoShow', false)">{{$t('WDNPC_WEB_GOT_IT',{text:'知道了'})}}</el-button>
			</span>
		</el-dialog>
		<!-- 评分弹框 -->
    <scoreDialog v-if="scoreDialogVisible.show" :scoreDialogVisible="scoreDialogVisible" :info="scoreInfo" :issubmit="issubmit"></scoreDialog>
	</div>
</template>
<script>
export default {
	name: 'dialogInfo',
	props: {
		info: {
			type: Boolean,
			default: false,
		},
		currentCourse: {
			type: Object,
			default: () => {},
		},
		description: {
			type: String,
			default: '',
		},
		type: {
			type: Number,
		},
		nameType: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
      rateValue:5,//总评分
      iconClasses:[ 'icon-xingxing-kongxin', 'icon-xingxing-banxin', 'icon-xingxing-shixin'],
			showInfo: false,
      scoreInfo:{
          title:'课程评分',
          name:'',
          scoreArr:[]
      }, // 评分需要的参数
			issubmit: false, //是否评分
      scoreDialogVisible:{
          show:false,
      },
			nameTypeGet:''
		}
	},
	filters: {
		dataFormat(msg) {
			const date = new Date(msg)
			const m = date.getMonth() + 1
			return date.getFullYear() + '.' + m + '.' + date.getDate()
		},
	},
	watch: {
		info(val) {
			this.showInfo = val
			this.getEvaluationInfo()
		},
		nameType(){
			this.nameTypeGet = this.nameType || this.$t('WDNPC_WEB_COURSE_INTRODUCTION',{text:'课程简介：'});
		}
	},
	mounted() {
	},
	methods: {
		toDetail(e) {
			this.isSelect = e
		},
		// 点击评价
		playboxscoreclick() {
			if (this.currentCourse.userLearnInfo && this.currentCourse.userLearnInfo.learnRate >= 30) {
        this.scoreDialogVisible.show = true;
			} else {
				this.$message.error(this.$t('WDNPC_WEB_THE_COURSE_LEARNING_PROGRESS_IS_NOT_REACHED',{text:'课程学习进度未达到30%，不能进行评价'}))
			}
		},
		getEvaluationInfo() {
			let params = {
				courseId: this.currentCourse.id,
			}
			this.$api.course.getEvaluationInfo({ params }).then((res) => {
        this.rateValue = res.data.aveScore || 5;
        this.issubmit = res.data.myScore ? true : false;
        console.log(this.currentCourse,'this.currentCourse')
        this.scoreInfo.name = this.currentCourse.name || '';
        let detailInfo = res.data.detailInfo && JSON.parse(res.data.detailInfo)
        this.scoreInfo.scoreArr = [
            {
              label:'课程内容',
              count:detailInfo ? Math.ceil(detailInfo.courseContentScore) : 0,
            },
            {
              label:'教学方法',
              count:detailInfo ? Math.ceil(detailInfo.trainMethodScore) : 0,
            },
            {
              label:'教学结果',
              count:detailInfo ? Math.ceil(detailInfo.trainEffectScore) : 0,
            }
        ];
        
				this.$forceUpdate()
			})
        },
        // 关闭评分
        clickclose() {
            this.scoreDialogVisible.show = false;
            this.$forceUpdate();
        },
        // 点击评分提交
        async evaluateCourse(info) {
            console.log(info)
            const data = {
                courseId: this.currentCourse.id,
                courseContentScore:info[0].count,//课程内容
                trainMethodScore:info[1].count,//教学方式
                trainEffectScore:info[2].count,//教学效果
            };
            await this.$api.course.evaluateCourse({ data }).then(() => {
                this.scoreDialogVisible.show = false;
                this.getEvaluationInfo();
            });
        },
	},
}
</script>
<style lang="stylus">
.el-dialog
  border-radius: 10px;
.el-dialog__footer
  padding: 0px 20px 30px;
.el-dialog__headerbtn .el-dialog__close
  font-size: 20px
</style>
<style lang="stylus" scoped>
@import '../../../assets/css/font/iconfont.css';
@import "../css/dialogInfo.styl"
.courseInfoDialog{
    /deep/.el-dialog{
        border-radius: 8px;
        .el-rate{
            height: auto;
        }
        .el-rate__icon{
            font-size: 32px;
            // margin-right: 0;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            font-family: iconfont !important;
        }
        .el-rate__decimal{
          font-style: normal;
        }
        .pic{
            border: 1px solid #f1f1f0;
            border-radius: 4px;
        }
        .dialog-footer{
            .el-button{
                width:240px;
            }
        }
    }
}
</style>
