export default API => ({
    htmlPdf({data,params}) {
        return API({
            url: `/cms/v1/offlineOrder/certificate`,
            method: 'post',
            data,
            params
        })
    }

})