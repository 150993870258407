const urlName = '/monitor/v1/ai'


export default API => ({
    postcamera({ params, data }) {
        return API({ url: `monitor/v1/humanFace/comparison`, method:'post', params, data,isToast:false });
    },
    judgefaceauth({ params, data }) {
        return API({ url: `/monitor/v1/user/oauth/isRealNameAuthentication`, method:'post', params, data });
    },
    getAccountUserInfo ({ params, data, headers }) {
        return API({ url: '/usercenter/v1/account/getUserInfo', params, data, headers });
    },
    getConfiguration({ params, data }) {
        return API({  url: '/modular/admin/v1/configuration/', params, data });
    },
    faceverify({ params, data }) {
        return API({ url: `${urlName}/oauth/faceverify`, method:'post', params, data});
    },
    idcardAffirm({ params, data }) {
        return API({ url: `${urlName}/oauth/idcardAffirm`, method:'post', params, data});
    },
    idcardDiscern({ params, data }) {
        return API({ url: `${urlName}/oauth/idcardDiscern`, method:'post', params, data});
    },
    getvirefytoken({ params, data }) {
        return API({ url:`${urlName}/token/getVerifyToken`, method:'post', params, data });
    },
    getprivacyPolicy() {
        return API({ url:`/resource/v1/privacyPolicy/getPrivacyPolicy?id=1`});
    },
    // 记录用户授权人脸比对照片保存
    // POST
    // /monitor/v1/humanFace/authSaveFace
    // query参数：taskId
    authSaveFace({ params, data }) {
        return API({ url:`/monitor/v1/humanFace/authSaveFace`, method:'post', params, data });
    },
    // 获取用户授权人脸比对照片保存记录
    // GET
    // /monitor/v1/humanFace/getAuthSaveFace
    // query参数：taskId
    // 返回：1-已授权，0-未授权
    getAuthSaveFace({ params, data }) {
        return API({ url:`/monitor/v1/humanFace/getAuthSaveFace`, method:'get', params, data });
    },
})