<template>
    <div class="training-task-list">
        <div class="tab">
            <span v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" @click="tabClick(tab)">
                {{tab.value}}
            </span>
        </div>
        <div class="study">
            <div class="study-time">
                <div class="val">{{learningTime.todayLearnTime}}</div>
                <div class="key">{{$t('WDNPC_WEB_TODAY_CUMULATIVE_STUDY',{text:'今日累计学习'})}}（{{$t('WDNPC_WEB_HOURS',{text:'小时'})}}）</div>
            </div>
            <div class="study-time">
                <div class="val">{{learningTime.thisWeekLearnTime}}</div>
                <div class="key">{{$t('WDNPC_WEB_CUMULATIVE_STUDY_THIS_WEEK',{text:'本周累计学习'})}}（{{$t('WDNPC_WEB_HOURS',{text:'小时'})}}）</div>
            </div>
            <div class="study-time">
                <div class="val">{{learningTime.thisMonthLearnTime}}</div>
                <div class="key">{{$t('WDNPC_WEB_ACCUMULATED_STUDY_THIS_MONTH',{text:'本月累计学习'})}}（{{$t('WDNPC_WEB_HOURS',{text:'小时'})}}）</div>
            </div>
        </div>
        <div class="content">
            <div class="list" v-for="item in taskList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.coverUrl" alt="">
                    <img v-show="item.isNewTask" class="icon" src="../assets/images/learning/new-icon.png" alt="">
                </div>
                <div class="middle item">
                    <div class="title">
                        <!-- 0 未分类，1 必修课，2 选修课，3 公开课 -->
                        <p
                            class="modifier"
                            :style="
                                item.taskType === 1 ? 'background:#FF7051'
                                :item.taskType === 2?'background:#2EC8A5'
                                :'background:#6080FB'"
                            v-show="item.taskType"
                        >
                            {{item.taskType === 1 ? $t('WDNPC_WEB_REQUIRED_COURSES',{text:'必修课'}) :item.taskType === 2?$t('WDNPC_WEB_ELECTIVE_COURSE',{text:'选修课'}):$t('WDNPC_WEB_OPEN_CLASS',{text:'公开课'})}}


                        </p>
                        <h1 :style="item.branchName ? 'margin-bottom:4px;':'margin-bottom:20px'">{{item.name}}</h1>
                    </div>
                    <p class="branchName" v-if="item.branchName">{{ item.branchName }}</p>
                    <!-- <p class="label">
                        <span class="text" v-for="type in item.typeList" :key="type">{{$t(type)}}</span>
                    </p> -->

                    <p class="bottom-container" v-if="item.courseNum > 0">
                        <span class="studyProcess">{{ $t('WDNPC_WEB_HAS_LEARNED',{text:'已学'}) }}{{item.studyProcess || 0}}%</span>
                        <span class="shichang">{{ $t('WDNPC_WEB_HAS_LEARNED',{text:'已学'}) }}{{formateTime(item.learnTotalTime)}}h</span>
                        <el-progress :percentage="item.studyProcess || 0" :show-text="false" color="#15A0FE"></el-progress>
                       
                    </p>

                    <ul class="tag">
                        <li v-for="tagItem in item.taskItemTypeList" :key="tagItem.type">
                            <p v-if="tag[tagItem.type]">
                                <span>{{ tag[tagItem.type]}}：</span>
                                <span>{{ tagItem.count }}</span>
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="right item">
                    <div class="button" @click="goTaskDetail(item)">{{($dateFormat.dateFormat() > item.endTime && (item.cycleType === 2 || item.cycleType === 1)) ? $t('WDNPC_WEB_VIEW',{text:'查看'}) :item.isNewTask ? $t('WDNPC_WEB_START_LEARNING',{text:'开始学习'}):$t('WDNPC_WEB_CONTINUE_LEARNING',{text:'继续学习'})}}</div>


                </div>
            </div>
            <article v-show="noData" class="no-data">
                <img class="notraining" src="../assets/images/common/nodata.png" alt />
                <p>{{$t('WDNPC_WEB_YOU_HAVENT_LEARNED_THE_CONTENT_YET',{text:'您还没有学习内容'})}}</p>
            </article>
            <p class="loadingmore" v-if="loading">{{$t('WDNPC_WEB_LOADING',{text:'加载中'})}}...</p>
            <p class="loadingmore" v-if="noMore">- {{$t('WDNPC_WEB_ALL_CONTENT_LOADED',{text:'已加载全部内容'})}} -</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    name:'trainingTask',
    props:{
        validity:{
            type:String,
            default:''
        },
        Task_interface_optimize:{
          type:Boolean,
          default:false
        }
    },
    data(){
        return {
            tabIndex:-1,
            tabList:[
                {
                    id:-1,
                    value:'全部',//{text:'全部'})',
                },
                {
                    id:1,
                    value:'未开始',//{text:'未开始'})',
                },
                {
                    id:2,
                    value:'进行中',//{text:'进行中'})',
                },
                {
                    id:3,
                    value:'已结束',//{text:'已结束'})',
                }
            ],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            taskList:[],
            taskStatus:null,
            loading:false,
            noData:null,
            learningTime:{},
            tag: {
				2: '课程',//{text:'课程'})',
				3: '考试',//{text:'考试'})',
				4: '直播',//{text:'直播'})',
				5: '线上实训',//{text:'线上实训'})',
				6: '表单',//{text:'表单'})',
				7: '练习',//{text:'练习'})',
				8: '线下实训',//{text:'线下实训'})',
			}, //内容类型
        }
    },
    computed: {
        noMore () {
            return this.taskList.length === this.paging.total && this.paging.total !== 0
        },
    },
    mounted(){
        // 学习时长统计
		this.getAllLearnedData()
        
        if(this.Task_interface_optimize){
          this.findTaskList();
        }else {
          this.getData()
        }
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')

                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    methods:{
      
        getAllLearnedData() {
			this.$api.learning.getAllLearnedData().then((res) => {
				this.learningTime = res.data
			})
		},
        formateTime(val) {
            if(val){
                if((val / 3600).toFixed(2)>0){
                    return (val / 3600).toFixed(2);
                }else{
                    return (val / 3600).toFixed(0);
                }

            }else{
                return val;
            }
        },
        tabClick(item){
            this.tabIndex = item.id
            this.paging.params = {
                pageNum: 1,
                pageSize: 10
            }
            this.taskStatus = item.id === -1 ? null : item.id;
            if(this.Task_interface_optimize){
              this.findTaskList();
            }else {
              this.getData()
            }
        },

        goTaskDetail(item){
            if(this.$dateFormat.dateFormat() < item.startTime && +item.cycleType === 1){
                this.$message.warning(this.$t('WDNPC_WEB_TASK_NOT_STARTED',{text:'任务未开始'}))
            }else if(this.$dateFormat.dateFormat() > item.endTime && (+item.cycleType === 1 || +item.cycleType === 2)){
                this.$message.warning(this.$t('WDNPC_WEB_UNABLE_TO_LEARN_AFTER_EXPIRATION',{text:'已到期，无法学习'}))
            }else {
                sessionStorage.setItem('ishint',1)
                this.$router.push({
                    path:'/learning/taskDetail',
                    query:{
                        backMoudle:'trainingTask',
                        id:item.id,
                        isNewTask:item.isNewTask,
                        validity:this.validity,
                        backPath:this.$route.path,
                    }
                })
            }
        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                if(this.Task_interface_optimize){
                  this.findTaskList('reachBottom');
                }else {
                  this.getData("reachBottom");
                }
            } else {
                this.loading = false;
            }
        },
        // 列表初始化
        findTaskList(){
          let params = {
            pageNum: this.paging.params.pageNum,
            pageSize: this.paging.params.pageSize,
            taskStatus: this.taskStatus
          }
          this.$api.learning.findTaskList({params}).then(async res => {
            if(res.data){
              if(res.data.list && res.data.list.length){
                  this.noData = false
              }else {
                  this.noData = true;
              }
              if(this.paging.params.pageNum>1){
                  this.taskList = this.taskList.concat(res.data.list);
              } else {
                  this.taskList = res.data.list || [];
              }
              let ids = [];
              this.taskList.forEach(item => {
                  ids.push(item.id);
              });
              await this.findTaskLearningInfo(ids.join(','))
              this.paging.total = res.data.total;
            }
          })
        },
        // 二次渲染 学习相关信息
        async findTaskLearningInfo(ids){
            let params = {
                param:ids
            }
            await this.$api.learning.findTaskLearningInfo({params}).then(res => {
                if(res.data){
                  this.taskList.forEach(item => {
                    for(let key in res.data){
                      if(Number(key) === item.id){
                          item.contentTypeSet = res.data[key].contentTypeSet;
                          item.studyProcess = res.data[key].studyProcess;
                          item.taskItemTypeList = res.data[key].taskItemTypeList;
                          item.courseNum = res.data[key].courseNum;
                          item.learnTotalTime = res.data[key].learnTotalTime;
                      }
                    }
                  })
                  this.contentTypeSet();
                }
            })
        },
        contentTypeSet(){
          let typeList = [];
          this.taskList.length && this.taskList.forEach(item => {
              typeList = [];
              item.contentTypeSet.length && item.contentTypeSet.forEach(item2=>{
                  if(item2 === 2){
                      typeList.push('WDNPC_WEB_COURSE') //,{text:'课程'}
                  }else if(item2 === 3){
                      typeList.push('WDNPC_WEB_EXAM')//,{text:'考试'})
                  }else if(item2 === 4){
                      typeList.push('WDNPC_WEB_LIVE_STREAMING')//,{text:'直播'})
                  }else if(item2 === 5){
                      typeList.push('WDNPC_WEB_TRAINING')//{text:'实训'})
                  }else if(item2 === 6){
                      typeList.push('WDNPC_WEB_FORM')//,{text:'表单'})
                  }else if(item2 === 7){
                      typeList.push('WDNPC_WEB_PRACTICE')//,{text:'练习'})
                  }
              })
              item.typeList = typeList;
          })
        },
        // 老接口返回所有数据
        getData() {
            this.$api.learning
                .taskList({
                    params: {
                        pageNum: this.paging.params.pageNum,
                        pageSize: this.paging.params.pageSize,
                        taskStatus: this.taskStatus
                    }
                })
                .then(({ data: { list, total } }) => {
                    if(list && list.length){
                        this.noData = false
                    }else {
                        this.noData = true;
                    }
                    if(this.paging.params.pageNum>1){
                        this.taskList = this.taskList.concat(list);
                    } else {
                        this.taskList = list || [];
                    }
                    this.paging.total = total;
                    this.contentTypeSet();
                });

        },
        
    }
}
</script>
<style lang="stylus" scoped>
@import './../assets/css/learningList.styl'
    .study
        height: 120px;
        background: url('./../assets/images/learning/studybg.png') no-repeat center
        background-size: cover
        display flex
        padding 16px 85px
        box-sizing border-box
        display flex
        justify-content space-between
        margin-bottom 38px
        .study-time
            text-align center
            .key
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #002D60;
                line-height: 25px;
            .val
                font-size: 40px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #002D60;
                line-height: 64px;
</style>
