const urlName = '/live/v1'
export default API => ({
    // 列表
    findByCondition(data) {
        return API({
            url: `${urlName}/liveRoom/findByCondition?pageSize=${data.pageSize}&pageNum=${data.pageNum}` ,
            method:  'post',
            data
        });
    },
    // 直播列表 新接口
    getDataList({data,params}) {
        return API({
            url: `/live/v1/userLive/open/findByCondition` ,
            data,
            params
        });
    },


    // 进入直播
    enterLive(data) {
        return API({
            url: `${urlName}/liveRoomUserX/anchorEnterRoom?id=${data.id}` ,
            method:  'post',
            data
        });
    },

    // 学员进入直播课访问屏code值
    getStudentVisitCode({data,params}) {
        return API({
            url: `/live/v1/liveCourseOpen/getStudentVisitCode` ,
            data,
            params
        });
    },


    // 老接口

    // audienceEnterRoom(data) {
    //     return API({
    //         url: `${urlName}/liveRoomUserX/audienceEnterRoom?id=${data.id}` ,
    //         method:  'post',
    //         data
    //     });
    // },


    // 获取百家云回放
    getBjyBackUrl(data) {
        return API({
            url: `/live/v1/liveCourse/getBjyBackUrl?liveCourseId=${data.liveCourseId}` ,
            method:  'post',
            data
        });
    },

    // 判断是不是百家云
    getBjyPlayUrl(data) {
        return API({
            url: `${urlName}/liveCourse/getBjyPlayUrl?liveCourseId=${data.liveCourseId}` ,
            method:  'post',
            data
        });
    },

    // 开始直播新接口
    audienceEnterRoom(data) {
        return API({
            url: `${urlName}/liveCourseOpen/audienceEnter?id=${data.id}` ,
            method:  'post',
            data
        });
    },

    // 开始直播新接口
    saveHeaderInfo(data) {
        return API({
            url: `${urlName}/tiw/saveHeaderInfo` ,
            method:  'post',
            data
        });
    },


    // 根据ROOMid获取房间信息  老接口
    // findById(data) {
    //     return API({
    //         url: `${urlName}/liveRoom/findById?id=${data.id}` ,
    //         method:  'post',
    //         data
    //     });
    // },
    // 新街口
    findById(data) {
        return API({
            url: `${urlName}/liveCourse/findById?id=${data.id}` ,
            data
        });
    },

    // 获取 sdkappid 和 UserSig  老接口
    findUserSig(pre) {
        return API({
            url: `${urlName}/tencentyun/findGenParam?pre=${pre}` ,
            method:  'post',
        });
    },

    // 获取 sdkappid 和 UserSig 新接口
    getUsersig({data,params}) {
        return API({
            url: `${urlName}/impass/getUsersig` ,
            method:  'post',
            data,
            params
        });
    },

    // 开始直播
    startLive(data) {
        return API({
            url: `${urlName}/liveRoomUserX/startLive?id=${data.id}` ,
            method:  'post',
            data
        });
    },

    // 延长/取消直播
    updateLive(data) {
        return API({
            url: `${urlName}/liveRoom/update?id=${data.id}&status=${data.status}` ,
            method:  'post',
            data
        });
    },



    // 获取直播间学员在线列表
    getOnlineInfoList(data) {
        return API({
            url: data.nick ? `${urlName}/liveRoomUserX/getOnlineInfoList?roomId=${data.roomId}&nick=${data.nick}` :  `${urlName}/liveRoomUserX/getOnlineInfoList?roomId=${data.roomId}`,
            method:  'post',
            data
        });
    },


    // 每30调用一次判断用户是否在线  不用了
    judgeOnlineInfo(data) {
        return API({
            url: `${urlName}/liveRoomUserX/judgeOnlineInfo?roomId=${data.roomId}` ,
            method:  'post',
            data
        });
    },
    // 老接口
    // saveLiveBarrage(data) {
    //     return API({
    //         url: `${urlName}/liveBarrage/saveLiveBarrage` ,
    //         method:  'post',
    //         data
    //     });
    // },
    // 新接口
    saveLiveBarrage({data,params}) {
        return API({
            url: `${urlName}/liveBarrage/sendBarrage` ,
            method:  'post',
            data,
            params
        });
    },
    // 举手
    requestToSpeak({data,params}) {
        return API({
            url: `${urlName}/liveAction/requestToSpeak`,
            method:  'post',
            data,
            params
        });
    },

    // 获得直播房间信息--调用Paas服务提供 【教师/学员端】开始直播时候调用
    getRoomInfo({data,params}) {
        return API({
            url: `${urlName}/livePaasAgent/getRoomInfo`,
            method:  'post',
            data,
            params
        });
    },

    // 学生 声音/在台上与否/文字 状态回调
    statueCallBack({data,params}) {
        return API({
            url: `${urlName}/liveAction/statueCallBack`,
            method:  'post',
            data,
            params
        });
    },

    // 获取弹幕列表
    getBarrageList({data,params}) {
        return API({
            url: `${urlName}/liveBarrage/getBarrageList`,
            method:  'post',
            data,
            params
        });
    },
    // 直播回放
    saveViewUser(id){
        return API({
            url: `${urlName}/liveCourseReplay/open/saveViewUser?liveCourseId=${id}` ,
            method:  'get',
        });
    },
    getPlayAuthToken (id) {
        return API({ url: `/resource/v1/resAccessToken/getPlayAuthToken?resId=${id}`, method:'get' })
    },
    // 检验直播课权限 role: 0、讲师 1、学员
    examineLiveCourseRole({data,params}){
        return API({
            url: `${urlName}/liveCourseOpen/examineLiveCourseRole` ,
            data,
            params
        });
    },
    // 防录屏
    getStudentVisitCodes(){
        return API({
            url: `${urlName}/liveCourseOpen/getStudentVisitCode`,
            method:'get',
        });
    },

})

