
const routerArr = [
    {
        path: '/offlineTraining',
        name: 'offlineTraining',
        meta: { title:'线下实训' , login:true },
        component: () => import(/* webpackChunkName: 'offlineTraining' */ './page/offlineTraining.vue'),
    },
    {
        path: '/offlineStem',
        name: 'offlineStem',
        meta: { title:'线下实训题干' , login:true },
        component: () => import(/* webpackChunkName: 'offlineTraining' */ './page/offlineStem.vue'),
    },
    {
        path: '/offlineTraining/preview',
        name: 'offlineTrainingPreview',
        meta: { title:'线下实训查看成绩' , login:true },
        component: () => import(/* webpackChunkName: 'offlineTraining' */ './page/offlinePreview.vue'),
    },
    {
        path: '/offlineTraining/preview/result',
        name: 'offlinePreviewResult',
        meta: { title:'线下实训查看成绩' , login:true },
        component: () => import(/* webpackChunkName: 'offlineTraining' */ './page/offlinePreviewResult.vue'),
    },
]
export default routerArr
