
const routerArr = [
    {
        path: '/practice',
        name: 'practice',
        meta: { title:'练习' , login:true },
        component: () => import(/* webpackChunkName: 'practice' */ './page/practice.vue'),
    },
    {
        path: '/wrongTopic',
        name: 'wrongTopic',
        meta: { title:'查看错题' , login:true },
        component: () => import(/* webpackChunkName: 'practice' */ './page/wrongTopic.vue'),
    },
    {
        path: '/practiceList',
        name: 'practiceList',
        meta: { title:'练习中心' , login:true },
        component: () => import(/* webpackChunkName: 'practice' */ './page/practiceList.vue'),
    },
    {
        path: '/pattern',
        name: 'pattern',
        meta: { title:'练习模式' , login:true },
        component: () => import(/* webpackChunkName: 'practice' */ './page/pattern.vue'),
    },
    {
        path: '/practiceResult',
        name: 'practiceResult',
        meta: { title:'练习结果' , login:true },
        component: () => import(/* webpackChunkName: 'practice' */ './page/practiceResult.vue'),
    },
    {
        path: '/practiceTrainingTask',
        name: 'practiceTrainingTask',
        meta: { title:'学习中心练习' , login:true },
        component: () => import(/* webpackChunkName: 'practice' */ './page/practiceTrainingTask.vue'),
    },
]
export default routerArr
