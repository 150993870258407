var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'wdc-app': true, [_vm.theme]: _vm.theme },attrs:{"id":"wdc-app"}},[(_vm.excludeNavPage.includes(_vm.$route.name))?_c('router-view'):_c('div',[(_vm.globalNavShow)?_c('globalNav'):(_vm.$store.state.dynamic.pageHeaderSetting == 1)?_c('tempNav',{ref:"tempnav",attrs:{"tempData":_vm.$store.state.dynamic}}):(_vm.shuidianNavShow)?_c('shuidianNav'):(_vm.$store.state.dynamic.pageHeaderSetting == 3)?_c('dynamic',{attrs:{"subUserInfo":_vm.subUserInfo,"tokenS":_vm.tokenS,"jsonInfo":_vm.jsonInfo,"typeTer":_vm.typeTer,"name":_vm.sysHeaderTemplate}}):_c('MENU'),_c('div',{ref:"main",staticClass:"main",style:(_vm.$route.name === 'serviceProviderList' || _vm.$route.name === 'opcourseGoods'
						? `padding-top: 80px;background:#ffffff;`
						: _vm.$store.state.dynamic.pageHeaderSetting == 1 && _vm.$store.state.dynamic.pageHeader && !_vm.$store.state.dynamic.pageHeader.headerFixed
						? 'padding-top: 0px;'
						: _vm.$store.state.dynamic.pageHeaderSetting == 1 && _vm.$store.state.dynamic.pageHeader && _vm.$store.state.dynamic.pageHeader.headerFixed
						? `padding-top:${_vm.headerHeight}px;`
						: 'padding-top: 80px;'),attrs:{"id":_vm.globalNavShow?'globalMain':''}},[_c('router-view',{key:_vm.key})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }