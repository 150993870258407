export default{
    state: {
        taskInfo:{}
    },
    mutations:{
        // 任务信息
        SET_TASK_INFO(state, taskInfo){
            state.taskInfo = taskInfo
        }
    },
    actions:{}
}