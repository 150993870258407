const urlName = '/i18n-admin'
import confUrl from '../../config/url'
export default API => ({
    // 获取语言包版本
    checkPackageVersion(params) {
      return API({ url: `${urlName}/package/checkPackageVersion?appname=${params.appname}&language=${params.language}&env=${params.env}`, method:  'get'})
    },
    // 获取语言包
    getCourseGrantList(params) {
        return API({ url: `${urlName}/package/getConfList?appname=${params.appname}&language=${params.language}&env=${params.env}`, method:  'get'})
    },
    getConfiguration() {
        return API({ 
            url: `${'/modular/admin/v1'}/configurations/${confUrl.confUrl}`
        });
    },
})
