
const routerArr = [
    {
        path: '/globalHome',
        name: 'GlobalHome',
        meta: { title:'首页' , login:false },
        component: () => import(/* webpackChunkName: 'GlobalHome' */ './page/index.vue'),
    }
]
export default routerArr
