
// 课程类型
const courseType = {
    // cls：类名
    1: {
        text:"视频",
        icon:'shipin'
    },
    2:{
        text:"音频",
        icon:'audio'
    },
    10:{
        text:"练习",
        icon:'lianxi'
    },
    11:{
        text:"直播课",
        icon:'zhiboke'
    },
    12:{
        text:"实训课",
        icon:'shixunke'
    },
    3:{
        text:'文档',
        icon:'doc'
    },
    4:{
        text:'图片',
        icon:'pic'
    },
    6:{
        text:'scorm',
        icon:'scorm'
    },
}
// 任务类型
const taskType = {
  1:{
      text:'目录',
      icon:'',
      textKey:'WDNPC_WEB_DIRECTORY'
  },
  2:{
      text:'课程',
      icon:'',
      textKey:'WDNPC_WEB_COURSE'
  },
  3:{
      text:'考试',
      icon:'',
      textKey:'WDNPC_WEB_EXAM'
  },
  4:{
      text:'直播',
      icon:'',
      textKey:'WDNPC_WEB_LIVE_STREAMING'
  },
  5:{
      text:'线上实训',
      icon:'',
      textKey:'WDNPC_WEB_ONLINE_TRAINING'
  },
  6:{
      text:'表单',
      icon:'',
      textKey:'WDNPC_WEB_FORM'

  },
  7:{
      text:'练习',
      icon:'',
      textKey:'WDNPC_WEB_PRACTICE'
  },
  8:{
      text:'线下实训',
      icon:'',
      textKey:'WDNPC_WEB_OFFLINE_TRAINING'
  },
}
export default {
    courseType,
    taskType
}