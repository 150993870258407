//禁止修改此文件
import './../assets/icons'
import DataUtil from './data.js'
import Vue from 'vue';

// vue实时更新sessionStorage中的数据 => 不知为何这么写，原先在main.js文件中迁移到该文件中，处理其它saas引为时报错
Vue.prototype.resetSetItem = function (key, newVal) {
    if (key === 'signOutStorage') {
        // 创建一个StorageEvent事件
        var newStorageSignOut = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageSignOut.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageSignOut)
            }
        }
        return storage.setItem(key, newVal);
    }
}
Vue.prototype.ConstData = DataUtil;
