const urlName = 'company/v1'

export default API => ({
    //职位列表
    findByCondition(data) {
        return API({
            url: `${urlName}/stu/position/findByCondition` ,
            method:  'post',
            data
        });
    },

    //职位详情
    findById(data) {
        return API({
            url: `${urlName}/stu/position/findById?id=${data.id}` ,
            method:  'get',
            data
        });
    },

    //相似职位列表
    findSimilarPostion(data) {
        return API({
            url: `${urlName}/stu/position/findSimilarPostion` ,
            method:  'post',
            data
        });
    },

    //获取当前定位城市
    locatingCity(data) {
        return API({
            url: `${urlName}/region/locatingCity` ,
            method:  'get',
            data
        });
    },


    //查询城市列表
    regionList(data) {
        return API({
            url: `${urlName}/region/regionList` ,
            method:  'get',
            data
        });
    },



    //企业详情查询
    companyInfo(data) {
        return API({
            url: `${urlName}/candidate/companyInfo/companyInfo` ,
            method:  'get',
            data
        });
    },


    //职位详情页企业信息
    companyInfoWithoutMan(data) {
        return API({
            url: `${urlName}/candidate/companyInfo/companyInfoWithoutMan?companyId=${data.id}` ,
            method:  'get',
            data
        });
    },


})

