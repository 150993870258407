
const zuoyeName =  '/course/v1';
export default API => ({
 /** 
     *@description: 作业提交
     *@date: 2021-08-03 16:15:18
    */
   answerHomework(data){
    return API({
        url: `${zuoyeName}/open/homework/answerHomework`,
        data,
        method:'post',
    })
},
/**
 *@description:查询作业问题信息
 *@date: 2021-08-03 16:25:03
*/
findHomework(params){
    return API({
        url: `${zuoyeName}/open/homework/findHomework`,
        params,
        method:'get',
    })
},
/*
 * @description:查询课程关联作业列表
 * @date: 2021-08-04 16:45:19
*/
findByCourseId(params){
    return API({
        url: `${zuoyeName}/open/homework/findByCourseId`,
        params,
        method:'get',
    })
},
})