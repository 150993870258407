export default API => ({
    getMenu(url) {
        return API({
            url: `/modular/admin/v1/navigation/getMenu?domain=${url}`,
            method: 'post'
        });
    },
    getTrain() {
        return API({
            url: `/train/v1/train/findIndexTrain`,
        });
    },
    getLive(pageNum,pageSize) {
        return API({
            url: `/live/v1/liveRoomUserX/getIndexLatelyLive?pageNum=${pageNum}&pageSize=${pageSize}`,
        });
    },
    getGongxinExam() {
        return API({
            url:  '/train/v1/train/findExamTrain'
        })

    },
    getJiazhengExam() {
        return API({
            url:  '/train/v1/jz/findIndexTrain'
        })

    },
    // 获取可售卖的商品
    findSaleGoods() {
        return API({
            url: '/order/v1/open/goods/findSaleGoods'
        })
    },
    // 获取网红项目可以售卖的商品
    findSaleWanghongGoods() {
        return API({
            url: '/order/v1/open/goods/findWangHongSaleGoods'
        })
    },
    findGoodsGiftByGoodsId(goodsId) {
        return API({
            url: `/order/v1/open/goods/findGoodsGiftByGoodsId?goodsId=${goodsId}`
        })
    },
    
    // 查看是否能够购买商品
    checkUserCanByGoods(goodsId) {
        return API({
            url: `/order/v1/open/goods/checkUserCanByGoods?goodsId=${goodsId}`,
        });
    },
    // 中智售卖商品查询
    findZhongZhiSaleGoods() {
        return API({
            url: '/order/v1/open/goods/findZhongZhiSaleGoods',
            method:'post'
        })
    }, 
    // 获取商品详情
    getGoodsById(id) {
        return API({
            url: `/order/v1/open/goods/findGoodsById?id=${id}`,
        });
    },
    // 订单页面获取订单详情
    getOrderByNo(id) {
        return API({
            url: `/order/v1/open/order/getOrderByNo?orderNo=${id}`,
        });
    },
    // 查询订单状态
    getOrderStatus(id) {
        return API({
            url: `/order/v1/open/order/getOrderStatus?orderNo=${id}`,
        });
    },
    // 下单
    // placeOrder({data, params}) {
    //     return API({ url: `/order/v1/open/order/placeOrder`, method:'post', params, data });
    // },
    placeOrder({data, params}) {
        return API({ url: `/order/v1/open/order/placeOrder/v2`, method:'post', params, data });
    },
    // 支付预下单
    preOrder({data, params}) {
        return API({ url: `/pay/v1/pay/preOrder?payChannel=1`, method:'post', params, data });
    },
    // 收银台支付预下单
    prePayOrde({data, params}) {
        return API({ url: `/order/v1/open/order/prePayOrder?payChannel=1`, method:'post', params, data });
    },

    // 收银台支付预下单
    launchPay({data, params}) {
        return API({ url: `/pay/cashier/launchPay?orderNo=${data.orderNo}&scene=3`, method:'post', params, data });
    },
    findAndSetPayStatus(payNo) {
        return API({ url: `/pay/v1/pay/findAndSetPayStatus?payNo=${payNo}`, method:'post'});
    },
    getGzjtTrain() {
        return API({
            url:  '/train/v1/traffic/findIndexTrain'
        })
       
    },
    //登录获取民政课程
    getGoodsInBranch(params){
        return API({
            url: `/saas/v1/minzheng/goods/getGoodsInBranch?type=${params.type}&size=${params.size}`,
            method:'get'
        })
    },
    //未登录获取民政课程
    getGoodsInSaas(params){
        return API({
            url: `/saas/v1/minzheng/goods/getGoodsInSaas?type=${params.type}&size=${params.size}`,
            method:'get'
        })
    },
    getMzHomeBanner(type){
        return API({
            url: `/saas/v1/minzheng/carousel/findListByType?type=${type}`,
            method:'get'
        })
    }
})
