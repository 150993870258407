export default API => ({
    trainSave({data,params}) {
        return API({
            url: `/homemaking/v1/trainingAgency/save`,
            method: 'post',
            data,
            params
        });
    },
    // 查询家政公司详情
    findByCompanyId(companyId) {
        return API({
            url: `/assess/v1/open/creditCompanyJz/findByCompanyId?companyId=${companyId}`,
            method: 'get'
        });
    },
})
