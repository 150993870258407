
const urlName = '/task/v1';
export default API => ({
    /******任务接口优化 */
    // 列表初始化
    findTaskList({ data, params }) {
      return API({ //学习列表
          url:'/task/v1/client/findTaskList',
          params,
          data
      });
    },
    // 列表二次渲染
    findTaskLearningInfo({ data, params }) {
      return API({ //学习列表
          url:'/task/v1/client/findTaskLearningInfo',
          params,
          data
      });
    },
    // 详情页初始化
    taskDetailWithoutLogin({ data, params }) {
      return API({ //学习列表
          url:'/task/v1/client/taskDetailWithoutLogin',
          params,
          data
      });
    },
    // 详情页二次渲染
    taskDetailExt({ data, params }) {
      return API({ //学习列表
          url:'/task/v1/client/taskDetailExt',
          params,
          data
      });
    },




    // 培训任务列表
    taskList({ data, params }) {
        return API({
            url: `${urlName}/client/taskList`,
            params,
            data
        });
    },
    // 培训任务详情
    taskDetail({ data, params }) {
        return API({
            url: `${urlName}/client/taskDetail`,
            params,
            data
        });
    },
    // 考试列表
    findUserExams({ data, params }) {
        return API({
            url: `/exam/v1/userExam/findUserExams`,
            params,
            data
        });
    },
    // 实训详情
    getdrillDetail(data) {
        return API({
            url: `/drill/v1/drill/drillDetail`,
            data,
            method:'post',
        })
    },
    // 统计学习时长
    getAllLearnedData() { 
        return API({ 
            url: `/bi/v1/statistics/getAllLearnedData`, 
            method:  'get',
        })
    },
    // 培训任务人人脸识别展示规则 人脸识别范围取值：task(任务)，course(课程)，video(视频，音频），默认值是video 。
    //  isOnce:人脸识别是否是一次性的，默认值是false   后台设置的人脸识别间隔时间针对的是video
    // 培训任务人脸识别规则
    // 保存人脸识别访问记录
    // 说明：type 是必填项，
    // 如果type=1,  传参：taskId,其它不传；
    // 如果 type=2  传参：taskId,courseId  其它不传；
    // 如果 type=3  参数都需要传
    saveFaceAccess(data) {
        return API({
            url: '/saas/v1/faceAccess/saveFaceAccess',
            data,
            method: 'post'

        })
    },
    //  查看是否已保存人脸识别访问记录
    // 说明：type 是必填项，
    // 如果type=1,  传参：taskId,其它不传；
    // 如果 type=2  传参：taskId,courseId  其它不传；
    // 如果 type=3  参数都需要传
    // {
    //     "taskId": "任务id",
    //     "courseId": "课程id",
    //     "resId": "素材id",
    //     "type": "类型，1任务 2课程  3素材",
    // }
    viewFaceAccess(data) {
        return API({
            url: '/saas/v1/faceAccess/viewFaceAccess',
            data,
            method: 'post'

        })
    },
})