const urlName = '/assess/v1';
export default API => ({
    // 学生测评列表
    myAssess(data){
        return API({
            url:`${urlName}/open/assess/myAssess?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
            method:'post',
            data
        })
    },
    // 学生测评详情
    findByIdAndRef(data){
        return API({
            url:`${urlName}/open/assess/findByIdAndRef`,
            method:'post',
            data
        })
    },
    // 学生测评结果列表
    historyList(data){
        return API({
            url:`${urlName}/open/assessUserResult/list`,
            method:'post',
            data
        })
    },
    // 学生测评结果详情
    findByResultIdId(resultId){
        return API({
            url:`${urlName}/open/assessUserResult/findByResultIdId?resultId=${resultId}`,
            method:'get',
        })
    },
    // 学生测评报告列表
    reportList(data){
        return API({
            url:`${urlName}/open/assessReport/list?pageNum=1&pageSize=999`,
            method:'post',
            data
        })
    },
    // 学生测评报告详情
    findByIdReport(id){
        return API({
            url:`${urlName}/open/assessReport/findById?id=${id}`,
            method:'get',
        })
    },
    // 评测的试题列表
    questionList(data){
        return API({
            url:`${urlName}/open/assessUserAnswer/questionList`,
            method:'post',
            data
        })
    },
    // 交卷
    saveUserAnswer(data){
        return API({
            url:`${urlName}/open/assessUserAnswer/saveUserAnswer`,
            method:'post',
            data
        })
    }
})