<template>
    <div class="evaluation-list">
        <div class="tab">
            <span v-for="tab in tabList" :key="tab.id" :class="{active:tabIndex === tab.id}" @click="tabClick(tab)">
                {{tab.value}}
            </span>
        </div>
        <div :class="['content', navPage?'navPage':'']">
            <div class="list" v-for="item in assessList" :key="item.id">
                <div class="left item">
                    <img class="cover" :src="item.assessCoverimg" alt="">
                    <img v-show="item.isValid == 1" class="icon" src="../../assets/images/learning/overdue-icon.png" alt="">
                    <!-- 千万别删！！！ -->
                    <!-- <img v-show="item.isNew == 1" class="icon" src="../assets/images/learning/new-icon.png" alt=""> -->
                </div>
                <div class="middle item">
                    <div class="title">
                        <h1>{{item.assessName}}</h1>
                        <!-- 千万别删！！！ -->
                        <!-- <h1><span v-if="item.modelType" :class="item.modelType==2?'active':''">{{item.modelType==1?'素质':'专业'}}</span>{{item.assessName}}</h1> -->
                    </div>
                    <p class="evaluation-time" v-show="validity === '' || validity==='true'">有效期至：{{item.validEndTime.split(' ')[0].replace(/-/g,'.')}}</p>
                </div>
                <div class="right item">
                    <div class="button" @click="goAssess(item)">{{$dateFormat.dateFormat() > item.validEndTime ? '查看' :'开始测评'}}</div>
                </div>
            </div>
            <article v-show="noData" class="no-data">
                <img class="notraining" src="./../../assets/images/common/nodata.png" alt />
                <p>还没有测评哟，赶快去测评吧～</p>
            </article>
            <p class="loadingmore" v-if="loading">加载中...</p>
            <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    name:'evaluationList',
    props:{
        validity:{
            type:String,
            default:''
        },
        navPage:{
            type:String,
            default:''
        },
    },
    data(){
        return {
            tabIndex:-1,
            tabList:[
                {
                    id:-1,
                    value:'全部',
                },
                {
                    id:0,
                    value:'进行中',
                },
                {
                    id:1,
                    value:'已过期',
                }
            ],
            assessList:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: 0
            },
            loading:false,
            isValid:null,
            noData:null
        }
    },
    mounted(){
        this.getData()
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                // console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    computed: {
        noMore () {
            return this.assessList.length === this.paging.total && this.paging.total !== 0
        },
    },
    methods:{
        goAssess(item){
            if(this.$dateFormat.dateFormat() > item.endTime ){
                this.$message.warning('已到期，无法学习')
            }else {
                this.$router.push({
                    path:'/assess',
                    query:{
                        assessId:item.id,
                        refId:item.refId,//来源ID 商品ID/测评ID
                        refType:item.refType,//来源类型 1 指派 2 商品购买
                        pathName:this.$route.path,
                        backMoudle:'evaluation',
                        validity:this.validity,
                        backPath:this.$route.path
                        
                    }
                })
            }
        },
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        tabClick(item){
            this.tabIndex = item.id
            this.paging.params = {
                pageNum: 1,
                pageSize: 10
            }
            this.isValid = item.id === -1 ? null : item.id;
            this.getData();
        },
        getData(){
            const params = {
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,
                isValid:this.isValid
            }
            this.$api.assess.myAssess(params).then(res => {
                if(res.data && res.data.list && res.data.list.length){
                    this.noData = false
                }else {
                    this.noData = true;
                }
                if(this.paging.params.pageNum>1){
                    this.assessList = this.assessList.concat(res.data && res.data.list);
                } else {
                    this.assessList = res.data && res.data.list;
                }
                this.paging.total = res.data && res.data.total;
            });
        }
    }
}
</script>
<style lang="stylus" scoped>
@import './../../assets/css/learningList.styl'
</style>